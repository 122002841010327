import React from 'react'
import styled from "styled-components";
import LibraryToggler from "./LibraryToggler";

const AttachmentRows = ({attachments}) => {
    return (
        <Container>
            <Wrapper>
                <Title>Załączniki do zadania</Title>
                <div>
                    <Legend>
                        <Sorter>
                            Data dodania
                        </Sorter>
                        <div>Nazwa materiału</div>
                    </Legend>
                </div>
            </Wrapper>
            <div>
                {attachments.map((e, i) => {
                    return <LibraryToggler key={i} data={e} number={i + 1} action={false}/>
                })}
            </div>
        </Container>
    )
}

const Container = styled.div`
  margin: 20px 0;
`;

const Wrapper = styled.div`
    max-width: 1160px;
    margin: 0 auto;
    display: block;
`;

const Title = styled.h3`
  margin-bottom: 10px;
  font-size: 24px;
  font-weight: bold;
  font-family: 'Merriweather', serif;
  color: ${props => props.theme.colors.primary};
`;


const Sorter = styled.div`
    display: flex;
    align-items: center;
    gap: 15px;
`

const Legend = styled.div`
    display: flex;
    align-items: center;
    gap: 80px;
    height: 45px;
`



export default AttachmentRows
