import React, { useState } from 'react'
import styled from 'styled-components'
import Description from '../components/Description'
import Collapse from './Collapse'
import ArrowRight2 from '../components/Icons/ArrowRight2'

const regex = /^\d/;

const Toggler = ({ data }) => {
    const [isOpen, setIsOpen] = useState()
    const handleTogglerClick = () => {
        setIsOpen(prev => !prev)
    }
    const part1 = data.slice(0, 2);
    const part2 = data.slice(2, data.length);

    return (
        <StyledToggler onClick={handleTogglerClick}>
            {
                regex.test(data) ? (
                    <TextWrapper>
                        <TogglerText>{part1}</TogglerText>
                        &nbsp;
                        <TogglerText>{part2}</TogglerText>
                    </TextWrapper>
                ) : (
                    <TogglerText>{data}</TogglerText>
                )
            }
            <ArrowWrapper>
                <StyledArrowRight2 isOpen={isOpen} />
            </ArrowWrapper>
        </StyledToggler>
    )
}

const TaskDescription = ({ data }) => {
    return (
        <>
            {
                data.map((item, index) => {
                    if (item.type.name === 'HTML') {
                        return (
                            <StyledDescription
                                key={index}
                                dangerouslySetInnerHTML={{ __html: item.content }}
                            />
                        )
                    }
                    else if (item.type.name === 'COLLAPSE') {
                        return (

                            <StyledCollapse
                                key={index}
                                toggler={<Toggler data={item.title} />}
                            >
                                <Desc>
                                    <StyledDescription
                                        dangerouslySetInnerHTML={{ __html: item.content }}
                                    />
                                </Desc>
                            </StyledCollapse>

                        )
                    }
                    return null
                })
            }
        </>
    )
}

export default TaskDescription

const StyledDescription = styled(Description)`
    text-align: left;
    font-size: 20px;
    @media only screen and (max-width: ${props => props.theme.mobile1}) {
        text-align: center;
    }
    p {
        margin-bottom: 20px;
        span {
            padding-right: 10px;
        }
        > p {
            margin-bottom: 0px;
        }
    }
    span {
        color: ${props => props.theme.colors.primary};
        font-weight: bold;
    }
    ul, ol {
        list-style-type: initial;
        margin-left: 20px;
        @media only screen and (max-width: ${props => props.theme.mobile1}) {
            list-style-position: inside;
            margin-left: 0;
        }
    }
    
`

const StyledToggler = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    gap: 90px;
    border-bottom: 1px solid ${props => props.theme.colors.primary};
    @media only screen and (max-width: 600px) {
        gap: 20px;
    }
`

const TogglerText = styled.div`
    color: ${props => props.theme.colors.primary};
    cursor: pointer;
    font-weight: bold;
    font-family: Lato, sans-serif;
    font-size: 20px;
    line-height: 32px;
    padding-bottom: 20px;
`

const StyledArrowRight2 = styled(ArrowRight2)`
    fill: ${props => props.theme.colors.primary};
    transform: translateY(${props => props.isOpen ? '20px' : '0px'}) rotate(${props => props.isOpen ? '90deg' : '0deg'}) ;
    transition: all 0.3s ease-in-out;
`

const Desc = styled.div`
    padding: 10px;
`

const StyledCollapse = styled(Collapse)`
  margin-bottom: 40px;
`

const ArrowWrapper = styled.div`
    margin-bottom: 20px;
`

const TextWrapper = styled.div`
    display: flex;
`