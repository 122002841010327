import React from 'react'
import styled from 'styled-components'
import Button from './Button'
import { useNavigate } from 'react-router-dom'


const ChairCard = ({ data, ...props }) => {

    const { name, shortDescription, image, universityChairLevels } = data

    const handleClick = (chairLevel) => {
        navigate(`/about/chairs/${chairLevel.slug}`)
    }

    const navigate = useNavigate()
    return (
        <StyledCard {...props}>
            <Title>{name}</Title>
            <Description dangerouslySetInnerHTML={{ __html: shortDescription }} />
            <Image src={image} alt={'chair'} />
            <Box>
                {
                    universityChairLevels.map((chairLevel, index) => (
                        <Button
                            onClick={() => handleClick(chairLevel)}
                            key={index}
                        >
                            {chairLevel.level.name}
                        </Button>
                    ))
                }
            </Box>
        </StyledCard>
    )
}

export default ChairCard

const StyledCard = styled.div`
    width: 535px;
    text-align: center;
    // padding: 0px 20px; 
`

const Image = styled.img`
    display: block;
    max-width: 320px;
    height: auto;
    margin-bottom: 30px;
    margin-right: auto;
    margin-left: auto;
    margin-top: 20px;
    @media only screen and (max-width: 320px) {
        width: calc(100% + 24px);
        max-width: calc(100% + 24px);
        margin-right: -12px;
        margin-left: -12px;
        margin-bottom: 20px;
    }
`

const Title = styled.h2`
    font-size: 48px;
    line-height: 60px;
    margin-bottom: 22px;
    color: ${props => props.theme.colors.yellowGreen};
    white-space: nowrap;
    font-weight: 500;
    @media only screen and (max-width: ${props => props.theme.mobile}) {
        font-size: 28px;
        line-height: 38px;
        margin-bottom: 14px;
        white-space: initial;
    }
`
const Box = styled.div`
    display: flex;
    justify-content: center;
    gap: 44px;
    @media only screen and (max-width: 400px) {
        gap: 10px;
        button {
            padding: 0;
            flex: 1;
        }
    }
`

const Description = styled.div`
    font-size: 20px;
    line-height: 28px;
    min-height: 84px;
    display: flex;
    align-items: center;
    justify-content: center;
`
