import axios from "./axios"

const inquisitiveService = {
    getAllInquisitive: () => {
        return axios.get('/api/v1/for-inquisitive-list').then(res => res.data)
    },
    getSingleInquisitive: (id)  => () => {
        return axios.get(`/api/v1/for-inquisitive/${id}`).then(res => res.data)
    },
}

export default inquisitiveService