import axios from './axios'

const searchService = {
    search: (data) => () => {
        if (data) {
            return axios.get(`/api/v1/search?search=${data}`).then(res => res.data)
        }
    }
}

export default searchService 