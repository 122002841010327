import React, { useMemo } from 'react'
import styled, { css } from 'styled-components'
import FlexBox from './FlexBox'
import NewTitle from './NewTitle'
import ArrowRight from './Icons/ArrowRight'
import { useStoreState } from 'easy-peasy'
import { useMutation } from '@tanstack/react-query'
import examService from '../services/examService'
import { useNavigate } from 'react-router-dom'
import Spiner from './Spiner'
import { incrementDataText } from '../helpers/helpers'


const ExamCard = ({ role, data, children, ...props }) => {
    const navigate = useNavigate()
    const { id, beforeStart, endDateText, afterDeadline, percentageResult, finished, started } = data

    const isTeacher = useStoreState(state => state.auth.roles.includes("ROLE_LECTURER"))

    const { mutate: mutateInitExam, isLoading: isLoadingInitExam } = useMutation(
        ['initMutate', id],
        examService.initExam(id),
        {
            onSuccess: () => {
                navigate(`${id}`)
            }
        }
    )

    const handleLink = () => {
        if (!beforeStart) {
            if (isTeacher) {
                navigate(`/my-studies/lecturer-exams/${id}`)
            }
            else {
                mutateInitExam()
            }
        }
    }

    const stage = useMemo(() => {
        if (!started && !finished) {
            return 'Rozpocznij'
        }
        else if (started && !finished) {
            return 'Wróć do egzaminu'
        }
        else if (started && finished) {
            return 'Szczegóły'
        }
        else return ''
    }, [started, finished])

    return (
        <>
            {
                isLoadingInitExam ? <Spiner /> : (
                    <StyledExamCard isDisabled={beforeStart} {...props}>
                        <FlexBox gap='10px' justifyContnet='space-between' alignItems={'flex-start'} margin='0 0 20px 0'>
                            <NewTitle variant='h4' textAlign='left'>
                                Egzamin
                            </NewTitle>
                            {(afterDeadline && !finished) ? (
                                <></>
                            ) : (<FlexBox alignItems='center' gap='10px' onClick={handleLink} style={{ cursor: 'pointer', whiteSpace: 'noWrap' }}>
                                {isTeacher ? 'Sprawdź' : stage}
                                <ArrowRight fill='#007A81' />
                            </FlexBox>
                            )}

                        </FlexBox>
                        {
                            !isTeacher && (
                                <Progress finished={finished} started={started}>
                                    <div className='continue'>W trakcie /&nbsp;</div>
                                    <div className='sent'>Wysłany / Oceniony</div>

                                </Progress>
                            )
                        }
                        <Status>
                            {
                                finished ? (
                                    <div>
                                        <FlexBox alignItems={'center'} gap={'20px'}>
                                            Egzamin sprawdzony. Twój wynik to:
                                            <PercentageResult>{percentageResult} %</PercentageResult>
                                        </FlexBox>
                                    </div>
                                ) : (
                                    <>
                                        {beforeStart && !afterDeadline && 'O terminie egzaminu zostaniesz poinformowany drogą mailową.'}

                                        {!beforeStart && !afterDeadline && (
                                            <FlexBox alignItems={'center'} gap={'20px'}>
                                                {
                                                    isTeacher ? 'Termin na sprawdzenie egzaminu upływa:' : 'Termin przystąpienia do egzaminu upływa:'
                                                }

                                                <DateText>
                                                    {
                                                        isTeacher ? incrementDataText(endDateText, 14) : endDateText
                                                    }

                                                </DateText>
                                            </FlexBox>
                                        )}

                                        {!beforeStart && afterDeadline && (
                                            <FlexBox alignItems={'center'} gap={'20px'}>
                                                Termin przystąpienia do egzaminu upłynął:
                                                <DateText>{endDateText}</DateText>
                                            </FlexBox>
                                        )}
                                    </>
                                )
                            }
                        </Status>
                    </StyledExamCard >
                )
            }
        </>
    )
}

export default ExamCard

const StyledExamCard = styled.div`
    width: 100%;
    background-color: ${props => props.theme.colors.white};
    opacity: ${props => props.isDisabled ? 0.5 : 1};
    border: 1px solid ${props => props.theme.colors.primary};
    padding: 20px;
`

const Status = styled.div`
    font-size: 20px;
    color: #707070;
`

const DateText = styled.div`
    font-size: 24px;
    font-weight: bold;
    font-family: 'Merriweather', serif;
    color: ${props => props.theme.colors.primary};
`

const PercentageResult = styled.div`
    font-size: 24px;
    font-weight: bold;
    font-family: 'Merriweather', serif;
    color: ${props => props.theme.colors.yellowGreen};
    white-space: nowrap;
`

const progressStyle = css`
        color: ${props => props.theme.colors.primary};
        font-weight: bold;
`

const Progress = styled.div`
    display: flex;
    margin-bottom: 20px;
    color: rgba(0,0,0,0.3);

    .continue {
        ${props => props.started && progressStyle}
    }
    .sent {
        ${props => props.finished && progressStyle}
    }
`






