import React from 'react'

import styled from 'styled-components'
import Button from '../components/Button'
import Link from './Link'

const createLink = (resultType, id) => {
    switch (resultType) {
        case 'TASK':
            return `/my-studies/tasks/${id}`
        case 'NEWS':
            return `/news/${id}`
        case 'FOR_INQUISITIVE':
            return `/for-inquisitive/${id}`
        default: return '/'
    }
}

const OutputSearchBox = ({ data }) => {
    const { id, title, abstract, resultType } = data
    return (
        <StyledOutputSearchBox>
            <Title>{title}</Title>
            {
                abstract && <Desc>{abstract}</Desc>
            }
            <StyledLink to={createLink(resultType, id)}>
                <StyledButton>Zobacz więcej</StyledButton>
            </StyledLink>
        </StyledOutputSearchBox>
    )
}

export default OutputSearchBox

const StyledOutputSearchBox = styled.div`
    border: 1px solid ${props => props.theme.colors.primary};
    padding: 20px;
    width: 100%;
    max-width: 560px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`

const Title = styled.p`
    font-family: 'Merriweather', serif;
    font-size: 24px;
    font-weight: bold;
    line-height: 38px;
    color: ${props => props.theme.colors.primary};
    margin-bottom: 26px;
`

const Desc = styled.p`
    font-size: 20px;
    line-height: 32px;
    margin-bottom: 40px;
`

const StyledButton = styled(Button)`
    
`

const StyledLink = styled(Link)`
    width: fit-content;
    align-self: flex-end;
`