import React from 'react'
import styled from 'styled-components'
import Container from '../components/Container'
import BigTitle from '../components/BigTitle'
import Title from '../components/Title'
import thumbs from '../assets/images/thumbs.jpg'
import TrophyCard from '../components/TrophyCard'
import Stages from '../components/Stages'
import Slider from '../components/Slider'
import { slide1Data } from "../FakeData/SliderData";
import Card from '../components/Card'
import { useQuery } from '@tanstack/react-query'
import userService from '../services/userService'
import Spiner from '../components/Spiner'


const Trophy = () => {

    const {
        data: dataUserMe,
        isLoading: isLoadingUserMe,
        isSuccess: isSuccessuserMe
    } = useQuery(
        ['userMe'],
        userService.userMe()
    )

    const calculateSemester = (number) => {
        return number % 2 ? 'zimowy' : 'letni'
    }

    return (
        <>
            {
                isLoadingUserMe && <Spiner />
            }
            {
                isSuccessuserMe && (
                    <Container>
                        <Wrapper>
                            <BigTitle>Trofea</BigTitle>
                            <Title>Ucz się, baw i zdobywaj odznaki</Title>
                            <Row>
                                <Desc>
                                    Kto powiedział, że nauka musi być nudna? My uważamy, że ucząc możesz się świetnie bawić, dlatego też Twoje osiągnięcia na UBCM połączyliśmy dodatkowo z możliwością zdobywania nagród i odznak. Poniżej znajdziesz wszystkie swoje nagrody za wykonane przez Ciebie zadania, rozwiązane quizy oraz wyniki semestralne i roczne.
                                </Desc>
                                <Image src={thumbs} alt='thumbs' />
                            </Row>
                            <SpecialTitle>Rok akademicki {dataUserMe.universityChairLevel.halfSet.half.name}</SpecialTitle>
                            <SpecialSubTitle>semestr {calculateSemester(dataUserMe.universityChairLevel.halfSet.half.number)}</SpecialSubTitle>
                            <Trophies>
                                <TrophyCard
                                    title='Kudosy'
                                    description='Nagroda za każde wykonane zadanie zakończone quizem.'
                                    active={dataUserMe.kudosCount > 0}
                                    category={1}
                                    points={dataUserMe.kudosCount}
                                />
                                <TrophyCard
                                    title='Student na medal'
                                    description='Nagroda za 100% poprawnych odpowiedzi w quizie w pierwszym podejściu.'
                                    active={dataUserMe.correctQuizzesCount > 0}
                                    category={2}
                                    points={dataUserMe.correctQuizzesCount}
                                />
                                <TrophyCard
                                    title='Mistrz Katedry'
                                    description='Nagroda za wszystkie kudosy w semestrze. Przyznawana w semestrze zimowym i letnim.'
                                    active={dataUserMe.isUniversityChairMaster}
                                    category={2}
                                />
                            </Trophies>
                        </Wrapper>
                    </Container>
                )
            }
            <StyledStages noBackground />
            <Slider
                data={slide1Data}
                component={<Card />}
                breakpoints={{
                    1400: {
                        slidesPerView: 3
                    },
                    900: {
                        slidesPerView: 2
                    },
                }}
                isCenter={true}
                bgColor={'lightGray'}
            />
        </>
    )
}

export default Trophy

const Wrapper = styled.div`
    max-width: 1160px;
    margin: auto;
`

const Row = styled.div`
    display: flex;
    gap: 50px;
    align-items: center;
    @media screen and (max-width: ${props => props.theme.mobile}) {
        flex-direction: column;
        align-items: center;
    }
`

const Desc = styled.div`
    font-size: 20px;
    line-height: 32px;
    @media screen and (max-width: ${props => props.theme.mobile}) {
        text-align: center;
    }
`

const Image = styled.img`
    display: block;
    max-width: 408px;
    width: 100%;
`

const SpecialTitle = styled.div`
    color: ${props => props.theme.colors.yellowGreen};
    font-size: 32px;
    line-height: 40px;
    font-family: 'Merriweather', serif;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 30px;
`

const SpecialSubTitle = styled.div`
    margin-bottom: 40px;
    color: ${props => props.theme.colors.primary};
    font-size: 24px;
    font-weight: bold;
    text-align: center;
`

const Trophies = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 60px;
    max-width: 900px;
    margin: auto;
`

const StyledStages = styled(Stages)`
    margin: 40px 0;
`