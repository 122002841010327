import React from 'react'
import styled from 'styled-components'

const InputGroup = ({ children, ...props }) => {
    return (
        <InputGroupStyled {...props}>
            {children}
        </InputGroupStyled>
    )
}

export default InputGroup

const InputGroupStyled = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: 30px;
`