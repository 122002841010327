import React from 'react'

const ArrowRight = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={29} {...props}>
      <path data-name="Polygon 1" d="M24 14.5 0 29V0Z" />
    </svg>
  )
}

export default ArrowRight