import React from 'react'
import styled from 'styled-components'
import useMediaQuery from '../Hooks/MediaQueryHook'
import theme from '../theme'
import Link from './Link'
import Logo from './Logo'
import { useStoreState } from 'easy-peasy'

const Footer = () => {
    const isMobile = useMediaQuery(`(max-width: ${theme.mobile}`)
    const chairLevelSlug = useStoreState(state => state.user.chairLevelSlug)
    return (
        <FooterStyled>
            <LogoBox>
                <Logo width={isMobile === true ? '54' : '120'} color='#BBA907' />
                {
                    !isMobile && (
                        <Copyright>
                            © Berlin-Chemie Menarini 2022
                        </Copyright>
                    )
                }
            </LogoBox>
            <div>
                <Title>
                    <Link to={'/about'}>O uniwersytecie</Link>
                </Title>
                <Separator />
                <Box>
                    <SubCategory>
                        <SubTitle>
                            Katedry
                        </SubTitle>
                        <List>
                            <ListItem>
                                <div>Katedra Coachingu i Rozwoju</div>
                                <Links>
                                    <Link to='/about/chairs/coaching-i-rozwój-specjalista'>Specjalista</Link>
                                    &nbsp;|&nbsp;
                                    <Link to='/about/chairs/coaching-i-rozwój-manager'>Manager</Link>
                                </Links>
                            </ListItem>
                            <ListItem>
                                <div>Katedra Marketingu i Promocji</div>
                                <Links>
                                    <Link to='/about/chairs/marketing-i-promocja-specjalista'>Specjalista</Link>
                                    &nbsp;|&nbsp;
                                    <Link to='/about/chairs/marketing-i-promocja-manager'>Manager</Link>
                                </Links>
                            </ListItem>
                            <ListItem>
                                <div>Katedra Sprzedaży</div>
                                <Links>
                                    <Link to='/about/chairs/sprzedaż-specjalista'>Specjalista</Link>
                                    &nbsp;|&nbsp;
                                    <Link to='/about/chairs/sprzedaż-manager'>Manager</Link>
                                </Links>
                            </ListItem>
                            <ListItem>
                                <div>Katedra Zarządzania i Rozwoju</div>
                                <Links>
                                    <Link to='/about/chairs/zarządzanie-i-rozwój-specjalista'>Specjalista</Link>
                                    &nbsp;|&nbsp;
                                    <Link to='/about/chairs/zarządzanie-i-rozwój-manager'>Manager</Link>
                                </Links>
                            </ListItem>
                            <ListItem>
                                <div>Katedra Specjalizacji Zawodowej</div>
                                <Links>
                                    <Link to='/about/chairs/specjalizacja-zawodowa-specjalista'>Specjalista</Link>
                                    &nbsp;|&nbsp;
                                    <Link to='/about/chairs/specjalizacja-zawodowa-manager'>Manager</Link>
                                </Links>
                            </ListItem>
                        </List>
                    </SubCategory>
                    <SubCategory>
                        <SubTitle>
                            <Links>
                                <Link to='/about/teachers'>NASI WYKŁADOWCY</Link>
                            </Links>
                        </SubTitle>
                        <SubTitle>
                            <Links>
                                <Link to='/for-inquisitive'>DLA DOCIEKLIWYCH</Link>
                            </Links>
                        </SubTitle>
                    </SubCategory>
                </Box>
            </div>
            <div>
                <Title>
                    <Link to={'/news'}>Aktualności</Link>
                </Title>
                <Separator />
            </div>
            <div>
                <Title>
                    <Link to={'/my-studies'}>Moje studia</Link>
                </Title>
                <Separator />

                <List>
                    <ListItem>
                        <Links>
                            <Link to='/my-studies/tasks'>Zadania</Link>
                        </Links>
                    </ListItem>
                    <ListItem>
                        <Links>
                            <Link to={`/about/chairs/${chairLevelSlug}`}>Program</Link>
                        </Links>
                    </ListItem>
                    <ListItem>
                        <Links>
                            <Link to='/my-studies/library'>Biblioteka</Link>
                        </Links>
                    </ListItem>
                    <ListItem>

                        <Links>
                            <Link to='/trophy'>Trofea</Link>
                        </Links>
                    </ListItem>

                    <ListItem>
                        <Links>
                            <Link to='/contact'>Kontakt</Link>
                        </Links>
                    </ListItem>
                    <ListItem>
                        <Links>
                            <Link to='/my-studies/exams'>Egzaminy</Link>
                        </Links>
                    </ListItem>
                </List>
            </div>
            <div>
                <Title>
                    <Link to={'/faq'}>FaQ</Link>
                </Title>
                <Separator />
            </div>
            {
                isMobile && (
                    <Copyright>
                        © Berlin-Chemie Menarini 2022
                    </Copyright>
                )
            }
        </FooterStyled>
    )
}

export default Footer

const Links = styled.div`
    display: flex;
    margin-top: 3px;
    a {
        transition: all 0.3s ease-in-out;
    }
    a:hover {
        color: ${props => props.theme.colors.yellowGreen};
    }
`

const List = styled.ul`
    list-style-type: none;
    margin-left: 10px;
    
`
const ListItem = styled.li`
    font-size: 16px;
    margin-bottom: 9px;
`

const Title = styled.div`
    text-transform: uppercase;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    white-space: nowrap;
    padding: 0 20px;
`

const Separator = styled.div`
    background-color: ${props => props.theme.colors.yellowGreen};
    height: 3px;
    width: 100%;
    margin: 10px 0px;
`

const SubTitle = styled.div`
    text-transform: uppercase;
    font-size: 18px;
    text-align: left;
    margin-bottom: 9px;
`

const SubCategory = styled.div`

`

const Box = styled.div`
    display: flex;
    gap: 20px;
    padding: 0 15px;
`

const Copyright = styled.div`
    text-align: center;
    color: ${props => props.theme.colors.yellowGreen};
`

const LogoBox = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
`

const FooterStyled = styled.footer`
    width: 100%;
    background-color: ${props => props.theme.colors.darkGray};
    color: ${props => props.theme.colors.white};
    display: flex;
    justify-content: center;
    gap: 40px;
    padding: 40px;
    z-index: 99;
    @media (max-width: ${props => props.theme.mobile}) {
        padding: ${props => props.theme.paddings.mobile};
        gap: 8px;
      flex-direction: column;
        ${Box} {
            flex-direction: column;
        }
        ${Copyright} {
            text-align: center; 
        }
    }
`