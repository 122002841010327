import React from 'react'
import styled from 'styled-components'
import teacherRowBg from '../assets/images/teacherRowBg.png'
import Description from './Description'
import Link from './Link'

import Button from '../components/Button'
import Container from './Container'
import { HashLink } from 'react-router-hash-link';
import ArrowRight3 from './Icons/ArrowRight3'


function TeacherRow({ data }) {

    const { id, firstName, lastName, title, sentence, image } = data
    return (
        <StyledTeacherRow>
            <Container>
                <Wrapper>
                    <Image src={image} alt='teacher' />
                    <Box>
                        <Title>
                            {title}
                        </Title>
                        <Title>
                            {firstName} {lastName}
                        </Title>
                        <StyledDescription>
                            {sentence}
                        </StyledDescription>
                        <StyledLink to={`/about/teachers/${id}`} >
                            Więcej informacji
                            <ArrowRight3 className='arrow' />
                            {/* <img src={arrowRight2} alt='arrow right' /> */}
                        </StyledLink>
                        <ButtonsWrapper>
                            <HashLink style={{ textDecoration: 'none' }} to={`/about/teachers/${id}#related-chairs`}><Button>Powiązane katedry</Button></HashLink>
                            <HashLink style={{ textDecoration: 'none' }} to={`/about/teachers/${id}#related-tasks`}><Button >Powiązane zadania</Button></HashLink>
                        </ButtonsWrapper>
                    </Box>
                </Wrapper>
            </Container>
        </StyledTeacherRow>
    )
}

export default TeacherRow



const StyledTeacherRow = styled.div`
    background-image: url(${teacherRowBg});
    min-height: 450px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: left;
`
const Wrapper = styled.div`
    min-height: 450px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1160px;
    margin: auto;
    gap: 20px;
    @media only screen and (max-width: 800px) {
        flex-wrap: wrap;
        justify-content: center;
        padding: 70px 0px; 
    }
`

const Image = styled.img`
    display: block;
    max-width: 280px;
    width: 100%;
    height: auto;
    border: 1px solid ${props => props.theme.colors.yellowGreen};

`

const Box = styled.div`
    max-width: 700px;
    width: 100%;
`

const StyledDescription = styled(Description)`
    margin-bottom: 14px;
    margin-top: 6px;
    @media only screen and (max-width: ${props => props.theme.mobile}) {
        text-align: left;
    }
`

const Title = styled.div`
    font-family: 'Merriweather', serif;
    font-size: 24px;
    color: ${props => props.theme.colors.primary};
    font-weight: bold;
    line-height: 38px;
`
const StyledLink = styled(Link)`
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 20px;
    font-weight: bold;
    color: ${props => props.theme.colors.primary};
    width: fit-content;
    margin-left: auto;
    transition: 0.3s ease-in-out;
    .arrow {
        transition: 0.3s ease-in-out;
        fill: ${props => props.theme.colors.primary};
    }
    &:hover {
      color: ${props => props.theme.colors.yellowGreen};
      .arrow {
        fill: ${props => props.theme.colors.yellowGreen};
        }
    }
    @media only screen and (max-width: 500px) {
        margin: auto;
    }
    
`

const ButtonsWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
    gap: 10px;
    @media only screen and (max-width: 500px) {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
`
