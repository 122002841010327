import React, { useRef, useState } from 'react'
import styled from 'styled-components'
import Link from './Link'

const Collapse = ({ setIsOpenMenu, data, altMode = false, isActive = true, toggler, children, ...props }) => {



    const [isOpen, setIsOpen] = useState(false)
    const contentRef = useRef()

    const handleClick = () => {
        isActive && setIsOpen(prev => !prev)

    }

    return (
        <StyledCollapse {...props}>
            {
                altMode ? (
                    <AltTogglerWrapper isOpen={isOpen}>
                        <Link
                            to={data.link}
                            onClick={() => setIsOpenMenu(prev => !prev)}
                        >
                            {data.label}
                        </Link>
                        {React.cloneElement(
                            toggler,
                            {
                                // isActive: isActive,
                                onClick: handleClick,
                                className: 'toggler',
                            })}
                    </AltTogglerWrapper>
                ) : (
                    <Span isactive={isActive} onClick={() => isActive && setIsOpen(prev => !prev)}>
                        {toggler}
                    </Span>
                )
            }

            <Content
                style={isOpen ? { height: contentRef.current.scrollHeight + "px" } : { height: '0px' }}
                ref={contentRef}
            >
                {children}
            </Content>
        </StyledCollapse >
    )
}

export default Collapse

const Content = styled.div`
    overflow: hidden;
    transition: all 0.3s ease-in-out;

`

const StyledCollapse = styled.div`
    width: 100%;
    position: relative;
`

const Span = styled.span`
    cursor: ${props => props.isactive ? 'pointer' : 'auto'};
    /* display: inline-flex; */
`



const AltTogglerWrapper = styled.div`
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;
    .toggler {
        transform: translate(-30px, ${props => props.isOpen ? 'calc(-50% + 20px)' : '-50%'}) rotate(${props => props.isOpen ? '90deg' : '0deg'});
        position: absolute;
        top: 50%;
        right: 0;
    }
`