import axios from "./axios"

const teacherService = {
    getAllLecturers: () => () => {
        return axios.get('/api/v1/lecturers').then(res => res.data)
    },
    getSingleLecturer: (id) => () => {
        return axios.get(`/api/v1/lecturer/${id}`).then(res => res.data)
    },
}

export default teacherService