import React from 'react'
import styled from 'styled-components'

const Label = ({ label }) => {
    return (
        <LabelStyled>{label}</LabelStyled>
    )
}

export default Label

const LabelStyled = styled.label`
    display: block;
    font-size: 20px;
`