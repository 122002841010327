import React, { forwardRef } from 'react'
import styled from 'styled-components'
import { MdAttachFile } from 'react-icons/md'

const InputFile = forwardRef(({ watch, ...props }, ref) => {

    const fileName = watch(props.name) && watch(props.name)[0] ? watch(props.name)[0].name : ''

    return (
        <Label>
            <div>
                <StyledMdAttachFile />
            </div>
            {
                fileName ? (
                    <Text>{fileName}</Text>
                ) : (
                    <Text>Dodaj załącznik <br /> max. 10 Mb, dopuszczalne formaty: JPG, PNG, PDF</Text>
                )
            }
            <StyledInput
                ref={ref}
                type={'file'}
                {...props}
            />
        </Label>
    )
})

export default InputFile

const StyledInput = styled.input`
    display: none;
`

const StyledMdAttachFile = styled(MdAttachFile)`
    font-size: 40px;
    color: ${props => props.theme.colors.primary};
`

const Label = styled.label`
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    min-height: 50px;
`

const Text = styled.div`
    font-size: 14px;
`