import React from 'react'
import styled from 'styled-components'

const ResetedButton = ({ children, ...props }) => {
  return (
    <ResetedButtonWrapper {...props}>{children}</ResetedButtonWrapper>
  )
}

export default ResetedButton

const ResetedButtonWrapper = styled.button`
  position: relative;
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  color: inherit;
  font: inherit;
  line-height: normal;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;
  // cursor: pointer;
  &::-moz-focus-inner {
    border: 0;
    padding: 0;
  }
`;