import { useStoreActions, useStoreState } from 'easy-peasy'
import { rgba } from 'polished'
import React, { useState } from 'react'
import styled from 'styled-components'
import Collapse from '../Collapse'
import Hamburger from '../Hamburger'
import Home from '../Icons/Home'
import Link from '../Link'
import Logo from '../Logo'
import Search from '../Search'
import menuDataStudent from './menuDataStudent'
import menuDataTeacher from './menuDataLecturer'
import { useNavigate } from 'react-router-dom'
import ArrowRight2 from '../../components/Icons/ArrowRight2'

const MobileNav = () => {

    const isTeacher = useStoreState(state => state.auth.roles.includes('ROLE_LECTURER'))
    const navigate = useNavigate()
    const [isOpen, setIsOpen] = useState(false)
    const logout = useStoreActions(actions => actions.auth.logoutUser)
    const chairLevelSlug = useStoreState(state => state.user.chairLevelSlug)

    const specMenuData = isTeacher ? menuDataTeacher : menuDataStudent

    const handeLogout = () => {
        logout()
    }

    const handleHamburger = () => {
        setIsOpen(prev => !prev)
    }

    const handleSearch = () => {
        navigate('/search')
        setIsOpen(prev => !prev)
    }

    const handleSubLink = (link) => {

        if (link === '/DYNAMIC_CHAIR') {
            return `/about/chairs/${chairLevelSlug}`
        }
        else if (link === '/my-studies/exams') {
            if (isTeacher) {
                return '/my-studies/lecturer-exams'
            }
            else return '/my-studies/exams'
        }
        else return link

    }

    return (
        <StyledMobileNav>
            <Link to='/login' onClick={handeLogout}>
                Wyloguj się
            </Link>
            <Link to='/' onClick={() => setIsOpen(false)}>
                <Logo width={54} color='white' style={{ marginTop: '4px' }} />
            </Link>

            < Hamburger isOpen={isOpen} onClick={handleHamburger} />

            <Menu isOpen={isOpen}>
                <Box>
                    <Link to='/' onClick={() => setIsOpen(false)}>
                        <Home />
                    </Link>
                    <Search onClick={handleSearch} />
                    <Link to={'/faq'} onClick={() => setIsOpen(false)}>
                        FAQ
                    </Link>
                </Box>

                <List>
                    {
                        specMenuData.map(
                            (menuItem, index) => menuItem.hasOwnProperty('collapse') ? (
                                <StyledCollapse setIsOpenMenu={setIsOpen} altMode={true} data={menuItem} key={index} toggler={<StyledArrowRight2 />} >
                                    <SubList>
                                        {
                                            menuItem.collapse.map((subMenuItem, index) => (
                                                <SubListItem key={index} >
                                                    <StyledLink
                                                        // to={subMenuItem.link === '/DYNAMIC_CHAIR' ? `/about/chairs/${chairLevelSlug}` : subMenuItem.link}
                                                        to={handleSubLink(subMenuItem.link)}
                                                        onClick={() => setIsOpen(false)}
                                                        disabled={subMenuItem.disabled}
                                                    >
                                                        {subMenuItem.label}
                                                    </StyledLink>
                                                </SubListItem>
                                            ))
                                        }
                                    </SubList>
                                </StyledCollapse>
                            ) : (
                                <ListItem key={index} >
                                    <StyledLink
                                        to={menuItem.link}
                                        onClick={() => setIsOpen(false)}
                                        disabled={menuItem.disabled}
                                    >
                                        {menuItem.label}
                                    </StyledLink>
                                </ListItem>
                            )
                        )
                    }
                </List>
            </Menu>
        </StyledMobileNav>
    )
}

export default MobileNav

const StyledCollapse = styled(Collapse)`
    &:not(:first-of-type)::before {
        content: '';
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        height: 4px;
        width: 48px;
        background-color: #F9E741;
    }
`

const StyledMobileNav = styled.nav`
    background-color: ${props => props.theme.colors.yellowGreen};
    color: ${props => props.theme.colors.white};
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 18px;
    height: 64px;
    z-index: 100;
`

const Menu = styled.div`
    height: 100%;
    overflow: scroll;
    position: absolute;
    top: 64px;
    width: 100%;
    height: calc(100vh - 64px);
    left: 0;
    background-color: ${props => props.theme.colors.yellowGreen};
    transition: all 0.3s ease-in-out;
    transform: translateX(${props => !!props.isOpen ? '0%' : '-100%'});
    text-align: center;
    font-size: 20px;
`
const Box = styled.div`
    display: flex;
    justify-content: center;
    gap: 80px;
    height: 64px;
    align-items: center;
    padding: 0 38px;
    background-color: ${props => props.theme.colors.primary};
    @media (max-width: 600px) {
        justify-content: space-between;
        gap: 10px;
      }
`

const List = styled.ul`
    font-size: 24px;
`

const SubList = styled.ul`
    font-size: 20px;
`

const ListItem = styled.li`
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        height: 4px;
        width: 48px;
        background-color: #F9E741;
    }
    
`

const StyledLink = styled(Link)`
    width: 100%;
    height: 100%;
    display: flex;
    align-items:center;
    justify-content: center;
    cursor: pointer;
`

const SubListItem = styled.li`
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${props => props.theme.colors.lightYellow};
    color: ${props => props.theme.colors.yellowGreen};
    ${StyledLink} {
        transition: all 0.3s ease-in-out;
        &:hover {
            background-color: ${props => rgba(props.theme.colors.yellowGreen, 0.6)};
            color: ${props => props.theme.colors.white};
        }
    }
`

const StyledArrowRight2 = styled(ArrowRight2)`
    fill: ${props => props.theme.colors.primary};
    transition: all 0.3s ease-in-out;
`