import React from 'react'
import styled from 'styled-components'
import Link from "../Link";
import { privateRoutes } from '../../Routes/allRoutes';
import useBreadcrumbs from 'use-react-router-breadcrumbs';

const BreadcrumbsWrapper = styled.div`
  border-bottom: 1px solid ${props => props.theme.colors.gray};
  height: 32px;
  padding: 6px 50px;
  background-color: white;
  @media (max-width: ${props => props.theme.mobile}) {
    padding: 6px 20px;
    white-space: pre;
    overflow-x: scroll;
    &::-webkit-scrollbar {
      display: none;
      -ms-overflow-style: none;
      scrollbar-width: none;
    }
  }
  ul {
    li {
      display: inline;
      margin-right: 10px;
      color: ${props => props.theme.colors.yellowGreen};
      &:not(:last-of-type)::after {
        content: '/';
        margin-left: 10px;
      }
      &:first-of-type {
        text-transform: uppercase;
      }
      &:last-of-type {
        color: ${props => props.theme.colors.primary}
        }
      .disabled {
        cursor: initial;
        transition: none;
        &:hover {
          color: unset;
        }
      }
      
    }
  }
`;

const Breadcrumbs = () => {

    const crumbs = useBreadcrumbs(privateRoutes, {disableDefaults: true});

    return (
        <BreadcrumbsWrapper>
            <ul>
                {
                    crumbs.map(({match,breadcrumb}) => (
                        <li key={match.pathname}>
                            <Link to={match.pathname}>{breadcrumb}</Link>
                        </li>
                        ))
                }
            </ul>
        </BreadcrumbsWrapper>
    )
}
export default Breadcrumbs
