import React from 'react'
import styled from 'styled-components'

const Background = ({ bgColor, children, ...props }) => {
  return (
    <StyledBackground bgColor={bgColor} {...props}>{children}</StyledBackground>
  )
}

export default Background

const StyledBackground = styled.div`
    background-color: ${props => props.theme.colors[props.bgColor]}
`