import React, { useState } from 'react'
import styled from 'styled-components'
import BigTitle from '../components/BigTitle'
import Container from '../components/Container'
import Title from '../components/Title'
import library from '../assets/images/library.jpg'
import Collapse from '../components/Collapse'
import LibraryToggler from '../components/LibraryToggler'
import libraryService from '../services/libraryService'
import { useQuery } from '@tanstack/react-query'
import TaskRow from '../components/TaskRow'
import Spiner from '../components/Spiner'
import SortDesc from '../components/Icons/SortDesc'
import SortAsc from '../components/Icons/SortAsc'
import Modal from '../components/Modal'
import AudioPlayer from '../components/AudioPlayer'


const Library = () => {
    const [param, setParam] = useState()
    const [isSortAsc, setIsSortAsc] = useState(false)
    const [isOpenModal, setIsOpenModal] = useState(false)
    const [audioUrl, setAudioUrl] = useState()

    const {
        data: dataLibrary,
        isSuccess: isSuccessLibrary,
        isLoading: isLoadingLibrary
    } = useQuery(
        ['library', param],
        libraryService.getMyTaskAttachments(param),
        { keepPreviousData: true }
    )

    const handleSortAsc = () => {
        setIsSortAsc(true)
        setParam({ sort_by: 'createdAtText_asc' })
    }

    const handleSortDesc = () => {
        setIsSortAsc(false)
        setParam({ sort_by: 'createdAtText_desc' })
    }

    return (
        <>
            {
                isLoadingLibrary && <Spiner />
            }
            {
                isSuccessLibrary && (
                    <>
                        <Container>
                            <BigTitle>
                                Biblioteka
                            </BigTitle>
                            <Title>
                                Twoje materiały do nauki
                            </Title>
                            <Wrapper>
                                <Row>
                                    <Desc>
                                        Poniżej znajdziesz wszystkie potrzebne materiały, dzięki którym zrealizujesz swoje zadania i przygotujesz się do egzaminów. W Bibliotece UBCM masz dostęp m.in. do książek, pdfów, plików audio i video. Poszerzaj swoją wiedzę
                                        w wygodny i przystępny sposób i ciesz się nauką.
                                    </Desc>
                                    <ImageWrapper>
                                        <Image src={library} alt='library' />
                                    </ImageWrapper>
                                </Row>
                            </Wrapper>
                        </Container>
                        <Container disablePaddingTop>
                            <Wrapper>
                                <Legend>
                                    <Sorter>
                                        <SorterIcons >
                                            <SortAsc isActive={isSortAsc} className='icon' onClick={handleSortAsc} />
                                            <SortDesc isActive={!isSortAsc} className='icon' onClick={handleSortDesc} />
                                        </SorterIcons>
                                        Data dodania
                                    </Sorter>
                                    <div>Nazwa materiału</div>
                                </Legend>
                            </Wrapper>
                        </Container>
                        {
                            dataLibrary.map((item, index) => (
                                <Collapse key={index} toggler={
                                    <LibraryToggler
                                        setAudioUrl={setAudioUrl}
                                        setIsOpenModal={setIsOpenModal}
                                        data={item}
                                        number={index + 1}
                                    />
                                }>
                                    <TaskRow key={index} data={item.task} />
                                </Collapse>
                            ))
                        }
                        <Modal isOpen={isOpenModal} setIsOpen={setIsOpenModal}>
                            <AudioPlayer url={audioUrl} />
                        </Modal>
                    </>
                )
            }
        </>
    )
}

export default Library

const Wrapper = styled.div`
    max-width: 1170px;
    margin: auto;
`

const Row = styled.div`
    display: flex;
    gap: 20px;
`

const Desc = styled.div`
    font-size: 20px;
    flex: 1;
`

const Image = styled.img`
    width: 100%;
    height: auto;
    display: block;
`

const ImageWrapper = styled.div`
    width: 400px;
`

const Sorter = styled.div`
    display: flex;
    align-items: center;
    gap: 15px;
`

const SorterIcons = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
    .icon {
        cursor: pointer;
    }
`

const Legend = styled.div`
    display: flex;
    align-items: center;
    gap: 55px;
    height: 75px;
`

