import React from 'react'
import styled from 'styled-components'
import Container from './Container'
import ArrowRight2 from '../components/Icons/ArrowRight2'
import { rgba } from 'polished'

const Category = ({ title }) => {
    return (
        <StyledCategory>
            <Container disablePaddingTop={true}>
                <Wrapper>
                    <Row>
                        <StyledArrowRight2 />
                        <Title>{title}</Title>
                    </Row>
                </Wrapper>
            </Container>
        </StyledCategory>
    )
}

export default Category

const StyledCategory = styled.div`
    background-color: ${props => rgba(props.theme.colors.primary, 0.19)};
`

const Wrapper = styled.div`
    max-width: 1170px;
    margin: auto;
`

const Row = styled.div`
    height: 74px;
    display: flex;
    align-items: center;
`

const StyledArrowRight2 = styled(ArrowRight2)`
    fill: ${props => props.theme.colors.primary};
`

const Title = styled.p`
    font-family: 'Merriweather', serif;
    font-weight: bold;
    font-size: 24px;
    line-height: 38px;
    color: ${props => props.theme.colors.primary};
    margin-left: 15px;
`
