import React from 'react'
import Background from '../../components/Background'
import BigTitle from '../../components/BigTitle'
import Container from '../../components/Container'
import FlexBox from '../../components/FlexBox'
import Title from '../../components/Title'
import exam from '../../assets/images/exam.jpg'
import Image from '../../components/Image'
import styled from 'styled-components'
import NewTitle from '../../components/NewTitle'
import ExamCard from '../../components/ExamCard'
import { useQuery } from '@tanstack/react-query'
import examService from '../../services/examService'
import Spiner from '../../components/Spiner'
import ArrowRight from '../../components/Icons/ArrowRight'
import Link from '../../components/Link'
import Stages from '../../components/Stages'
import Slider from '../../components/Slider'
import { slide1Data } from '../../FakeData/SliderData'
import Card from '../../components/Card'
import useMediaQuery from '../../Hooks/MediaQueryHook'


const StudentExam = () => {

  const isMobile = useMediaQuery(`(max-width: 1200px`)


  const {
    data: dataExams,
    isSuccess: isSuccessExams,
    isLoading: isLoadingExams
  } = useQuery(
    ['dataExams'],
    examService.getAllExams()
  )

  return (
    <>
      <Background bgColor={'primary'}>
        <Container>
          <BigTitle color='yellow2'>Egzaminy</BigTitle>
          <Title color='white'>Nadszedł czas, by sprawdzić wiedzę</Title>
        </Container>
      </Background>
      <Background bgColor='aquamarine'>
        <Container maxWidth='1460px'>
          <StyledFlexBox2 gap={'50px'} alignItems='flex-start' justifyContnet='center' margin='0 0 38px 0'>
            <Desc>
              {
                isMobile ? (
                  <>
                    <p>III semestr to czas egzaminów na Uniwersytecie Berlin-Chemie Menarini.
                      Dzięki nim, masz możliwość powrotu do realizowanych wcześniej zagadnień,
                      a także możesz zweryfikować wiedzę nabytą w trakcie dotychczasowej nauki.
                      O dostępności egzaminów będziemy informować Cię mailowo.
                      Każdy z egzaminów zostanie indywidualnie oceniony przez Twoich wykładowców, z którymi będziesz mieć możliwość konsultacji wyników.
                      Trzymamy kciuki i życzymy Ci powodzenia!</p>
                  </>
                ) : (
                  <>
                    <p>III semestr to czas egzaminów na Uniwersytecie Berlin-Chemie Menarini.</p>
                    <p>Dzięki nim, masz możliwość powrotu do realizowanych wcześniej zagadnień,</p>
                    <p>a także możesz zweryfikować wiedzę nabytą w trakcie dotychczasowej nauki.</p>
                    <p>O dostępności egzaminów będziemy informować Cię mailowo.</p>
                    <p>Każdy z egzaminów zostanie indywidualnie oceniony przez Twoich</p>
                    <p>wykładowców, z którymi będziesz mieć możliwość konsultacji wyników.</p>
                    <p>Trzymamy kciuki i życzymy Ci powodzenia!</p>
                  </>
                )
              }
            </Desc>
            <Image src={exam} alt='exam' />
          </StyledFlexBox2>
          <NewTitle variant='h3' color='primary'>Poniżej znajdziesz egzaminy dla Twojej specjalizacji</NewTitle>
        </Container>
        <Container maxWidth='1460px'>
          {
            isLoadingExams && <Spiner />
          }
          {
            isSuccessExams && (
              <>
                {
                  dataExams.length > 0 ? (
                    <FlexBox flexWrap={'wrap'} gap={'20px'}>
                      {
                        dataExams.map((exam, index) => (
                          <StyledFlexBox key={index} >
                            <ExamCard data={exam} />
                          </StyledFlexBox>
                        ))
                      }
                    </FlexBox>
                  ) : (
                    <>
                      <NewTitle variant='h3' color='gray2'>W tej chwili nie ma żadnych egzaminów dla Twojej specjalizacji.</NewTitle>
                      <NewTitle variant='h3' color='gray2'>O terminach i dostępności egzaminów poinformujemy Cię e-mailem.</NewTitle>
                    </>
                  )
                }

              </>
            )
          }
        </Container>
      </Background>
      <Background bgColor={'primary'}>
        <Container maxWidth='800px'>
          <Link to='/contact'>
            <NewTitle variant='h3' color='white'>
              Jeśli potrzebujesz zmienić termin egzaminu
              skorzystaj z formularza kontaktowego
              <ArrowRight fill='#FFFFFF' style={{ marginLeft: '10px' }} />
            </NewTitle>
          </Link>
        </Container>
      </Background>
      <StyledStages noBackground />
      <Slider
        data={slide1Data}
        component={<Card />}
        breakpoints={{
          1400: {
            slidesPerView: 3
          },
          900: {
            slidesPerView: 2
          },
        }}
        isCenter={true}
        bgColor={'lightGray'}
      />
    </>
  )
}

export default StudentExam

const Desc = styled.div`
  font-size: 20px;
  line-height: 32px;
  color: ${props => props.theme.colors.balck};
  /* max-width: 670px; */
`

const StyledStages = styled(Stages)`
  margin: 25px 0px 30px 0px;
`

const StyledFlexBox = styled(FlexBox)`
    width: calc(50% - 10px);
    @media only screen and (max-width: ${props => props.theme.mobile2}) {
        width: calc(100% - 10px);
    }
`
const StyledFlexBox2 = styled(FlexBox)`
    @media only screen and (max-width: ${props => props.theme.mobile2}) {
        flex-direction: column;
        align-items: center;
    }
`
