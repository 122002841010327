import React from 'react'

const Calendar = (props) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={43.5}
            height={48.333}
            {...props}
        >
            <path
                d="M3.625 48.333a3.478 3.478 0 0 1-2.537-1.088A3.478 3.478 0 0 1 0 44.708V7.25a3.478 3.478 0 0 1 1.087-2.538 3.478 3.478 0 0 1 2.538-1.087h3.927V0h3.927v3.625h20.542V0h3.927v3.625h3.927a3.478 3.478 0 0 1 2.537 1.087A3.478 3.478 0 0 1 43.5 7.25v37.458a3.717 3.717 0 0 1-3.625 3.625Zm0-3.625h36.25V18.729H3.625Zm0-29.6h36.25V7.25H3.625Zm0 0ZM21.75 29a2.374 2.374 0 1 1 1.722-.695A2.338 2.338 0 0 1 21.75 29Zm-9.667 0a2.374 2.374 0 1 1 1.722-.695 2.338 2.338 0 0 1-1.722.695Zm19.333 0a2.374 2.374 0 1 1 1.722-.695 2.338 2.338 0 0 1-1.721.695Zm-9.666 9.667a2.374 2.374 0 1 1 1.722-.695 2.338 2.338 0 0 1-1.722.695Zm-9.667 0a2.374 2.374 0 1 1 1.722-.695 2.338 2.338 0 0 1-1.722.695Zm19.333 0a2.374 2.374 0 1 1 1.722-.695 2.338 2.338 0 0 1-1.721.695Z"
            />
        </svg>
    )
}

export default Calendar