import React from 'react'
import { useStoreState } from 'easy-peasy'
import {
    Navigate,
    Outlet
} from "react-router-dom";

const PrivateRoute = ({ children }) => {
    const { isAuthenticated } = useStoreState(state => state.auth)
    if (!isAuthenticated) {
        return <Navigate to={'/login'} />
    }
    return children ? children : <Outlet />;
}

export default PrivateRoute