import React from 'react'
import styled from 'styled-components'
import useScrollPosition from '../Hooks/useScrollPosition'
import { rgba } from 'polished'

const ScrollButton = () => {
    const scrollPosition = useScrollPosition()

    const handleScroll = () => {
        const app = document.querySelector('.App')
        if (app) {
            app.scrollIntoView({ behavior: 'smooth' })
        }
    }
    return (
        <StyledScrollBUtton
            onClick={handleScroll}
            scrollPosition={scrollPosition}
        >
            <Arrow />
        </StyledScrollBUtton>
    )
}

export default ScrollButton

const StyledScrollBUtton = styled.button`
    position: fixed;
    bottom: 50%;
    transform: translate(${props => (props.scrollPosition > 100) ? '-80px' : '100%'}, 50%);
    right: 0px;
    width: 45px;
    height: 45px;
    border-radius: 10px;
    border: none;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: ${rgba('#007A81', 0.18)};
    opacity: ${props => props.scrollPosition > 100 ? '1' : '0'};
    transition: all 0.3s ease-in-out, transform 0.3s ease-in-out;
    z-index: 100;
    @media only screen and (max-width: 1400px) {
        transform: translate(${props => (props.scrollPosition > 100) ? '-20px' : '100%'}, 50%);
    }
    @media only screen and (max-width: 400px) {
        transform: translate(${props => (props.scrollPosition > 100) ? '-6px' : '100%'}, 50%);
    }

`

const Arrow = styled.div`
    width: 0; 
    height: 0; 
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 15px solid ${rgba('#ffffff', 0.92)};
`