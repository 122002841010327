import React, { useState } from 'react'
import NewRow from '../components/NewRow'
import Container from '../components/Container'
import styled from 'styled-components'
import Stages from '../components/Stages'
import Slider from '../components/Slider'
import { slide1Data } from "../FakeData/SliderData";
import Card from '../components/Card'
import BigTitle from '../components/BigTitle'
import Title from '../components/Title'
import Pagination from '../components/Pagination'
import bgGray from '../assets/images/bgGray.png'
import { useQuery } from "@tanstack/react-query";
import inquisitiveService from "../services/inquisitiveService";
import Spiner from '../components/Spiner'

const perPage = 6

const ForInquisitive = () => {
    const [currentPage, setCurrentPage] = useState(1)

    const { data, isSuccess, isLoading } = useQuery(
        ['inquisitive'],
        inquisitiveService.getAllInquisitive,
    )

    return (
        <>
            {
                isLoading && <Spiner />
            }
            {
                isSuccess && (
                    <>
                        <Container>
                            <BigTitle>Dla dociekliwych</BigTitle>
                            <Title> Jeszcze więcej wiedzy </Title>
                        </Container>
                        {
                            data.map((item, index) => (index < currentPage * perPage) && (index >= (currentPage - 1) * perPage) &&
                                <NewRow key={index} data={item} bg={bgGray} link={`/for-inquisitive/${item.id}`} />)
                        }
                        <Container disablePaddingTop>
                            <PagginationWrapper>
                                <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} pages={data.length}
                                    perPage={perPage} />
                            </PagginationWrapper>
                        </Container>
                        <StyledStages noBackground />
                        <Slider
                            data={slide1Data}
                            component={<Card />}
                            breakpoints={{
                                1400: {
                                    slidesPerView: 3
                                },
                                900: {
                                    slidesPerView: 2
                                },
                            }}
                            isCenter={true}
                            bgColor={'lightGray'}
                        />
                    </>
                )
            }
        </>
    )
}

export default ForInquisitive

const PagginationWrapper = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 80px;
    margin-top: 40px;
`

const StyledStages = styled(Stages)`
    margin: 40px 0;
`