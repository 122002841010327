import axios from 'axios'
import store from '../store'

axios.defaults.baseURL = process.env.REACT_APP_API_URL

axios.interceptors.request.use(function (config) {
    const token = store.getState().auth.token

    if (token) {
        config.headers.Authorization = `Bearer ${token}`
    }
    return config
})

axios.interceptors.response.use(
    (response) => { // Any status code from range of 2xx
        // Do something with response data
        return response;
    },
    (error) => {
        if (error.response.data.message === 'Expired JWT Token') {
            store.getActions().auth.logoutUser();

           window.location.href = '/login/expired'
        }

        return Promise.reject(error);
    });

export default axios
