import React, { forwardRef, useState } from 'react'
import InputGroup from './InputGroup'
import Label from './Label'
import styled from 'styled-components'
import { FaEye, FaEyeSlash } from 'react-icons/fa';

const TextField = forwardRef(({ label, name, onChange, ...props }, ref) => {

    const [type, setType] = useState('password')

    const [isShowPassword, setIsShowPassword] = useState(false)

    const handleClickEye = () => {
        setIsShowPassword(prev => !prev)
        type === 'password' ? setType('text') : setType('password')
    }


    return (
        <InputGroupStyled>
            {
                label && <Label label={label} />
            }
            <InputStyled
                name={name}
                ref={ref}
                onChange={onChange}
                type={type}
                {...props}
            />

            <EyeWrapper onClick={handleClickEye}>
                {
                    isShowPassword ? <FaEye /> : <FaEyeSlash />
                }
            </EyeWrapper>

        </InputGroupStyled>
    )
})

export default TextField

const InputStyled = styled.input`
    width: 100%;
    height: 45px;
    padding: 0 40px 0 15px;
    border: 1px solid ${props => props.theme.colors.gray};
    border-radius: 0;
    background-color: white;
    outline: none;
    color: ${props => props.theme.colors.yellowGreen};
    &::placeholder {
        color: ${props => props.theme.colors.yellowGreen};
   
    }
`

const InputGroupStyled = styled(InputGroup)`
    position: relative;
`

const EyeWrapper = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    height: 100%;
    width: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    
`