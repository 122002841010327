import React from 'react'

const Link = (props) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={43.381}
            height={21.69}
            {...props}
        >
            <path
                data-name="link_FILL0_wght400_GRAD0_opsz48"
                d="M20.064 21.69h-9.219a10.452 10.452 0 0 1-7.673-3.172A10.452 10.452 0 0 1 0 10.845a10.452 10.452 0 0 1 3.172-7.673A10.452 10.452 0 0 1 10.845 0h9.218v3.254h-9.218a7.315 7.315 0 0 0-5.368 2.223 7.593 7.593 0 0 0 0 10.737 7.315 7.315 0 0 0 5.368 2.223h9.218Zm-6.778-9.218V9.218H30.1v3.254Zm10.031 9.218v-3.253h9.218a7.591 7.591 0 0 0 7.592-7.592 7.591 7.591 0 0 0-7.592-7.592h-9.218V0h9.218a10.831 10.831 0 0 1 10.846 10.845A10.831 10.831 0 0 1 32.536 21.69Z"
            />
        </svg>
    )
}

export default Link