import React from 'react'
import styled, { css } from 'styled-components'

const NewTitle = ({ textAlign = 'center', color = 'primary', variant = 'h1', headerStyle, children, ...props }) => {
    return (
        <>
            {variant === 'h1' && <H1 textAlign={textAlign} color={color} className={headerStyle ? headerStyle : 'h1'} {...props}>{children}</H1>}
            {variant === 'h2' && <H2 textAlign={textAlign} color={color} className={headerStyle ? headerStyle : 'h2'} {...props}>{children}</H2>}
            {variant === 'h3' && <H3 textAlign={textAlign} color={color} className={headerStyle ? headerStyle : 'h3'} {...props}>{children}</H3>}
            {variant === 'h4' && <H4 textAlign={textAlign} color={color} className={headerStyle ? headerStyle : 'h4'} {...props}>{children}</H4>}
            {variant === 'h5' && <H5 textAlign={textAlign} color={color} className={headerStyle ? headerStyle : 'h4'} {...props}>{children}</H5>}
        </>
    )
}

export default NewTitle

const TitleStyles = css`
    &.h1 {
        font-size: 64px;
        line-height: 81px;
        font-weight: bold;
        font-family: 'Merriweather', serif;
    }
    &.h2 {
        font-size: 48px;
        line-height: 60px;
        font-weight: 500;
        font-family: 'Lato', sans-serif;
    }
    &.h3 {
        font-size: 32px;
        line-height: 40px;
        font-weight: bold;
        font-family: 'Merriweather', serif;
    }
    &.h4 {
        font-size: 24px;
        line-height: 38px;
        font-weight: bold;
        font-family: 'Merriweather', serif;
    }
    &.h5 {
        font-size: 20px;
        line-height: 24px;
        font-family: 'Lato', sans-serif;
    }
`

const H1 = styled.h1`
    text-align: ${props => props.textAlign};
    color: ${props => props.theme.colors[props.color]};
    ${TitleStyles}
`
const H2 = styled.h2`
    text-align: ${props => props.textAlign};
    color: ${props => props.theme.colors[props.color]};
    ${TitleStyles}
`
const H3 = styled.h3`
    text-align: ${props => props.textAlign};
    color: ${props => props.theme.colors[props.color]}; 
    ${TitleStyles}
`
const H4 = styled.h4`
    text-align: ${props => props.textAlign};
    color: ${props => props.theme.colors[props.color]}; 
    ${TitleStyles}
`
const H5 = styled.h5`
    text-align: ${props => props.textAlign};
    color: ${props => props.theme.colors[props.color]}; 
    ${TitleStyles}
`