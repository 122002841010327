import React from 'react'
import styled, { keyframes } from 'styled-components'

const Spiner = () => {
    return (
        <StyledSpiner />
    )
}

export default Spiner

const rotate = keyframes`
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
`;

const StyledSpiner = styled.div`
    border: 4px solid #f3f3f3;
    border-radius: 50%;
    border-top: 4px solid #3498db;
    width: 25px;
    height: 25px;
    animation: ${rotate} 2s linear infinite;
    margin: auto;
`

