import React from 'react'

const SortDesc = ({ isActive, ...props }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={23.734}
            height={20.498}
            {...props}
        >
            <g >
                <path data-name="Polygon 4" d="m11.867 19.5-11-19h22Z" fill={isActive ? "#007a81" : 'transparent'} />
                <path
                    data-name="Polygon 4 - Outline"
                    d="M11.867 20.5 0 0h23.734ZM1.734 1l10.133 17.5L22 1Z"
                    fill="#007a81"
                />
            </g>
        </svg>
    )
}

export default SortDesc