import React from 'react'
import styled from 'styled-components'

const FlexBox = ({ padding, whiteSpace, margin, justifyContnet, alignItems, flexDirection, flex, gap, flexWrap, children, ...props }) => {
    return (
        <StyledFlexBox
            margin={margin}
            justifyContnet={justifyContnet}
            alignItems={alignItems}
            flexDirection={flexDirection}
            flex={flex}
            gap={gap}
            flexWrap={flexWrap}
            whiteSpace={whiteSpace}
            padding={padding}
            {...props}
        >
            {children}
        </StyledFlexBox>
    )
}

export default FlexBox

const StyledFlexBox = styled.div`
    display: flex;
    justify-content: ${props => props.justifyContnet};
    align-items: ${props => props.alignItems};
    flex-direction: ${props => props.flexDirection};
    flex: ${props => props.flex};
    gap: ${props => props.gap};
    margin: ${props => props.margin};
    flex-wrap: ${props => props.flexWrap};
    white-space: ${props => props.whiteSpace};
    padding: ${props => props.padding};
`