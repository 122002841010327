import React, { useState } from 'react'
import { FaSearch } from 'react-icons/fa'
import styled from 'styled-components'


const Search = ({ iconcolor, ...props }) => {
    // eslint-disable-next-line 
    const [isActive, setIsActive] = useState(false)
    return (
        <SearchStyled isActive={isActive} {...props}>
            <FaSearchStyled
                // onClick={() => setIsActive(prev => !prev)}
                iconcolor={iconcolor}
            />
            <Input isActive={isActive} />
        </SearchStyled>
    )
}

export default Search


const SearchStyled = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 250px;
    width: 100%;
    gap: ${props => props.isActive ? '20px' : '0px'};
    transition: all 0.3s ease-in-out;
`

const Input = styled.input`
    border: none;
    outline: none;
    padding: ${props => props.isActive ? '6px' : '0'};
    width: ${props => props.isActive ? '100%' : '0%'};
    transition: all 0.3s ease-in-out;
    opacity: ${props => props.isActive ? '1' : '0'};
`



const FaSearchStyled = styled(FaSearch)`
    min-width: 28px;
    min-height: 28px;
    cursor: pointer;
    color: ${props => props.theme.colors[props.iconcolor]};

`


