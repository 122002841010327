import { action, persist, thunk, computed } from 'easy-peasy'
import authService from '../services/authService'
import userService from '../services/userService'
import jwt_decode from 'jwt-decode'

const AuthStore = persist(
    {
        username: null,
        roles: [],
        token: null,
        exp: null,
        isAuthenticated: computed((state) => {
            return !!state.username && !!state.token
        }),
        setUser: action((state, { username, roles, exp, token }) => {
            state.username = username
            state.roles = roles
            state.exp = exp
            state.token = token
        }),
        loginUser: thunk(async (actions, payload) => {
            try {
                const { data } = await authService.login(payload)
                const token = data.token
                const { username, roles, exp } = jwt_decode(token)
                actions.setUser({ username, roles, exp, token })
            } catch (e) {
                throw new Error(e)
            }
        }),
        relogUser: thunk(async (actions, payload) => {
            try {
                const { token } = await userService.renewToken()
                const { username, roles, exp } = jwt_decode(token)
                actions.setUser({ username, roles, exp, token })
            } catch (e) {
                throw new Error(e)
            }
        }),
        logoutUser: action((state, payload) => {
            state.username = null
            state.roles = []
            state.exp = null
            state.token = null
        }),
    },
    {
        storage: localStorage,
    },
)

export default AuthStore
