import React from 'react'
import { toast } from 'react-toastify';
import styled from 'styled-components'


const CustomMessage = ({title, description}) => (
    <CustomToastMessage>
       <p className={'title'}>{title}</p>
       <p className={'description'}>{description}</p>
    </CustomToastMessage>
)

const Toast = ({title, description, type}) => {

    const message = !description ? title : <CustomMessage title={title} description={description} />

    toast[type](message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
    })
}
const CustomToastMessage = styled.div`
  .title {
    font-weight: bold;
    margin-bottom: 7px;
  }
  .description {
    font-size: 11px;
  }
`;

export default Toast;


