import React from 'react'
import styled from 'styled-components'
import kudos from '../assets/images/kudos.svg'
import kudosAlt from '../assets/images/kudosAlt.svg'
import medal from '../assets/images/medal.svg'
import medalAlt from '../assets/images/medalAlt.svg'
import goblet from '../assets/images/goblet.svg'
import gobletAlt from '../assets/images/gobletAlt.svg'


const imageType = (category, active) => {
    if (category === 1 && active === true) {
        return kudos
    }
    else if (category === 1 && active === false) {
        return kudosAlt
    }
    else if (category === 2 && active === true) {
        return medal
    }
    else if (category === 2 && active === false) {
        return medalAlt
    }
    else if (category === 3 && active === true) {
        return goblet
    }
    else if (category === 3 && active === false) {
        return gobletAlt
    }
    else return null
}

const TrophyCard = ({ title, description, category, active, points, ...props }) => {
    return (
        <StyledTrophyCard {...props}>
            <Image src={imageType(category, active)} />
            <Title isActive={active}>{title}</Title>
            <Description>{description}</Description>
            {
                points !== undefined && <Points isActive={active}>{points}</Points>
            }
        </StyledTrophyCard>
    )
}

export default TrophyCard

const StyledTrophyCard = styled.div`
    width: 244px;
    height: 350px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid ${props => props.theme.colors.yellowGreen};
    padding: 20px 12px;
    position: relative;
`
const Image = styled.img`
    display: block;
    width: 160px;
    height: auto;
    margin-bottom: 27px;
`

const Title = styled.div`
    font-size: 22px;
    line-height: 36px;
    font-family: 'Merriweather', serif;
    text-align: center;
    color: ${props => props.isActive ? props.theme.colors.yellowGreen : '#858585'};
    margin-bottom: 37px;
`

const Description = styled.div`
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #858585;
`

const Points = styled.div`
    width: 55px;
    height: 55px;
    font-size: 20px;
    font-weight: bold;
    position: absolute;
    top: 80px;
    right: 40px;
    border: 2px solid white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    background-color: ${props => props.isActive ? props.theme.colors.primary : '#e8e8e8'};
`
