import React, { useEffect, useMemo, useState } from 'react'
import BigTitle from '../components/BigTitle'
import Container from '../components/Container'
import Title from '../components/Title'
import QuizQuestion from '../components/QuizQuestion'
import { useForm } from 'react-hook-form'
import Button from '../components/Button'
import styled from 'styled-components'
import Stages from '../components/Stages'
import Slider from '../components/Slider'
import Card from '../components/Card'
import { slide1Data } from '../FakeData/SliderData'
import quizService from '../services/quizService'
import { useQuery, useMutation } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import Modal from '../components/Modal'
import Link from '../components/Link'
import { useStoreActions } from 'easy-peasy'
import Spiner from '../components/Spiner'
import { toast } from 'react-toastify'

const Quiz = () => {
    const [stage, setStage] = useState(0)
    const [checkedQuestions, setCheckedQuestion] = useState([])
    const [isOpen, setIsOpen] = useState(false)

    const logout = useStoreActions(actions => actions.auth.logoutUser)

    const { id } = useParams()
    const { handleSubmit, register } = useForm()

    const handleResponse = (data) => {
        setCheckedQuestion(data)
    }

    const {
        data: dataQuiz,
        isSuccess: isSuccessDataQuiz,
        isLoading: isLoadingDataQuiz
    } = useQuery(
        ['quiz', id],
        quizService.getQuizDetails(id)
    )

    const { mutate: finishQuiz, isSuccess: isSuccessFinishQuiz, isLoading: isLoadingFinishQuiz } = useMutation(
        quizService.finishTheQuiz(dataQuiz?.id),
        {
            onSuccess: (data) => {
                handleResponse(data)
                setStage(1)
            },
            onError: (error) => {
                toast.error(error?.response?.data || 'Ups... Coś poszło nie tak.')
            }
        }

    )

    const onSubmit = (values) => {
        finishQuiz({ userAnswersIds: Object.values(values) })
    }

    const handleLogout = () => {
        logout()
    }

    const isFinished = useMemo(() => {
        if (dataQuiz?.finished) {
            return true
        }
        else {
            return false
        }
        // eslint-disable-next-line 
    }, [dataQuiz])


    // const handleShowResult = () => {
    //     finishQuiz()
    //     setIsOpen(true)
    // }

    useEffect(() => {
        if (isSuccessDataQuiz && dataQuiz) {
            dataQuiz.finished && finishQuiz()
        }
        // eslint-disable-next-line 
    }, [dataQuiz, isSuccessDataQuiz])

    return (
        <>
            {
                isLoadingDataQuiz && <Spiner />
            }
            {
                isSuccessDataQuiz && (
                    <>
                        <Container>
                            <BigTitle>
                                {dataQuiz.name}
                            </BigTitle>
                            <Title>
                                {dataQuiz.subTitle}
                            </Title>
                        </Container>
                        {
                            isFinished ? (
                                <>
                                    {
                                        isLoadingFinishQuiz && <Spiner />
                                    }
                                    {
                                        isSuccessFinishQuiz && (
                                            <Container>
                                                <QuizResult>
                                                    <QuizResultTitle>Dziękujemy za rozwiązanie quizu!</QuizResultTitle>
                                                    <div>Osiągnięty przez Ciebie wynik to <span className='info'>{checkedQuestions.correctAnswersPercent}%</span>.</div>
                                                    <QuizResultButtons>
                                                        <StyledLink to='/my-studies'>
                                                            <Button>Wróć do nauki</Button>
                                                        </StyledLink>
                                                        <StyledLink to='/login'>
                                                            <Button onClick={handleLogout}>Wyloguj się</Button>
                                                        </StyledLink>
                                                    </QuizResultButtons>
                                                </QuizResult>

                                            </Container>
                                        )
                                    }
                                </>
                            ) : (
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    {
                                        dataQuiz.quizQuestions.map((question, index) => (
                                            <QuizQuestion
                                                register={register}
                                                key={index}
                                                data={question}
                                                number={index + 1}
                                                quizQuestions={checkedQuestions?.quizQuestions?.length > 0 ? checkedQuestions.quizQuestions[index] : null}
                                            />
                                        ))
                                    }
                                    <Container disablePaddingTop>
                                        <ButtonWrapper>
                                            {
                                                (stage === 0 && dataQuiz.isQuizInCurrentHalf) && (
                                                    <Button type='submit'>Sprawdź wyniki</Button>
                                                )
                                            }
                                            {
                                                stage === 1 && (
                                                    <Button onClick={() => setIsOpen(true)} type='button'>Zakończ</Button>
                                                )
                                            }
                                        </ButtonWrapper>
                                    </Container>
                                </form>
                            )
                        }

                        <StyledStages noBackground />
                        <Slider
                            data={slide1Data}
                            component={<Card />}
                            breakpoints={{
                                1400: {
                                    slidesPerView: 3
                                },
                                900: {
                                    slidesPerView: 2
                                },
                            }}
                            isCenter={true}
                            bgColor={'lightGray'}
                        />
                        <Modal isOpen={isOpen} setIsOpen={setIsOpen} >
                            <div>
                                <ModalTitle>Dziękujemy za udział.</ModalTitle>
                                <div>
                                    <div> Liczba pytań: {checkedQuestions.questionsCount}.</div>
                                    <div>Liczba poprawnych odpowiedzi: {checkedQuestions.myCorrectAnswers}.</div>
                                    <div>Liczba niepoprawnych odpowiedzi: {checkedQuestions.incorrectAnswersCount}.</div>
                                    <div>Uzyskano {checkedQuestions.correctAnswersPercent}% poprawnych odpowiedzi.</div>
                                </div>
                                <ModalButtons>
                                    <StyledLink to='/my-studies'>
                                        <Button>Wróć do nauki</Button>
                                    </StyledLink>
                                    <StyledLink to='/login'>
                                        <Button onClick={handleLogout}>Wyloguj się</Button>
                                    </StyledLink>
                                </ModalButtons>
                            </div>
                        </Modal>
                    </>
                )
            }
        </>
    )
}

export default Quiz


const ButtonWrapper = styled.div`
    max-width: 1170px;
    margin: auto;
`

const StyledStages = styled(Stages)`
    margin-top: 48px;
    margin-bottom: 40px;
`


const ModalTitle = styled.div`
    font-family: 'Merriweather', serif;
    font-size: 24px;
    line-height: 38px;
    margin-bottom: 40px;
`

const ModalButtons = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
    margin-bottom: 25px;
    @media screen and (max-width: 400px) {
        flex-direction: column;
        align-items: center;
        gap: 20px;
      }
`

const StyledLink = styled(Link)`
    display: inline-block;
`
const QuizResult = styled.div`
      display: flex;
      flex-direction: column;
      justify-content: center;
      font-size: 30px;
      gap: 10px;
      max-width: 530px;
      margin: auto;
      text-align: center;
      .info {
        color: ${props => props.theme.colors.primary};
        font-weight: bold;
      }

`

const QuizResultTitle = styled.div`
      font-weight: bold;
      font-size: 35px;
      margin-bottom: 20px;
`

const QuizResultButtons = styled.div`
      display: flex;
      justify-content: space-between;
      margin-top: 30px;
      
      gap: 30px;
      @media only screen and (max-width: 600px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        
    }

`



