import * as React from "react"

const Chat = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={54} height={54} {...props}>

        <g data-name="Group 2806">
            <path
                data-name="Ellipse 10"
                d="M27 2a25.007 25.007 0 0 0-9.731 48.036A25.007 25.007 0 0 0 36.731 3.964 24.842 24.842 0 0 0 27 2m0-2A27 27 0 1 1 0 27 27 27 0 0 1 27 0Z"

            />
            <g data-name="Group 2710">
                <g
                    data-name="Group 92"
                    clipPath="url(#a)"

                    transform="translate(12 10)"
                >
                    <path
                        data-name="Path 284"
                        d="M30.564 20.684a9.9 9.9 0 0 0-7.235-9.52A11.67 11.67 0 1 0 1.618 17.6L.043 23.3l5.7-1.575a11.627 11.627 0 0 0 5.426 1.606 9.883 9.883 0 0 0 14.542 5.859l4.809 1.33-1.33-4.809a9.844 9.844 0 0 0 1.373-5.027m-24.543-.9-3.41.943.943-3.41-.215-.336A9.876 9.876 0 1 1 6.357 20Zm21.931 8.166-2.53-.7-.338.22a8.092 8.092 0 0 1-12.069-4.21 11.692 11.692 0 0 0 10.249-10.244 8.092 8.092 0 0 1 4.209 12.069l-.22.338Z"
                    />
                    <path
                        data-name="Rectangle 101"
                        d="M10.775 16.177h1.791v1.791h-1.791z"
                    />
                    <path
                        data-name="Path 285"
                        d="M13.462 8.955a1.772 1.772 0 0 1-.583 1.322l-2.1 1.925v2.185h1.791v-1.4l1.522-1.393a3.582 3.582 0 1 0-6-2.643h1.791a1.791 1.791 0 1 1 3.582 0"
                    />
                </g>
            </g>
        </g>
    </svg>
)

export default Chat
