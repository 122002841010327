import { rgba } from 'polished'
import React, { forwardRef } from 'react'
import styled, { css } from 'styled-components'
import arrowDownRed from '../assets/images/arrowDownRed.svg'
import arrowDown from '../assets/images/arrowDown.svg'


const Checkbox = forwardRef(({ isDisabled, isSelected, status = null, variant = 1, borderColor = 'gray', text, ...props }, ref) => {

    return (
        <Label isSelected={isSelected} isDisabled={isDisabled}>
            {
                variant === 1 && (
                    <>
                        <InputStyled1
                            borderColor={borderColor}
                            type={'checkbox'}
                            ref={ref}
                            {...props}
                        />
                        {text ? text : 'Zapamiętaj mnie'}
                    </>
                )
            }
            {
                variant === 2 && (
                    <>
                        <InputStyled2
                            type={'checkbox'}
                            ref={ref}
                            status={status}

                            {...props}




                        />
                        <Text status={status}>{text}</Text>
                    </>
                )
            }
        </Label>
    )
})

export default Checkbox

const selectedStyle = css`
    font-weight: bold;
    background-color: ${props => props.theme.colors.aquamarine};


`

const Label = styled.label`
    display: inline-flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    pointer-events: ${props => props.isDisabled ? 'none' : 'auto'};
    ${props => props.isSelected && selectedStyle}
    
`

const InputStyled1 = styled.input`
    appearance: none;
    max-width: 30px;
    min-width: 30px;
    max-height: 30px;
    min-height: 30px;
    border: 1px solid ${props => props.theme.colors[props.borderColor]};
    outline: none;
    cursor: pointer;
    margin: 0;
    position: relative;
    background-color: white;
    &:checked::before {
        display: flex;
        content: "✔";
        position: absolute;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        font-size: 20px;
    }
`

const InputStyled2 = styled.input`
    appearance: none;
    min-width: 40px;
    min-height: 40px;
    border-radius: 50%;
    border: 1px solid ${props => props.status && props.status === 'wrong'
        ? props.theme.colors.red : props.status === 'good'
            ? props.theme.colors.primary : props.status === 'disabled'
                ? rgba('#000', 0.3) : props.theme.colors.primary};
    cursor: pointer;
    background-position: center;
    background-size: 60%;
    background-repeat: no-repeat;
    &:checked {
        background-image: url(${props => props.status && props.status === 'wrong' ? arrowDownRed : props.status === 'good' ? arrowDown : arrowDown});
    }  
`



const Text = styled.p`
    font-size: 20px;
    color: ${props => props.status && props.status === 'wrong'
        ? props.theme.colors.red : props.status === 'good'
            ? props.theme.colors.primary : props.status === 'disabled'
                ? rgba('#000', 0.3) : props.theme.colors.primary};
`