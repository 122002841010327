import React from 'react'
import useMediaQuery from '../../Hooks/MediaQueryHook'
import DesktopNav from './DesktopNav'
import MobileNav from './MobileNav'
import theme from '../../theme'
import Breadcrumbs from "./Breadcrumbs";
import styled from 'styled-components'


const Header = () => {
    const isMobile = useMediaQuery(`(max-width: ${theme.mobile2})`)

    if(isMobile) {
        return (
            <HeaderWrapper>
                <MobileNav />
                <Breadcrumbs/>
            </HeaderWrapper>
        )
    } else {
        return (
            <HeaderWrapper>
                <DesktopNav />
                <Breadcrumbs/>
            </HeaderWrapper>
        )
    }
}
const HeaderWrapper = styled.header`
  position: fixed;
  width: 100%;
  z-index: 999;
`;

export default Header
