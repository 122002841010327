import React, { useState } from 'react'
import Container from '../components/Container'
import styled from 'styled-components'
import BigTitle from '../components/BigTitle'
import Title from '../components/Title'
import TaskRow from '../components/TaskRow'
import calendar from '../assets/images/calendar.svg'
import ProTip from '../components/ProTip'
import Button from '../components/Button'
import Stages from '../components/Stages'
import Slider from '../components/Slider'
import Card from '../components/Card'
import { slide1Data } from '../FakeData/SliderData'
import Modal from '../components/Modal'
import { BsExclamationTriangle } from 'react-icons/bs'
import { useMutation, useQuery } from '@tanstack/react-query'
import Toast from "../components/Toast";
import taskService from "../services/taskService";
import { useParams, useNavigate } from 'react-router-dom'
import TaskDescription from '../components/TaskDescription'
import TaskDescription2 from '../components/TaskDescription2'
import useMediaQuery from '../Hooks/MediaQueryHook'
import Spiner from '../components/Spiner'
import AttachmentRows from "../components/AttachmentRows";



const Task = () => {
    const isMobile = useMediaQuery(`(max-width: 1170px`)
    const isMobileVideo = useMediaQuery(`(max-width: 416px`)
    const { id } = useParams()
    const navigate = useNavigate()
    // const navigate = useNavigate()
    const [isOpen, setIsOpen] = useState(false)
    // eslint-disable-next-line 
    const [isQuiz, setIsQuiz] = useState(true)

    const {
        data: dataTask,
        isSuccess: isSuccessTasks,
        isLoading: isLoadingDataTask,
        refetch,
    } = useQuery(
        ['task', id],
        taskService.getTaskDetails(id)
    )

    const { mutate: startTask } = useMutation(
        taskService.startTask(),
        {
            onSuccess: () => {
                refetch();
                Toast({
                    type: 'success',
                    title: 'Zadanie zostało rozpoczęte',
                });
            },
            onError: (error) => {
                Toast({
                    type: 'error',
                    title: 'Wystąpił błąd',
                    description: error.response?.data
                });
            }
        }
    )


    const { mutate: stopTask } = useMutation(
        taskService.finishTask(),
        {
            onSuccess: () => {

                refetch()
                Toast({
                    type: 'success',
                    title: 'Zadanie zostało zakończone',
                });
            },
            onError: (error) => {
                Toast({
                    type: 'error',
                    title: 'Wystąpił błąd',
                    description: error.response?.data
                });
            }
        }
    )


    const handleStartQuiz = () => {
        if (dataTask.userStatus.name === 'FINISHED') {
            navigate('quiz')
        }
        else {
            setIsOpen(true)
        }
    }


    return (
        <>
            {
                isLoadingDataTask && <Spiner />
            }
            {
                isSuccessTasks && (
                    <>
                        <Container>
                            <Wrapper>
                                <BigTitle>{dataTask.name}</BigTitle>
                                <Title>{dataTask.extraDescription}</Title>
                            </Wrapper>
                        </Container>
                        <StyledTaskRow
                            data={{
                                createdAtText: dataTask.dateFromText,
                                degree: dataTask.lecturer.title,
                                fullName: `${dataTask.lecturer.firstName} ${dataTask.lecturer.lastName}`,
                                abstract: dataTask.abstract,
                                tags: dataTask.tags,
                                image: dataTask.image
                            }}
                            noBackground
                            noButton
                            extraComponent={isMobile ? (
                                <ProTip
                                    title={dataTask.tipTitle}
                                    description={dataTask.tipContent}
                                />
                            ) : null}
                        />
                        <Container>
                            <Wrapper>
                                <Row>
                                    <Box>
                                        <Header>
                                            Termin wykonania zadania:
                                        </Header>
                                        <CalendarBox>
                                            <img src={calendar} alt='calendar' />
                                            {`${dataTask.dateFromText} - ${dataTask.dateToText}`}
                                        </CalendarBox>
                                        <Header>
                                            Opis zadania:
                                        </Header>
                                        {dataTask.stackItems.length === 0 && dataTask.content ?
                                            <TaskDescription2 data={dataTask.content} />
                                            :
                                            <TaskDescription data={dataTask.stackItems} />
                                        }
                                        <ButtonsBox>
                                            {
                                                dataTask.userStatus.name === 'NOT_TAKEN' &&
                                                <Button onClick={() => startTask(dataTask.id)}>Rozpocznij Zadanie</Button>
                                            }
                                            {
                                                dataTask.userStatus.name !== 'NOT_TAKEN' &&
                                                <Button disabled={dataTask.userStatus.name === 'FINISHED'} onClick={() => {
                                                    if (dataTask.userStatus.name !== 'FINISHED') {
                                                        stopTask(dataTask.id);
                                                    }
                                                }
                                                }
                                                >
                                                    Zakończ Zadanie
                                                </Button>
                                            }
                                            {dataTask?.isQuiz && <Button onClick={handleStartQuiz}>Rozpocznij Quiz</Button>}
                                        </ButtonsBox>
                                    </Box>
                                    <div>
                                        {
                                            !isMobile && (
                                                <ProTip
                                                    title={dataTask.tipTitle}
                                                    description={dataTask.tipContent}
                                                />
                                            )
                                        }

                                        {
                                            dataTask.taskAttachments.length > 0 && (
                                                <Attachment>
                                                    <Header>
                                                        Załączniki do zadania:
                                                    </Header>
                                                    {
                                                        dataTask.taskAttachments.map((item, index) => {
                                                            if (item.type === 'text') {
                                                                return (
                                                                    <p key={index}>{item.content}</p>
                                                                )
                                                            }
                                                            else if (item.type === 'link') {
                                                                return (
                                                                    <span key={index}>
                                                                        <a href={item.content} target="_blank" rel="noopener noreferrer">
                                                                            Link: {item.content}
                                                                        </a>
                                                                    </span>
                                                                )
                                                            }
                                                            else if (item.type === 'video') {
                                                                return (
                                                                    <IframeWrapper key={index}>
                                                                        <IframeTitle>
                                                                            Video
                                                                        </IframeTitle>
                                                                        <iframe width={isMobileVideo ? '300' : '400'} height={isMobileVideo ? '168' : '225'} src={item.content} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                                                    </IframeWrapper>
                                                                )
                                                            }
                                                            return null
                                                        })
                                                    }
                                                </Attachment>
                                            )
                                        }

                                    </div>
                                </Row>
                            </Wrapper>
                        </Container>
                        <AttachmentRows attachments={dataTask.taskAttachments}/>
                        <div style={{marginBottom: '40px'}}>
                            <Stages noBackground />
                        </div>
                        <Slider
                            data={slide1Data}
                            component={<Card />}
                            breakpoints={{
                                1400: {
                                    slidesPerView: 3
                                },
                                900: {
                                    slidesPerView: 2
                                },
                            }}
                            isCenter={true}
                            bgColor={'lightGray'}
                        />
                        <Modal isOpen={isOpen} setIsOpen={setIsOpen} >
                            <StyledBsExclamationTriangle />
                            <div>
                                Jeśli chcesz rozpocząć quiz musisz zakończyć zadanie.
                            </div>
                            <div>
                                <div>
                                    Quiz nie jest jeszcze dostępny.
                                </div>
                                <div>
                                    O jego dostępności poinformujemy Cię drogą mailową na adres e-mail używany do logowania.
                                </div>
                            </div>
                        </Modal>
                    </>
                )
            }
        </>
    )
}

export default Task

const Wrapper = styled.div`
    max-width: 1160px;
    margin: auto;
`

const Box = styled.div`
    width: 680px;
`

const Row = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 20px;
    margin-bottom: 40px;
    @media only screen and (max-width: ${props => props.theme.mobile}) {
       flex-wrap: wrap;
       justify-content: center;
       ${Box} {
        width: 100%;
       }
    }
`

const CalendarBox = styled.div`
    display: flex;
    font-size: 20px;
    align-items: center;
    gap: 20px;
    margin-bottom: 40px;
    @media only screen and (max-width: ${props => props.theme.mobile1}) {
        justify-content: center;
    }
`

const Header = styled.div`
    font-size: 24px;
    font-weight: bold;
    line-height: 38px;
    color: ${props => props.theme.colors.primary};
    font-family: 'Merriweather', serif;
    margin-bottom: 16px;
    @media only screen and (max-width: ${props => props.theme.mobile1}) {
        text-align: center;
    }
`

const ButtonsBox = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 40px;
    gap: 100px;
    @media only screen and (max-width: 600px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;
    }
`

const StyledTaskRow = styled(TaskRow)`
    padding: 0px;
    margin-bottom: 40px;
    margin-top: 20px;
`



const StyledBsExclamationTriangle = styled(BsExclamationTriangle)`
    color: #E2000F;
    font-size: 40px;
    display: block;
    margin: auto;
`

const Attachment = styled.div`
    max-width: 400px;
    ${Header} {
        margin-top: 40px;
        margin-bottom: 20px;
    }
    a {
        color: inherit;
        text-decoration: none;
        display: block;
        margin-bottom: 10px;
    }
  p {
    margin-top: 10px;
  }
`

const IframeWrapper = styled.div`
    margin-bottom: 20px;
`

const IframeTitle = styled.div`
    font-size: 20px;
    margin-bottom: 16px;
`
