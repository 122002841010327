import React from 'react'

const DoubleArrowRight = ({ ...props }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={27.2} height={24.1} {...props}>
            <g data-name="Group 2756" >
                <path d="M2.15 24.1 0 21.95l9.9-9.9L0 2.15 2.15 0 14.2 12.05Z" />
                <path
                    data-name="navigate_next_FILL0_wght400_GRAD0_opsz48"
                    d="M15.15 24.1 13 21.95l9.9-9.9-9.9-9.9L15.15 0 27.2 12.05Z"
                />
            </g>
        </svg>
    )
}

export default DoubleArrowRight