import React from 'react'
import styled from 'styled-components'

const BigTitle = ({color = 'primary', children, ...props }) => {
    return (
        <StyledBigTitle color={color} {...props}>
            {children}
        </StyledBigTitle>
    )
}

export default BigTitle

const StyledBigTitle = styled.h1`
    font-size: 64px;
    line-height: 81px;
    font-weight: bold;
    margin-bottom: 32px;
    color: ${props => props.theme.colors[props.color]};
    font-family: 'Merriweather', serif;
    text-align: center;
    @media only screen and (max-width: ${props => props.theme.mobile}) {
        font-size: 28px;
        line-height: 36px;
        font-weight: bold;
        margin-bottom: 16px;
    }
`