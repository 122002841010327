import * as React from "react"

const Building = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={54} height={54} {...props}>

        <g data-name="Group 2803">
            <path
                data-name="Ellipse 72"
                d="M27 2a25.007 25.007 0 0 0-9.731 48.036A25.007 25.007 0 0 0 36.731 3.964 24.842 24.842 0 0 0 27 2m0-2A27 27 0 1 1 0 27 27 27 0 0 1 27 0Z"

            />
            <g data-name="Group 2653">
                <g
                    data-name="Group 90"
                    clipPath="url(#a)"
                    transform="translate(12.973 10.943)"
                >
                    <path
                        data-name="Path 283"
                        d="M26.406 23.247V13.062a2.476 2.476 0 0 0 0-4.668V6.6a.826.826 0 0 0-.456-.739L14.4.087a.825.825 0 0 0-.738 0L2.107 5.863a.825.825 0 0 0-.456.739v1.792a2.476 2.476 0 0 0 0 4.668v10.185a2.476 2.476 0 0 0 .825 4.81h23.105a2.476 2.476 0 0 0 .825-4.81M3.3 7.112l10.728-5.364 10.727 5.364v1.14H3.3ZM2.476 9.9h23.105a.825.825 0 0 1 0 1.651H2.476a.825.825 0 0 1 0-1.651m22.28 13.2h-1.65v-9.9h1.65Zm-3.3 0H16.5v-9.9h4.951Zm-6.6 0H13.2v-9.9h1.65Zm-3.3 0H6.6v-9.9h4.951Zm-6.6 0H3.3v-9.9h1.65Zm20.63 3.3H2.476a.825.825 0 0 1 0-1.651h23.105a.825.825 0 0 1 0 1.651"

                    />
                </g>
            </g>
        </g>
    </svg>
)

export default Building
