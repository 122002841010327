import React from 'react'
import styled from 'styled-components'

const Image = ({ src, alt = '' }) => {
    return (
        <StyledImage src={src} alt={alt} />
    )
}

export default Image

const StyledImage = styled.img`
    max-width: 100%;
    height: auto;
    display: block;
`