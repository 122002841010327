import * as React from "react"

const User = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={54} height={54} {...props}>
        <g data-name="Group 2646">
            <g data-name="Ellipse 71" fill="none" stroke={props?.color || '#fff'} strokeWidth={2}>
                <circle cx={27} cy={27} r={27} stroke="none" />
                <circle cx={27} cy={27} r={26} />
            </g>
            <path
                d="M27.396 39.744H14.119a1.341 1.341 0 0 1-1.055-.5 1.378 1.378 0 0 1-.279-1.155 14.572 14.572 0 0 1 13.88-11.541c.111 0 .223.007.336.007a8.977 8.977 0 1 0-5.365-1.786A17.151 17.151 0 0 0 10.203 37.55a4.007 4.007 0 0 0 3.916 4.832h13.277a1.319 1.319 0 0 0 0-2.638Zm-6.727-22.158a6.331 6.331 0 1 1 6.481 6.331 1.3 1.3 0 0 0-.15-.009h-.309a6.338 6.338 0 0 1-6.022-6.322Zm23.213 18.2a1.319 1.319 0 0 1-1.319 1.319h-9.891a1.319 1.319 0 1 1 0-2.638h9.892a1.319 1.319 0 0 1 1.319 1.319Zm-11.21-6.593h9.892a1.319 1.319 0 1 1 0 2.638h-9.892a1.319 1.319 0 1 1 0-2.638Zm11.211 11.87a1.319 1.319 0 0 1-1.319 1.319h-9.892a1.319 1.319 0 1 1 0-2.638h9.892a1.319 1.319 0 0 1 1.319 1.319Zm0 0"
                fill={props?.color || '#fff'}
            />
        </g>
    </svg>
)

export default User
