import { action, persist } from 'easy-peasy'


const chairStore = persist(
    {
        chairLevel: null,
        setChairLevel: action((state, payload) => {
            state.chairLevel = payload
        }),
    },
    {
        storage: localStorage,
    },
)

export default chairStore
