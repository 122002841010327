import React from 'react'
import BigTitle from '../components/BigTitle'
import Card from '../components/Card'
import styled from 'styled-components'
import catedral from '../assets/images/catedral.jpg'
import teachers from '../assets/images/teachers.jpg'
import Container from '../components/Container'
import Stages from "../components/Stages";
import bulb from '../assets/images/dla-dociekliwych2.jpg'


const cardData = [
    {
        title: 'Katedry tematyczne',
        desc1: 'Uniwersytet Berlin-Chemie Menarini pozwoli Ci poszerzyć horyzonty i rozwinąć się w swojej dziedzinie, a także odkryć zupełnie nowe talenty.',
        image: catedral,
        imageAlt: 'graduade cap on books',
        link1: '/about/chairs',
        link1Text: 'Przejdź do katedr',
    },
    {
        title: 'Nasi wykładowcy',
        desc1: 'Czeka na Ciebie zespół wybitnych specjalistów i ekspertów, którzy podzielą się z Tobą swoją wiedzą oraz bogatym doświadczeniem.',
        image: teachers,
        imageAlt: 'teacher',
        link1: '/about/teachers',
        link1Text: 'Poznaj ekspertów',
    },
    {
        title: 'Dla dociekliwych',
        desc1: 'To miejsce dla osób wyjątkowo głodnych wiedzy. Znajdziesz tu dodatkowe informacje, najnowsze trendy i nowinki z różnych dziedzin.',
        image: bulb,
        imageAlt: 'teacher',
        link1: '/for-inquisitive',
        link1Text: 'Sięgnij po wiedzę',
    },
]


const About = () => {

    return (
        <>
            <Container>
                <BigTitle>
                    O Uniwersytecie
                </BigTitle>
                <Row>
                    {
                        cardData.map((item, index) => (
                            <Card
                                key={index}
                                data={item}
                                index={index}
                            />
                        ))
                    }
                </Row>
            </Container>
            <StyledStages noBackground />
        </>
    )
}

export default About

const Row = styled.div`
    display: flex;
    justify-content: center;
    gap: 70px; 
    flex-wrap: wrap;
`
const StyledStages = styled(Stages)`
    padding-top: 40px;
    padding-bottom: 40px;
`