import { createStore } from 'easy-peasy'
import authStore from './authStore'
import chairStore from './chairStore'
import userStore from './userStore'


export default createStore({
    auth: authStore,
    chair: chairStore,
    user: userStore
})
