import React from 'react'

const DoubleArrowLeft = ({ ...props }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={27.2} height={24.1} {...props}>
            <g data-name="Group 2755">
                <path d="m25.05 0 2.15 2.15-9.9 9.9 9.9 9.9-2.15 2.15L13 12.05Z" />
                <path
                    data-name="navigate_next_FILL0_wght400_GRAD0_opsz48"
                    d="m12.05 0 2.15 2.15-9.9 9.9 9.9 9.9-2.15 2.15L0 12.05Z"
                />
            </g>
        </svg>
    )
}

export default DoubleArrowLeft