import React from 'react'
import { Swiper, SwiperSlide, } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import styled from 'styled-components';


const Slider = (props) => {
    const pagination = {
        clickable: true,
    };
    return (
        <SwiperWrapper bgColor={props.bgColor}>
            <Swiper
                modules={[Navigation, Pagination, Scrollbar, A11y]}
                spaceBetween={0}
                slidesPerView={1}
                pagination={pagination}
                breakpoints={props.breakpoints}
            >
                {
                    props.data.map((card, index) => (
                        <SwiperSlide key={index}>
                            {
                                props.isCenter ? (
                                    <SlideWrapper>
                                        {React.cloneElement(props.component, { data: card })}
                                    </SlideWrapper>
                                ) : React.cloneElement(props.component, { data: card })
                            }
                        </SwiperSlide>
                    ))
                }
            </Swiper>
        </SwiperWrapper>
    )
}

export default Slider


const SwiperWrapper = styled.div`
    background-color: ${props => props.theme.colors[`${props.bgColor}`]};
    padding-top: 30px;
    z-index: 99;
    position: relative;
    .swiper-pagination-bullet {
        background-color: transparent;
        border: 2px solid ${props => props.theme.colors.primary};
        border-radius: 0;
        width: 26px;
        height: 26px;
        opacity: 1;
    }
    .swiper-pagination-bullet-active {
        background-color: ${props => props.theme.colors.primary};
    }
    .swiper {
        padding-bottom: 80px;
    }
    .swiper-pagination {
        bottom: 26px;
    }
`

const SlideWrapper = styled.div`
    display: flex;
    justify-content: center;
`

