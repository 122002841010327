import React from 'react'

const SingleArrowRight = ({ ...props }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={14.2} height={24.1} {...props}>
            <path
                d="M2.15 24.1 0 21.95l9.9-9.9L0 2.15 2.15 0 14.2 12.05Z"

            />
        </svg>
    )
}

export default SingleArrowRight