import React, { forwardRef, useMemo } from 'react'
import styled from 'styled-components'

const Select = forwardRef((props, ref) => {

    const arr = useMemo(() => {
        const points = []
        for (let i = 0; i <= props.max; i++) {
            if (i % 5 === 0) {
                points.push(i)
            }
        }
        return points
    }, [props.max])


    return (
        <StyledSelect ref={ref} {...props}>
            {
                arr.map((item, key) => <option key={key} value={item}>{item}</option>)
            }
        </StyledSelect>
    )
})

export default Select

const StyledSelect = styled.select`
    font-size: 32px;
    font-weight: bold;
    font-family: 'Merriweather', serif;
    color: ${props => props.theme.colors.primary};
    width: 65px;
    height: 50px;
    border: 1px solid ${props => props.theme.colors.primary};
    text-align: center;

`