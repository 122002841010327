import { rgba } from 'polished'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import DoubleArrowLeft from './Icons/DoubleArrowLeft'
import DoubleArrowRight from './Icons/DoubleArrowRight'
import SingleArrowLeft from './Icons/SingleArrowLeft'
import SingleArrowRight from './Icons/SingleArrowRight'




const Pagination = ({ currentPage = 1, setCurrentPage, pages = 10, perPage = 1 }) => {

    const pagesAmout = Math.ceil(pages / perPage)



    const numberOfPages = []

    for (let i = 1; i <= pagesAmout; i++) {
        numberOfPages.push(i)
    }
    const [currentButton, setCurrentButton] = useState(currentPage)
    const [arrOfCurrButtons, setArrOfCurrButtons] = useState([])

    const handleClcik = (e, number) => {
        e.target.innerText !== '...' && setCurrentButton(number)
    }

    useEffect(() => {
        let tempNumberOfPages = [...numberOfPages]


        if (currentButton >= 1 && currentButton <= 3 && pagesAmout > 5) {
            tempNumberOfPages = [1, 2, 3, 4, '...']
        }
        else if (currentButton >= 4 && currentButton < numberOfPages.length - 2 && pagesAmout > 5) {
            const sliced1 = numberOfPages.slice(currentButton - 2, currentButton)
            const sliced2 = numberOfPages.slice(currentButton, currentButton + 1)
            tempNumberOfPages = (['...', ...sliced1, ...sliced2, '...'])
        }
        else if (currentButton > numberOfPages.length - 3 && pagesAmout > 5) {
            const sliced = numberOfPages.slice(numberOfPages.length - 4)
            tempNumberOfPages = (['...', ...sliced])
        }

        setArrOfCurrButtons(tempNumberOfPages)
        setCurrentPage(currentButton)
        // eslint-disable-next-line
    }, [currentButton])



    return (
        <StyledPaginarion>
            <StyledDoubleArrowLeft
                isdisabled={currentButton === 1 ? 1 : 0}
                onClick={() => setCurrentButton(1)}
            />
            <StyledSingleArrowLeft
                isdisabled={currentButton === 1 ? 1 : 0}
                onClick={() => setCurrentButton(prev => prev <= 1 ? prev : prev - 1)}
            />
            <Numbers>
                {
                    arrOfCurrButtons.map((number, index) => (
                        <Box
                            isActive={currentButton === number}
                            key={index}
                            onClick={(e) => handleClcik(e, number)}
                        >
                            {number}
                        </Box>
                    ))
                }
            </Numbers>
            <StyledSingleArrowRight
                isdisabled={currentButton === numberOfPages.length ? 1 : 0}
                onClick={() => setCurrentButton(prev => prev >= numberOfPages.length ? prev : prev + 1)}
            />
            <StyledDoubleArrowRight
                isdisabled={currentButton === numberOfPages.length ? 1 : 0}
                onClick={() => setCurrentButton(numberOfPages.length)}
            />
        </StyledPaginarion>
    )
}

export default Pagination

const StyledPaginarion = styled.div`
    display: flex;
    align-items: center;
    gap: 30px;
    @media only screen and (max-width: 520px) {
        transform: scale(0.75);
        gap: 15px;
    }
`

const Numbers = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    @media only screen and (max-width: 520px) {
        gap: 5px;
    }
`

const Box = styled.div`
    width: 50px;
    height: 50px;
    border: 1px solid ${props => props.theme.colors.primary};
    display: flex;
    align-items:center;
    justify-content: center;
    cursor: pointer;
    color: ${props => rgba(props.theme.colors.primary, 0.3)};
    background-color: ${props => props.theme.colors.white};
    color: ${props => props.isActive ? props.theme.colors.white : rgba(props.theme.colors.primary, 0.3)};
    background-color: ${props => props.isActive ? props.theme.colors.primary : props.theme.colors.white};
    transition: all 0.3s ease-in-out;
`

const StyledDoubleArrowLeft = styled(DoubleArrowLeft)`
    transition: all 0.3s ease-in-out;
    fill: ${props => props.isdisabled ? rgba(props.theme.colors.primary, 0.3) : props.theme.colors.primary};
    cursor: ${props => props.isdisabled ? 'initial' : 'pointer'};
`

const StyledSingleArrowLeft = styled(SingleArrowLeft)`
    transition: all 0.3s ease-in-out;
    fill: ${props => props.isdisabled ? rgba(props.theme.colors.primary, 0.3) : props.theme.colors.primary};
    cursor: ${props => props.isdisabled ? 'initial' : 'pointer'};
`

const StyledSingleArrowRight = styled(SingleArrowRight)`
    transition: all 0.3s ease-in-out;
    fill: ${props => props.isdisabled ? rgba(props.theme.colors.primary, 0.3) : props.theme.colors.primary};
    cursor: ${props => props.isdisabled ? 'initial' : 'pointer'};

`

const StyledDoubleArrowRight = styled(DoubleArrowRight)`
    transition: all 0.3s ease-in-out;
    fill: ${props => props.isdisabled ? rgba(props.theme.colors.primary, 0.3) : props.theme.colors.primary};
    cursor: ${props => props.isdisabled ? 'initial' : 'pointer'};

`