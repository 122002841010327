import React from 'react'
import styled from 'styled-components'
import Description from '../components/Description'

const TaskDescription2 = ({ data }) => {
    return (
        <StyledDescription
            dangerouslySetInnerHTML={{ __html: data }}
        />
    )
}

export default TaskDescription2


const StyledDescription = styled(Description)`
    text-align: left;
    font-size: 20px;
    @media only screen and (max-width: ${props => props.theme.mobile1}) {
        text-align: center;
    }
    p {
        margin-bottom: 20px;
        span {
            padding-right: 10px;
        }
        > p {
            margin-bottom: 0px;
        }
    }
    span, a {
        color: ${props => props.theme.colors.primary};
        font-weight: bold;
        text-decoration: none;
    }
    ul {
        list-style-type: initial;
        margin-left: 20px;
        @media only screen and (max-width: ${props => props.theme.mobile1}) {
            list-style-position: inside;
            margin-left: 0;
        }
    }
    ol {
        margin-left: 20px;
    }
`
