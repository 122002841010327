import React from 'react'
import { publicRoutes, privateRoutes } from './allRoutes';
import {
    BrowserRouter as Router,
    Route,
    Routes
} from "react-router-dom";
import PrivateRoute from './PrivateRoute';
import Layout from '../components/Layout';
import ScrollToTop from '../components/ScrollToTop';

const MyRoutes = () => {
    return (
        <Router>
            <ScrollToTop>


                <Layout>
                    <Routes>
                        {
                            publicRoutes.map((route, index) => (
                                <Route
                                    path={route.path}
                                    element={route.component}
                                    key={index}
                                    exact={true}
                                />
                            ))
                        }
                        <Route
                            element={<PrivateRoute />}
                        >
                            {
                                privateRoutes.map((route, index) => (
                                    <Route
                                        path={route.path}
                                        element={route.component}
                                        key={index}
                                        exact={true}
                                    />
                                ))
                            }
                        </Route>
                    </Routes>
                </Layout>
            </ScrollToTop>
        </Router>
    )
}

export default MyRoutes