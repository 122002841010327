import { rgba } from 'polished'
import React, { forwardRef } from 'react'
import styled from 'styled-components'
import arrowDown from '../assets/images/arrowDown.svg'
import arrowDownRed from '../assets/images/arrowDownRed.svg'

const RadioBox = forwardRef(({ status = null, text, ...props }, ref) => {

    return (
        <StyledRadionBox>
            <Input
                status={status}
                ref={ref}
                type={'radio'}
                {...props}
            />
            <Text status={status}>{text}</Text>
        </StyledRadionBox>
    )
})

export default RadioBox



const StyledRadionBox = styled.label`
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    gap: 15px;
    
`

const Text = styled.p`
    font-size: 20px;
    color: ${props => props.status && props.status === 'wrong'
        ? props.theme.colors.red : props.status === 'good'
            ? props.theme.colors.primary : props.status === 'disabled'
                ? rgba('#000', 0.3) : props.theme.colors.primary};
`


const Input = styled.input`
    appearance: none;
    min-width: 40px;
    min-height: 40px;
    border-radius: 50%;
    border: 1px solid ${props => props.status && props.status === 'wrong'
        ? props.theme.colors.red : props.status === 'good'
            ? props.theme.colors.primary : props.status === 'disabled'
                ? rgba('#000', 0.3) : props.theme.colors.primary};
    cursor: pointer;
    background-position: center;
    background-size: 60%;
    background-repeat: no-repeat;
    &:checked {
        background-image: url(${props => props.status && props.status === 'wrong' ? arrowDownRed : props.status === 'good' ? arrowDown : arrowDown});
    }  
`