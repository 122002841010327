import React from 'react'
import styled from 'styled-components'
import { rgba } from 'polished'

const Button = ({ isReverse = false, isIcon = true, icon, children, disabled, ...props }) => {

    return (
        <ButtonStyled isReverse={isReverse} disabled={disabled} className={`${disabled ? "disabled" : ""}`} {...props}>
            {children}
            {isIcon && icon}
        </ButtonStyled>
    )
}

export default Button

const ButtonStyled = styled.button`
    border-radius: 0;
    background-color: ${props => props.theme.colors.primary};
    border: none;
    outline: none;
    color: white;
    font-size: 24px;
    height: 50px;
    padding: 0 15px 3px;
    display: flex;
    flex-direction: ${props => props.isReverse ? 'row-reverse' : 'row'};
    gap: 5px;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
    &:not(.disabled):hover {
        background-color: ${props => rgba(props.theme.colors.yellowGreen, 1)};
    }
  &.disabled {
    opacity: 0.8;
    cursor: initial;
  }
    @media only screen and (max-width: ${props => props.theme.mobile}) {
        font-size: 20px;
        height: 45px;
    }
`