import * as React from "react"

const Graduade = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={54} height={54} {...props}>
        <g data-name="Group 2805">
            <path
                data-name="Path 280"
                d="M38.852 37.475a.96.96 0 0 0 .632-.9v-7.684a.96.96 0 0 0-1.92 0v7a29.1 29.1 0 0 1-19.206 0v-7a.96.96 0 1 0-1.92 0v7.682a.961.961 0 0 0 .632.9 31.07 31.07 0 0 0 21.783 0"
            />
            <path
                data-name="Path 281"
                d="M10.676 26.186v-2.641l15.625 5.757a4.8 4.8 0 0 0 3.32 0l16.918-6.233a.961.961 0 0 0 0-1.8l-16.918-6.236a4.817 4.817 0 0 0-3.32 0L9.383 21.267a.961.961 0 0 0-.628.9v4.018a2.879 2.879 0 0 0-1.92 2.7v1.92a.96.96 0 0 0 .96.96h3.841a.96.96 0 0 0 .96-.96v-1.92a2.879 2.879 0 0 0-1.92-2.7m16.289-9.35a2.886 2.886 0 0 1 1.992 0l14.472 5.331-14.472 5.333a2.893 2.893 0 0 1-1.992 0l-14.472-5.332ZM10.676 29.852h-1.92v-.96a.96.96 0 1 1 1.92 0Z"
            />
            <path
                data-name="Ellipse 9"
                d="M27 2a25.007 25.007 0 0 0-9.731 48.036A25.007 25.007 0 0 0 36.731 3.964 24.842 24.842 0 0 0 27 2m0-2A27 27 0 1 1 0 27 27 27 0 0 1 27 0Z"
            />
        </g>
    </svg>
)

export default Graduade
