import React, { useMemo } from 'react'
import Background from '../../components/Background'
import BigTitle from '../../components/BigTitle'
import Container from '../../components/Container'
import FlexBox from '../../components/FlexBox'
import Title from '../../components/Title'
import exam from '../../assets/images/exam.jpg'
import Image from '../../components/Image'
import styled from 'styled-components'
import NewTitle from '../../components/NewTitle'
import ExamCard from '../../components/ExamCard'
import Stages from "../../components/Stages";
import { useQuery } from '@tanstack/react-query'
import examService from '../../services/examService'
import Spiner from '../../components/Spiner'
import useMediaQuery from '../../Hooks/MediaQueryHook'


const TeacherExam = () => {
    const isMobile = useMediaQuery(`(max-width: 1200px`)

    const {
        data: dataLecturerExams,
        isLoading: isLoadingLecturerExams,
        isSuccess: isSuccessLecturerExams
    } = useQuery(
        ['lecturerExams'],
        examService.getAllLecturerExams()
    )


    // remove universityChairLevels without exams
    const checkChair = (chair) => {
        const newObj = {}
        for (const key in chair) {
            if (key === 'universityChairLevels') {
                const filteredChairLevel = chair[key].filter(chairLevel => chairLevel.exams.length > 0)
                newObj['universityChairLevels'] = filteredChairLevel
            }
            else {
                newObj[key] = chair[key]
            }
        }
        return newObj
    }


    // catedrals with exams only
    const filteredExams = useMemo(() => {
        if (dataLecturerExams) {
            return dataLecturerExams.universityChairs.map(chair => checkChair(chair)).filter(chair => chair.universityChairLevels.length > 0)
        }
        else return []
    }, [dataLecturerExams])




    return (
        <>
            <Background bgColor={'primary'}>
                <Container>
                    <BigTitle color='yellow2'>Egzaminy</BigTitle>
                    <Title color='white'>Sprawdź wyniki swoich studentów</Title>
                </Container>
            </Background>
            <Background bgColor='aquamarine' style={{ paddingBottom: '20px' }}>
                <Container maxWidth='1460px'>
                    <StyledFlexBox2 gap={'50px'} alignItems='flex-start' justifyContnet='center' margin='0 0 38px 0'>
                        <Desc>
                            {
                                isMobile ? (
                                    <>
                                        <p>
                                            Poniżej znajdziesz wypełnione egzaminy swoich studentów do oceny.
                                            O możliwości sprawdzenia egzaminów danej grupy, poinformujemy Cię każdorazowo drogą mailową. Poza oceną, możesz udzielić feedbacku,
                                            a Twoi studenci będą mieli możliwość skontaktowania się z Tobą w celu omówienia wyników.<br /><br />

                                        </p>
                                        <FlexBox margin='20px 0 0 0'>Miłego sprawdzania!</FlexBox>
                                    </>
                                ) : (
                                    <>
                                        <p>Poniżej znajdziesz wypełnione egzaminy swoich studentów do oceny.</p>
                                        <p>O możliwości sprawdzenia egzaminów danej grupy, poinformujemy Cię</p>
                                        <p>każdorazowo drogą mailową. Poza oceną, możesz udzielić feedbacku,</p>
                                        <p>a Twoi studenci będą mieli możliwość skontaktowania się z Tobą</p>
                                        <p>w celu omówienia wyników.</p>
                                        <FlexBox margin='20px 0 0 0'>Miłego sprawdzania!</FlexBox>
                                    </>
                                )
                            }
                        </Desc>
                        <Image src={exam} alt='exam' />
                    </StyledFlexBox2>
                </Container>
                {
                    isLoadingLecturerExams && <Spiner />
                }
                {
                    isSuccessLecturerExams && (
                        <Container maxWidth='1460px'>
                            {
                                filteredExams.map(({ name, universityChairLevels }, index) => (
                                    <div key={index}>
                                        <NewTitle style={{ marginBottom: '18px' }} variant='h3' key={index}>{name}</NewTitle>
                                        {
                                            universityChairLevels.map(({ level, exams }, index) => (
                                                <div key={index}>
                                                    <NewTitle variant='h5' color='gray2'>{level.name}</NewTitle>
                                                    <FlexBox flexWrap={'wrap'} gap={'20px'} margin={'40px 0px'}>
                                                        {
                                                            exams.map((exam, index) => (
                                                                <StyledFlexBox key={index} >
                                                                    <ExamCard data={exam} />
                                                                </StyledFlexBox>
                                                            ))
                                                        }
                                                    </FlexBox>
                                                </div>
                                            ))
                                        }
                                    </div>
                                ))
                            }
                        </Container>
                    )
                }
            </Background>
            <StyledStages noBackground />
        </>

    )
}

export default TeacherExam

const Desc = styled.div`
  font-size: 20px;
  line-height: 32px;
  color: ${props => props.theme.colors.balck};
`

const StyledStages = styled(Stages)`
    padding-top: 40px;
    padding-bottom: 40px;
`

const StyledFlexBox = styled(FlexBox)`
    width: calc(50% - 10px);
    @media only screen and (max-width: ${props => props.theme.mobile2}) {
        width: calc(100% - 10px);
    }
`

const StyledFlexBox2 = styled(FlexBox)`
    @media only screen and (max-width: ${props => props.theme.mobile2}) {
        flex-direction: column;
        align-items: center;
    }
`