import React from 'react'
import styled from "styled-components";


const ProgressBar = ({type, current, max, title}) => {

    const currentValue = () => {
        if(type === 'scale') {
           return parseInt(current / max * 100);
        } else if(type === 'percent') {
            return parseInt(current);
        } else {
            console.error('ProgressBar - Wrong type passed')
        }
    }

    return (
        <ProgressBarWrapper>
            <div className={'current-points'}>{current}{type === 'percent' && '%'}</div>
            <ProgressIndicator value={currentValue()}>
                <div className="scale-value" />
            </ProgressIndicator>
            <div className={'title'}>
                {title}
            </div>
        </ProgressBarWrapper>
    )}

const ProgressBarWrapper = styled.div`
   width: 100%;
  margin-bottom: 15px;
  .current-points {
    text-align: right;
    font-family: Merriweather, sans-serif;
    font-size: 24px;
    color: ${props => props.theme.colors.primary};
    line-height: 28px;
    font-weight: 700;
  }
  .title {
    text-align: right;
    font-size: 14px;
    font-family: Lato, sans-serif;
    color: black;
    line-height: 16px;
    margin-top: 4px;
  }
`;

const ProgressIndicator = styled.div`
    margin-top: 5px;
    width: 100%;
    height: 10px;
    background-color: rgb(183,216, 219);
    position: relative;
    .scale-value {
      position: absolute;
      background-color: ${props => props.theme.colors.primary};
      width: ${props => props.value + `%`};
      height: 10px;
  }
`;

export default ProgressBar