const menuData = [
    {
        label: 'O Uniwersytecie',
        link: '/about',
        collapse: [
            {
                label: 'Katedry',
                link: '/about/chairs',
                disabled: false
            },
            {
                label: 'Nasi wykładowcy',
                link: '/about/teachers',
                disabled: false
            },
            {
                label: 'Dla dociekliwych',
                link: '/for-inquisitive',
                disabled: false
            }
        ]
    },
    {
        label: 'Aktualności',
        link: '/news/',
        disabled: false
    },
    {
        label: 'Egzaminy',
        link: '/my-studies/lecturer-exams',
        disabled: false
    },
]

export default menuData