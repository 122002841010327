import React from 'react'
import styled from 'styled-components'
import Button from './Button'
import Container from './Container'
import Link from './Link'
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'


const NewRow = ({ data, bg, link }) => {

    const { publicationDateText, title, abstract, image } = data
    return (
        <StyledNewRow bg={bg}>
            <StyledContainer disablePaddingTop>
                <Wrapper>
                    <div>
                        <Date>{publicationDateText}</Date>
                        <Title>{title}</Title>
                        <Desc>{abstract}</Desc>
                        <StyledLink to={link}>
                            <Button>Czytaj więcej</Button>
                        </StyledLink>
                    </div>
                    <Zoom>
                        <Image src={image} alt='news image' />
                    </Zoom>
                </Wrapper>
            </StyledContainer>
        </StyledNewRow>
    )
}

export default NewRow


const Date = styled.div`
    font-size: 20px;
    margin-bottom: 15px;
`

const Title = styled.div`
    font-size: 24px;
    font-weight: bold;
    line-height: 38px;
    color: ${props => props.theme.colors.primary};
    margin-bottom: 15px;
`

const Desc = styled.div`
    margin-left: 20px;
    font-size: 20px;
    line-height: 32px;
    margin-bottom: 30px;
`

const StyledLink = styled(Link)`
    display: inline-block;
`

const Image = styled.img`
    width: 400px;
    
    @media only screen and (max-width: 800px) {
        max-width: 400px;
        width: 100%;
    }
`

const Wrapper = styled.div`
    max-width: 1170px;
    display: flex;
    align-items: center;
    gap: 40px;
    margin: auto;
    @media only screen and (max-width: 800px) {
        flex-direction: column;
    }
`

const StyledContainer = styled(Container)`
    min-height: 450px;
    padding-top: 54px;
    padding-bottom: 54px;
    display: flex;
`

const StyledNewRow = styled.div`
    background-image: url(${props => props.bg});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: left;
    [data-rmiz-modal-overlay="visible"] {
        background-color: rgba(255, 255, 255, 0.6);
      }
`

