import React from 'react'
import styled from 'styled-components'

const Container = ({ maxWidth = '1900px', disablePaddingTop, children, ...props }) => {


    return (
        <StyledContainer maxWidth={maxWidth} disablePaddingTop={disablePaddingTop} {...props}>
            {children}
        </StyledContainer>
    )
}

export default Container

const StyledContainer = styled.div`
    max-width: ${props => props.maxWidth};
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding: ${props => props.disablePaddingTop ? '0px 20px' : '20px'};
    @media only screen and (max-width: ${props => props.theme.mobile}) {
        padding: ${props => props.disablePaddingTop ? '0px 12px' : '12px'};
        width: 100%;
    }
`