import React from 'react'

const ArrowRight2 = (props) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={16.719}
            height={22.902}
            {...props}
        >
            <g data-name="Group 2697">
                <path
                    data-name="Rectangle 1080"
                    d="m11.451 6.184 5.267 5.267-5.267 5.267-5.267-5.267z"
                />
                <path
                    data-name="Rectangle 1083"
                    d="m5.268 12.367 5.267 5.267-5.267 5.267-5.267-5.267z"
                />
                <path
                    data-name="Rectangle 1081"
                    d="m5.268 0 5.267 5.267-5.267 5.267L.001 5.267z"
                />
            </g>
        </svg>
    )
}

export default ArrowRight2