import React, { useEffect, useRef, useState } from 'react'
import styled from "styled-components";
import uploadAvatar from "../assets/images/upload_avatar.svg"
import ResetedButton from "./ResetedButton";
import userService from '../services/userService'
import { useMutation } from '@tanstack/react-query';
import { fileToBase64 } from '../helpers/helpers';
import Toast from "../components/Toast";
import { AiOutlinePlusCircle } from 'react-icons/ai'
import useClickOutside from '../Hooks/useClickOutside';



const Avatar = ({ name, lastName, image }) => {

  const wrapperRef = useRef(null);
  const isOutside = useClickOutside(wrapperRef)
  const [isAddPhoto, setIsAddPhoto] = useState(false)

  useEffect(() => {
    isOutside && setIsAddPhoto(false)
  }, [isOutside])

  const { mutate: updateMe } = useMutation(
    userService.updateMe(),
    {
      onSuccess: () => {
        window.location.reload()
        Toast({
          type: 'success',
          title: 'Avatar został zaktualizowany',
        });
      }
    }
  )
  // eslint-disable-next-line
  const [selectedFile, setSelectedFile] = useState();


  // eslint-disable-next-line
  const [isFilePicked, setIsFilePicked] = useState(false);
  const inputRef = useRef(null);

  //use only to avoid building crashes
  // console.log(selectedFile, isFilePicked)

  const changeHandler = async (event) => {
    setSelectedFile(event.target.files[0]);
    setIsFilePicked(true);
    const output = { base64: event.target.files[0] ? await fileToBase64(event.target.files[0]) : '', type: event.target.files[0].type }
    updateMe({ avatarFile: output })

  };

  const openInputBox = () => {
    inputRef.current.click();
  }

  const handleChangePhoto = () => {
    setIsAddPhoto(true)
  }

  return (
    <AvatarWrapper>
      <div className={'user-data'}>
        <input type="file" name="file" ref={inputRef} onChange={changeHandler} style={{ display: 'none' }} />
        {/* <ResetedButton onClick={openInputBox}> */}
        <StyledResetedButton>
          <img src={image ? image : uploadAvatar} alt={'avatar'} />
          <ChangePhotoBox onClick={handleChangePhoto}>
            zmień zdjęcie
          </ChangePhotoBox>
          <AddPhotoBox ref={isAddPhoto ? wrapperRef : null} isAddPhoto={isAddPhoto} onClick={openInputBox}>
            dodaj zdjęcie
            <StyledAiOutlinePlusCircle />
          </AddPhotoBox>
        </StyledResetedButton>
        <div className="user-data--name">
          <span>{name}</span>
          <span>{lastName}</span>
        </div>
      </div>

    </AvatarWrapper>
  )
}

const ChangePhotoBox = styled.div`
  display: none;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%, -30px);
  background-color: ${props => props.theme.colors.white};
  width: 100%;
  padding: 5px;
  border: 1px solid ${props => props.theme.colors.primary};
  cursor: pointer;
  z-index: 100;
  &:hover {
    background-color: ${props => props.theme.colors.lightGray};
    font-weight: bold;
  }
`

const AddPhotoBox = styled.div`
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%, -30px);
  background-color: ${props => props.theme.colors.white};
  width: 100%;
  padding: 5px;
  border: 1px solid ${props => props.theme.colors.primary};
  cursor: pointer;
  display: ${props => props.isAddPhoto ? 'flex' : 'none'};
  flex-direction: column;
  align-items: center;
  z-index: 100;
`

const StyledAiOutlinePlusCircle = styled(AiOutlinePlusCircle)`
  font-size: 60px;
  color: ${props => props.theme.colors.gray};
`

const StyledResetedButton = styled(ResetedButton)`
  &:hover {
    ${ChangePhotoBox} {
      display: block;
    }
  }
`

const AvatarWrapper = styled.div`
    position: relative;
    width: 100%;
    .user-data {
      display: flex;
      align-items: center;
      img {
        width: 123px;
        height: 123px;
        border-radius: 50%;
        object-fit: cover;
      }
      &--name {
        margin-left: 23px;
        span {
          display: block;
          color: ${props => props.theme.colors.primary};
          font-size: 24px;
          font-family: Lato, sans-serif;
          line-height: 28px;
        }
      }
    }
`;




export default Avatar