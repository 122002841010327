import * as React from "react"

const SvgComponent = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={54} height={54} {...props}>
        <g data-name="Group 2804">
            <g data-name="Group 2706">
                <g
                    data-name="Group 89"
                    clipPath="url(#a)"
                    transform="translate(13.121 13.371)"
                >
                    <path
                        data-name="Path 282"
                        d="M26.51 8.5C24.662 3.511 21.335 0 18.378 0a4.682 4.682 0 0 0-1.2.185 3.76 3.76 0 0 0-2.033 1.756 36.3 36.3 0 0 1-13.307 9.148c-1.848.647-2.4 3.6-1.2 6.838C1.653 20.7 3.5 22.455 5.164 22.455a1.573 1.573 0 0 0 .647-.092 23.186 23.186 0 0 1 3.049-.554l1.848 3.881a.946.946 0 0 0 .554.462l2.957 1.016c.092 0 .185.092.277.092a1.313 1.313 0 0 0 .554-.185 1 1 0 0 0 .37-.832l-.647-5.267a50.1 50.1 0 0 1 7.485-.092h.185a4.638 4.638 0 0 0 1.109.185 4.682 4.682 0 0 0 1.2-.185c1.571-.554 2.68-2.31 2.957-4.805A17.546 17.546 0 0 0 26.51 8.5ZM12.187 24.487 10.8 21.438l2.125-.277.462 3.7Zm6.653-8.871a19.644 19.644 0 0 1-1.848-3.7 16.407 16.407 0 0 1-.924-3.973 2.24 2.24 0 0 1 1.016.554 7.392 7.392 0 0 1 1.663 2.772c.739 2.125.554 3.789.092 4.343m-13.49 4.994h-.092c-.647.185-2.033-1.016-2.957-3.326-.832-2.31-.462-4.251.092-4.435a38.617 38.617 0 0 0 11.736-7.3 19.5 19.5 0 0 0 1.109 7.023 18.368 18.368 0 0 0 3.973 6.468 58.35 58.35 0 0 0-13.861 1.57Zm20.422-4.713c-.185 1.756-.832 2.865-1.756 3.234a1.172 1.172 0 0 1-.554.092c-1.016 0-2.218-.739-3.511-2.218 1.294-1.109 1.571-3.6.554-6.376a10.291 10.291 0 0 0-2.125-3.419 3.408 3.408 0 0 0-2.4-1.109 2.849 2.849 0 0 1 .092-.924c.185-1.756.832-2.865 1.756-3.234a1.172 1.172 0 0 1 .554-.092c1.848 0 4.713 2.772 6.376 7.3a13.859 13.859 0 0 1 1.014 6.747Z"

                    />
                </g>
            </g>
            <path
                data-name="Ellipse 72"
                d="M27 2a25.007 25.007 0 0 0-9.731 48.036A25.007 25.007 0 0 0 36.731 3.964 24.842 24.842 0 0 0 27 2m0-2A27 27 0 1 1 0 27 27 27 0 0 1 27 0Z"

            />
        </g>
    </svg>
)

export default SvgComponent
