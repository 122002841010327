import React from 'react'
import styled from 'styled-components'
import BigTitle from '../components/BigTitle'
import Button from '../components/Button'
import Container from '../components/Container'
import Title from '../components/Title'
import plug from '../assets/images/plug.png'
import Stages from '../components/Stages'
import Link from '../components/Link'

const NotFound = () => {
    return (
        <NotFoundStyled>
            <Container>
                <BigTitle>404</BigTitle>
                <Title>Ups! Zabłądziłeś?</Title>
                <Desc>Zajmij się nauką zamiast łazić po tej stronie</Desc>
            </Container>
            <Plug src={plug} alt='plug' />
            <Container>
                <Row>
                    <Link to='/my-studies'>
                        <Button>Wróć do nauki</Button>
                    </Link>
                </Row>
            </Container>
            <Stages noBackground />
        </NotFoundStyled>
    )
}

export default NotFound

const NotFoundStyled = styled.div`
    padding-bottom: 40px;
`

const Desc = styled.p`
    font-size: 24px;
    font-weight: bold;
    line-height: 38px;
    text-align: center;
    color: ${props => props.theme.colors.primary};
`

const Plug = styled.img`
    display: flex;
    width: 100%;
`
const Row = styled.div`
    display: flex;
    justify-content: center;
    margin: 40px 0;
`