import './App.css';
import { ThemeProvider } from 'styled-components';
import theme from './theme';
import MyRoutes from './Routes/MyRoutes';
import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
import { StoreProvider } from 'easy-peasy';
import store from './store'
import ReactModal from 'react-modal';

ReactModal.setAppElement('#root')
function App() {
  const queryClient = new QueryClient()
  return (
    <StoreProvider store={store}>
      <QueryClientProvider client={queryClient} >
        <ThemeProvider theme={theme}>
          <div className="App">
            <MyRoutes />
          </div>
        </ThemeProvider>
      </QueryClientProvider>
    </StoreProvider>
  );
}

export default App;
