import React from 'react'

const Audio = (props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={44} height={44} {...props}>
            <path
                data-name="play_circle_FILL0_wght400_GRAD0_opsz48"
                d="M16.665 31.35 31.35 22l-14.685-9.35ZM22 44a21.287 21.287 0 0 1-8.525-1.733A22.165 22.165 0 0 1 1.732 30.525a21.976 21.976 0 0 1 0-17.1 21.986 21.986 0 0 1 4.73-6.985 22.552 22.552 0 0 1 7.012-4.7 21.976 21.976 0 0 1 17.1 0 21.966 21.966 0 0 1 11.693 11.68 21.976 21.976 0 0 1 0 17.1 22.552 22.552 0 0 1-4.7 7.013 21.986 21.986 0 0 1-6.985 4.73A21.423 21.423 0 0 1 22 44Zm0-3.3a17.994 17.994 0 0 0 13.255-5.472A18.067 18.067 0 0 0 40.7 22a18.04 18.04 0 0 0-5.445-13.255A18.04 18.04 0 0 0 22 3.3 18.067 18.067 0 0 0 8.772 8.745 17.994 17.994 0 0 0 3.3 22a18.021 18.021 0 0 0 5.473 13.227A18.021 18.021 0 0 0 22 40.7ZM22 22Z"
            />
        </svg>
    )
}

export default Audio