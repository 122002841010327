import { useEffect, useState } from 'react'

const useClickOutside = (ref) => {
    const [isOutside, setIsOutSide] = useState(null)

    useEffect(() => {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                setIsOutSide(true)
            }
            else {
                setIsOutSide(false)
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);
    return isOutside

}

export default useClickOutside