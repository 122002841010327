import React from 'react'
import styled from 'styled-components'

const Hamburger = ({ isOpen, ...props }) => {

    return (
        <StyledHamburger {...props}>
            <Wrapper >
                <Line1 isOpen={isOpen} />
                <Line2 isOpen={isOpen} />
                <Line3 isOpen={isOpen} />
                <Line4 isOpen={isOpen} />
            </Wrapper>
        </StyledHamburger>
    )
}

export default Hamburger

const StyledHamburger = styled.button`
    border-radius: 0;
    outline: none;
    padding: 5px;
    border: none;
    cursor: pointer;
    background-color: transparent;
`

const Wrapper = styled.div`
    width: 49px;
    height: 40px;
    position: relative;
    overflow: hidden;
`

const Line1 = styled.div`
    background-color: ${props => props.theme.colors.lightGray};
    position: absolute;
    top:0;
    left: 0;
    height: 4px;
    transition: all 0.3s ease-in-out;
    width: 50%;
    transform: ${props => props.isOpen ? 'translateX(-100%)' : 'translateX(0%)'};
    opacity: ${props => props.isOpen ? '0' : '1'};
`
const Line2 = styled.div`
    background-color: ${props => props.theme.colors.lightGray};
    position: absolute;
    top: 12px;
    left: 0;
    width: 100%;
    height: 4px;
    transition: all 0.3s ease-in-out;
    transform: ${props => props.isOpen ? 'translateY(6px) rotate(45deg)' : 'translateY(0)'};
`
const Line3 = styled.div`
    background-color: ${props => props.theme.colors.lightGray};
    position: absolute;
    top: 24px;
    left: 0;
    width: 100%;
    height: 4px;
    transition: all 0.3s ease-in-out;
    transform: ${props => props.isOpen ? 'translateY(-6px) rotate(-45deg)' : 'translateY(0)'};
`

const Line4 = styled.div`
    background-color: ${props => props.theme.colors.lightGray};
    position: absolute;
    top: 36px;
    left: 0;
    width: 50%;
    height: 4px;
    transition: all 0.3s ease-in-out;
    transform: ${props => props.isOpen ? 'translateX(100%)' : 'translateX(0%)'};
    opacity: ${props => props.isOpen ? '0' : '1'};
`