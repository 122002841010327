import React from 'react'
import styled from 'styled-components'
import useMediaQuery from '../Hooks/MediaQueryHook'
import Link from './Link'
import { rgba } from 'polished'



const Card = ({ data, ...props }) => {

    const { title, desc1, desc2, desc3, image, imageAlt, link1, link2, link1Text, link2Text } = data
    const isMobile = useMediaQuery(`(max-width: 535px`)

    return (
        <StyledCard {...props}>
            <Title>{title}</Title>
            {
                isMobile ? (
                    <>
                        <SpanDescription>{desc1}</SpanDescription>
                        <SpanDescription>{desc2}</SpanDescription>
                        <SpanDescription>{desc3}</SpanDescription>
                    </>
                ) : (
                    <>
                        <Description>{desc1}</Description>
                        <Description>{desc2}</Description>
                        <Description>{desc3}</Description>
                    </>
                )
            }
            <Image src={image} alt={imageAlt} />
            <Box>
                <StyledLink to={link1}>
                    {link1Text}
                </StyledLink>

                {
                    link2Text && (
                        <StyledLink to={link2}>
                            {link2Text}
                        </StyledLink>
                    )
                }
            </Box>
        </StyledCard>
    )
}

export default Card

const StyledCard = styled.div`
    width: 535px;
    text-align: center;
    padding: 0px 20px;
`

const Image = styled.img`
    display: block;
    max-width: 320px;
    width: 100%;
    height: auto;
    margin-bottom: 30px;
    margin-right: auto;
    margin-left: auto;
    margin-top: 20px;
    @media only screen and (max-width: 400px) {
        margin-bottom: 20px;
    }
`

const Title = styled.h2`
    font-size: 48px;
    line-height: 60px;
    margin-bottom: 22px;
    color: ${props => props.theme.colors.yellowGreen};
    white-space: nowrap;
    font-weight: 500;
    @media only screen and (max-width: ${props => props.theme.mobile}) {
        font-size: 28px;
        line-height: 38px;
        margin-bottom: 14px;
        white-space: initial;
    }
`
const Box = styled.div`
    display: flex;
    justify-content: center;
    gap: 44px;
    @media only screen and (max-width: 400px) {
        gap: 10px;
        button {
            padding: 0;
            flex: 1;
        }
    }
`

const Description = styled.div`
    font-size: 20px;
    line-height: 28px;
`

const SpanDescription = styled.span`
    font-size: 20px;
    line-height: 28px;
`

const StyledLink = styled(Link)`
    border-radius: 0;
    background-color: ${props => props.theme.colors.primary};
    border: none;
    outline: none;
    color: white;
    font-size: 24px;
    height: 50px;
    padding: 0 15px 3px;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
    &:not(.disabled):hover {
        background-color: ${props => rgba(props.theme.colors.yellowGreen, 1)};
    }
  &.disabled {
    opacity: 0.8;
    cursor: initial;
  }
    @media only screen and (max-width: ${props => props.theme.mobile}) {
        font-size: 20px;
        height: 45px;
    }
`
