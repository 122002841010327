import React, { useState } from 'react'
import Container from '../components/Container'
import BigTitle from '../components/BigTitle'
import Title from '../components/Title'
import Collapse from '../components/Collapse'
import styled from 'styled-components'
import ArrowRight2 from '../components/Icons/ArrowRight2'
import Stages from '../components/Stages'
import Slider from '../components/Slider'
import { slide1Data } from "../FakeData/SliderData";
import Card from '../components/Card'

// const faqData = [
//     {
//         text: 'Nie mogę  się zalogować lub nie pamiętam hasła – co zrobić?',
//         desc: 'Zgłoś problem w formularzu kontaktowym w zakładce „Kontakt”. Zespół support dośle Ci Twoje dotychczasowe hasło lub wygeneruje nowe.'
//     },
//     {
//         text: 'Ile czasu mam na wykonanie zadania?',
//         desc: 'Termin wykonania zadania znajdziesz w nad opisem treści zadania oraz w zakładce „Terminarz”.'
//     },
//     {
//         text: 'Dlaczego nie mogę rozpocząć quizu? ',
//         desc: 'Nie możesz rozpocząć quizu w dwóch przypadkach:
//         'Zadanie nie zostało przez Ciebie zakończone (wciśnij buton „Zakończ zadanie”). Quiz nie jest jeszcze dostępny (otrzymasz e-mail informujący o możliwości rozwiązania quizu).'
//     },
// ]




const Toggler = ({ data }) => {
    const [isOpen, setIsOpen] = useState()
    const handleTogglerClick = () => {
        setIsOpen(prev => !prev)
    }


    return (
        <StyledToggler onClick={handleTogglerClick}>
            <TogglerText>{data}</TogglerText>
            <div>
                <StyledArrowRight2 isOpen={isOpen} />
            </div>
        </StyledToggler>
    )
}



const FAQ = () => {


    return (
        <>
            <Container>
                <BigTitle>FAQ</BigTitle>
                <Title>Najczęściej zadawane pytania</Title>
                <Wrapper>
                    {/* {
                        faqData.map((question, index) => (
                            <Collapse key={index} toggler={<Toggler data={question} />}>
                                <Desc>
                                    {question.desc}
                                </Desc>
                            </Collapse>
                        ))
                    } */}
                    <Collapse toggler={<Toggler data={'Nie mogę  się zalogować lub nie pamiętam hasła – co zrobić?'} />}>
                        <Desc>
                            Zgłoś problem w formularzu kontaktowym w zakładce „Kontakt”. Zespół support dośle Ci Twoje dotychczasowe hasło lub wygeneruje nowe.
                        </Desc>
                    </Collapse>
                    <Collapse toggler={<Toggler data={'Ile czasu mam na wykonanie zadania?'} />}>
                        <Desc>
                            Termin wykonania zadania znajdziesz w nad opisem treści zadania oraz w zakładce „Terminarz”.
                        </Desc>
                    </Collapse>
                    <Collapse toggler={<Toggler data={'Dlaczego nie mogę rozpocząć quizu? '} />}>
                        <Desc>
                            Nie możesz rozpocząć quizu w dwóch przypadkach:
                            <List>
                                <ListItem>Zadanie nie zostało przez Ciebie zakończone (wciśnij buton „Zakończ zadanie”).</ListItem>
                                <ListItem>Quiz nie jest jeszcze dostępny (otrzymasz e-mail informujący o możliwości rozwiązania quizu).</ListItem>
                            </List>
                        </Desc>
                    </Collapse>


                </Wrapper>
            </Container>
            <StyledStages noBackground />
            <Slider
                data={slide1Data}
                component={<Card />}
                breakpoints={{
                    1400: {
                        slidesPerView: 3
                    },
                    900: {
                        slidesPerView: 2
                    },
                }}
                isCenter={true}
                bgColor={'lightGray'}
            />
        </>
    )
}

export default FAQ

const Wrapper = styled.div`
    max-width: 880px;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    gap: 40px;
`

const StyledToggler = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    gap: 10px;
    border-bottom: 1px solid ${props => props.theme.colors.primary};
    padding-bottom: 20px;
`

const TogglerText = styled.div`
    color: ${props => props.theme.colors.primary};
    font-size: 24px;
    cursor: pointer;
    
`

const StyledArrowRight2 = styled(ArrowRight2)`
    fill: ${props => props.theme.colors.primary};
    transform: translateY(${props => props.isOpen ? '20px' : '0px'}) rotate(${props => props.isOpen ? '90deg' : '0deg'}) ;
    transition: all 0.3s ease-in-out;
`

const Desc = styled.div`
    padding: 10px;
    font-size: 20px;
`

const StyledStages = styled(Stages)`
    padding: 40px 0px
`

const List = styled.ol`
    list-style-type: auto;
    padding-left: 20px;
    margin-top: 20px;
`
const ListItem = styled.li`
    
`