import React, { useState, useRef } from 'react'
import { BsPlayCircle, BsPauseCircle } from 'react-icons/bs'
import styled, { css } from 'styled-components'
import Logo from './Logo';

const AudioPlayer = ({ url, ...props }) => {
    // state
    const [isPlaying, setIsPlaying] = useState(false);
    const [duration, setDuration] = useState(0);
    const [currentTime, setCurrentTime] = useState(0);
    const [progress, setProgress] = useState('0%')

    // references
    const audioPlayer = useRef();   // reference our audio component
    const progressBar = useRef();   // reference our progress bar
    const animationRef = useRef();  // reference the animation


    const handleOnLoadedMetadata = () => {
        const seconds = Math.floor(audioPlayer.current.duration);
        progressBar.current.max = seconds;
        setDuration(seconds);
    }

    const calculateTime = (secs) => {
        const minutes = Math.floor(secs / 60);
        const returnedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
        const seconds = Math.floor(secs % 60);
        const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
        return `${returnedMinutes}:${returnedSeconds}`;
    }

    const togglePlayPause = (e) => {
        e.stopPropagation()
        const prevValue = isPlaying;
        setIsPlaying(!prevValue);
        if (!prevValue) {
            audioPlayer.current.play();
            animationRef.current = requestAnimationFrame(whilePlaying)
        } else {
            audioPlayer.current.pause();
            cancelAnimationFrame(animationRef.current);
        }
    }

    const whilePlaying = () => {
        progressBar.current.value = audioPlayer.current.currentTime;
        changePlayerCurrentTime();
        animationRef.current = requestAnimationFrame(whilePlaying);
    }

    const changeRange = () => {
        audioPlayer.current.currentTime = progressBar.current.value;
        changePlayerCurrentTime();
    }

    const changePlayerCurrentTime = () => {
        setCurrentTime(progressBar.current.value);
        setProgress(`${progressBar.current.value / duration * 100}%`)
    }



    return (
        <>
            <StyledAudio {...props}>
                <audio controls ref={audioPlayer} onLoadedMetadata={handleOnLoadedMetadata}>
                    <source src={url} />
                </audio>
                <div>
                    {
                        isPlaying ? <StyledPause onClick={togglePlayPause} /> : <StyledPlay onClick={togglePlayPause} />
                    }
                </div>
                <RangeWrapper>
                    <Range className='progressBar' progress={progress} ref={progressBar} type={'range'} defaultValue={0} onChange={changeRange} />
                    <Timers>
                        <CurrentTime>{calculateTime(currentTime)}</CurrentTime>
                        <Duration>{duration && calculateTime(duration)}</Duration>
                    </Timers>
                </RangeWrapper>
                <div>
                    <StyledLogo color='#BBA907' width={'100px'} />
                </div>
            </StyledAudio>
        </>
    )
}

export default AudioPlayer


const StyledAudio = styled.div`
    margin: auto;
    display: flex;
    gap: 20px;
    align-items: center;
    // padding: 20px;
    width: 100%;
    background-color: ${props => props.theme.colors.lightGray};
    audio {
        display: none;
    }
`

const icon = css`
    cursor: pointer;
    font-size: 80px;
`


const StyledPause = styled(BsPauseCircle)`
    ${icon}
`

const StyledPlay = styled(BsPlayCircle)`
    ${icon}
`
const RangeWrapper = styled.div`
    width: 100%;
    
    padding: 13px 20px 0 20px;
`

const Timers = styled.div`
    display: flex;
    justify-content: space-between;
`

const CurrentTime = styled.div`
    margin-left: -20px;
`

const Duration = styled.div`
    margin-right: -20px;
`

const Range = styled.input`
    appearance: none;
    background-color: ${props => props.theme.colors.transparent};
    position: relative;
    width: 100%;
    height: 5px;
    border: 1px solid ${props => props.theme.colors.gray};
    outline: none;
    box-sizing: border-box;
    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        height: 3px;
        width: ${props => props.progress};
        background-color: blue;
    }
    &::-moz-range-progress {
        background-color: blue;
        height: 3px;
    }
    &::-webkit-slider-thumb {
        appearance: none;
        height: 10px;
        width: 10px;
        border-radius: 50%;
        background-color: blue;
        position: relative;
        z-index: 3;
        cursor: pointer;
    }
    &::-moz-range-thumb {
        appearance: none;
        height: 10px;
        width: 10px;
        border-radius: 50%;
        background-color: blue;
        position: relative;
        z-index: 3;
        border: transparent;
        cursor: pointer;
    }
`

const StyledLogo = styled(Logo)`
    background-color: ${props => props.theme.colors.darkGray};
    padding: 10px;
    display: block;
`
