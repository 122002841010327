import axios from './axios'
import { parseParams } from "../helpers/helpers"

const examService = {
    getAllExams: () => () => {
        return axios.get('/api/v1/exams').then(res => res.data)
    },
    getSingleExam: (id) => () => {
        return axios.get(`/api/v1/exams/${id}`).then(res => res.data)
    },
    getAllLecturerExams: () => () => {
        return axios.get('/api/v1/lecturer/exams').then(res => res.data)
    },
    initExam: (id) => () => {
        return axios.post(`/api/v1/exams/init/${id}`)
    },
    updateStudentAnswers: () => (data) => {
        return axios.put(`/api/v1/exams/answers`, data)
    },
    finishExamByStudent: (id) => (data) => {
        return axios.put(`/api/v1/exams/answers/finish/${id}`, data)
    },
    getAllStudentsByExamId: (id, params) => () => {
        return axios.get(`/api/v1/lecturer/exams/students/${id}${params ? parseParams(params) : ''}`).then(res => res.data)
    },
    getSpecificExam: (examId, studentId) => () => {
        return axios.get(`/api/v1/lecturer/exams/${examId}/${studentId}`).then(res => res.data)
    },
    assessExamByLecturerDraft: () => (data) => {
        return axios.put(`/api/v1/lecturer/exams/answers`, data).then(res => res.data)
    },
    finishExamByLecturer: (examId, studentId) => (data) => {
        return axios.put(`/api/v1/lecturer/exams/answers/finish/${examId}/${studentId}`, data).then(res => res.data)
    },



}

export default examService