import React, { useEffect } from 'react'
import Modal from './Modal'
import { useStoreActions, useStoreState } from 'easy-peasy'
import { useState } from 'react'
import NewTitle from './NewTitle'
import { minutesToMiliseconds } from '../helpers/helpers'
import FlexBox from './FlexBox'
import Button from './Button'
import Toast from "./Toast";
import Link from './Link'


const TokenExpModal = () => {
    const timeBeforeTokenExpInMinutes = 5
    const [isOpen, setIsOpen] = useState(false)
    const tokenExp = useStoreState(state => state.auth.exp)
    const logout = useStoreActions(actions => actions.auth.logoutUser)
    const renewToken = useStoreActions(actions => actions.auth.relogUser)

    const handleContinueSession = async () => {
        await renewToken()
        setIsOpen(false)
    }

    const handleClose = () => {
        setIsOpen(false)
        logout();
        Toast({
            type: 'success',
            title: 'Zostałeś prawidłowo wylogowany',
        });
    }

    useEffect(() => {
        const interval = setInterval(() => {
            if (tokenExp && Date.now() >= (tokenExp * 1000 - minutesToMiliseconds(timeBeforeTokenExpInMinutes))) {
                !isOpen && setIsOpen(true)
            }
        }, 5000)
        return () => clearInterval(interval)
    }, [isOpen, tokenExp])

    return (
        <Modal isOpen={isOpen} setIsOpen={setIsOpen} onRequestClose={false}>
            <NewTitle variant='h3'>Twoja sesja wygaśnie za {timeBeforeTokenExpInMinutes} minut.</NewTitle>
            <FlexBox justifyContnet={'space-between'}>
                <Button onClick={handleContinueSession}>Kontynuuj sesję</Button>
                <Link to='/login'>
                    <Button onClick={handleClose}>Wyloguj</Button>
                </Link>
            </FlexBox>
        </Modal>
    )
}

export default TokenExpModal