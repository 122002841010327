import axios from "./axios"

const newsService = {
    getAllNews: () => () => {
        return axios.get('/api/v1/news-list').then(res => res.data)
    },
    getSingleNew: (id) => () => {
        return axios.get(`/api/v1/news/${id}`).then(res => res.data)
    },
}

export default newsService