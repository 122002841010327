import React from 'react'

const GoogleDrive = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="43.35" height="38" viewBox="0 0 43.35 38">
            <path id="add_to_drive_FILL0_wght400_GRAD0_opsz48" d="M36.05,42V36h-6V32h6V26h4v6h6v4h-6v6Zm-24.6,1a3.76,3.76,0,0,1-2-.55A4.31,4.31,0,0,1,8,41L3.25,32.75a3.911,3.911,0,0,1,0-4L15.8,7a3.656,3.656,0,0,1,1.475-1.5A4.19,4.19,0,0,1,19.3,5h9.45a4.048,4.048,0,0,1,2,.5A3.7,3.7,0,0,1,32.2,7l8.9,15.45a8.083,8.083,0,0,0-1.525-.35A12.3,12.3,0,0,0,38,22h-.875a7.081,7.081,0,0,0-.875.05L28.7,9H19.3L6.7,30.75,11.45,39H27.1a14.864,14.864,0,0,0,1.275,2.175A8.941,8.941,0,0,0,30.1,43Zm3.05-9L13,31.45,22.2,15.5h3.6l5.05,8.75a14.467,14.467,0,0,0-1.65,1.4,7.948,7.948,0,0,0-1.25,1.65L24,20.45,18.5,30h8.25a8.135,8.135,0,0,0-.55,1.925A13.652,13.652,0,0,0,26.05,34Z" transform="translate(-2.7 -5)" fill="#fff" />
        </svg>
    )
}

export default GoogleDrive