import React, { forwardRef } from 'react'
import InputGroup from './InputGroup'
import Label from './Label'
import styled from 'styled-components'

const TextField = forwardRef(({ error, color = 'yellowGreen', borderColor = 'gray', type, label, name, onChange, ...props }, ref) => {
    return (
        <InputGroupStyled>
            {
                label && <Label label={label} />
            }
            <InputStyled
                color={color}
                borderColor={borderColor}
                name={name}
                ref={ref}
                onChange={onChange}

                {...props}
            />
            {
                error && <Error>{error}</Error>
            }
        </InputGroupStyled>
    )
})

export default TextField

const InputStyled = styled.input`
    width: 100%;
    height: 45px;
    padding: 0 40px 0 15px;
    border: 1px solid ${props => props.theme.colors[`${props.borderColor}`]};
    border-radius: 0;
    background-color: white;
    outline: none;
    color: ${props => props.theme.colors[`${props.color}`]};
    &::placeholder {
        color: ${props => props.theme.colors[`${props.color}`]};
   
    }
`

const InputGroupStyled = styled(InputGroup)`
    position: relative;
    width: 100%;
`

const Error = styled.small`
    color: red;
    margin-left: 15px;
`
