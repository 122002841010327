import React from 'react'

const SingleArrowLeft = ({ ...props }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={14.2} height={24.1} {...props}>
            <path
                d="m12.05 0 2.15 2.15-9.9 9.9 9.9 9.9-2.15 2.15L0 12.05Z"
            />
        </svg>
    )
}

export default SingleArrowLeft