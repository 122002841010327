import React, { useState, useRef } from 'react'
import styled from 'styled-components'
import Container from '../components/Container'
import BigTitle from '../components/BigTitle'
import Title from '../components/Title'
import Magnifier from '../components/Icons/Magnifier'
import TextField from '../components/TextField'
import { rgba } from 'polished'
import { debounce } from '../helpers/helpers'
import Stages from '../components/Stages'
import Slider from '../components/Slider'
import Card from '../components/Card'
import { slide1Data } from '../FakeData/SliderData'
import searchService from '../services/searchService'
import { useQuery } from '@tanstack/react-query'
import OutputSearchBox from '../components/OutputSearchBox'
import Spiner from '../components/Spiner'
import {GiCancel} from 'react-icons/gi'



const Search = () => {
    const [query, setQuery] = useState('')
    const inputRef = useRef(null)

    const {
        data: dataSearch,
        isSuccess: isSuccessSearch,
        isFetching: isFetchingDataSearch,
    } = useQuery(
        ['search', query],
        searchService.search(query),
        {
            enabled: !!query
        }
    )

    const handleClearInput = () => {
        setQuery('')
        inputRef.current.value = ''
    }

    return (
        <>
            <Container>
                <BigTitle>
                    Wyszukiwanie
                </BigTitle>
                <Title>
                    {
                        isSuccessSearch ? dataSearch.length > 0 ? 'Wyniki dla szukanej przez Ciebie frazy.' : 'Niestety nic nie znaleziono, spróbuj ponownie.' : 'Wyniki dla szukanej przez Ciebie frazy.'
                    }
                </Title>
            </Container>

            <SearchBar>
                <Container disablePaddingTop>
                    <Wrapper>
                        <StyledMagnifier />
                        <TextFieldWrapper>
                            <StyledTextField ref={inputRef} onChange={debounce(e => setQuery(e.target.value))} borderColor='transparent' color='black' />
                            {query &&
                            <IconWrapper onClick={handleClearInput}>
                                <StyledGiCancel/>
                            </IconWrapper>
                            }
                        </TextFieldWrapper>
                    </Wrapper>
                </Container>
            </SearchBar>
            <Container disablePaddingTop>
                <WrapperOutput>
                    {
                        isSuccessSearch && dataSearch.length > 0 && dataSearch.map((item, index) => <OutputSearchBox key={index} data={item} />)
                    }
                    {
                        isSuccessSearch && dataSearch.length === 0 && <NoResult>Spróbuj ponownie używając innych słów kluczowych.</NoResult>
                    }
                    {
                        isFetchingDataSearch && <Spiner />
                    }
                </WrapperOutput>
            </Container>


            <StyledStages noBackground />
            <Slider
                data={slide1Data}
                component={<Card />}
                breakpoints={{
                    1400: {
                        slidesPerView: 3
                    },
                    900: {
                        slidesPerView: 2
                    },
                }}
                isCenter={true}
                bgColor={'lightGray'}
            />
        </>
    )
}

export default Search

const SearchBar = styled.div`
    background-color: ${props => rgba(props.theme.colors.primary, 0.19)};
    padding: 38px 0px;
`

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    max-width: 840px;
    gap: 26px;
    margin: auto;
`

const StyledTextField = styled(TextField)`
    margin-bottom: -30px;
`

const StyledMagnifier = styled(Magnifier)`
    fill: ${props => props.theme.colors.primary};
`

const StyledStages = styled(Stages)`
    margin: 40px 0px;
`

const WrapperOutput = styled.div`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 26px;
    padding-top: 50px;
`

const NoResult = styled.div`
    font-size: 20px;
`

const TextFieldWrapper = styled.div`
    width: 100%;
    position: relative;
`

const IconWrapper = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    // background-color: red;
    height: 100%;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`

const StyledGiCancel = styled(GiCancel)`
    font-size: 20px;
    color: ${props => props.theme.colors.primary};
`



