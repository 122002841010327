import React from 'react'
import styled from 'styled-components'
import Container from '../components/Container'
import BigTitle from '../components/BigTitle'
import Title from '../components/Title'
import TextField from '../components/TextField'
import { useForm } from 'react-hook-form'
import TextArea from '../components/TextArea'
import Button from '../components/Button'
import Stages from '../components/Stages'
import Slider from '../components/Slider'
import { slide1Data } from '../FakeData/SliderData'
import Card from '../components/Card'
import contactService from '../services/contactService'
import { useMutation } from '@tanstack/react-query'
import Toast from "../components/Toast";
import InputFile from '../components/InputFile'
import { fileToBase64 } from '../helpers/helpers'
import Spiner from '../components/Spiner'

const Contact = () => {
    const { register, handleSubmit, setError, formState: { errors }, reset, watch } = useForm()




    const {
        mutate: sendMessage,
        isLoading: isLoadingSendMessage
    } = useMutation(
        contactService.send(),
        {
            onSuccess: () => {
                reset()
                Toast({
                    type: 'success',
                    title: 'Wiadomość wysłano pomyślnie',
                });
            },
            onError: (res) => {
                const errors = res.response.data
                for (const key in errors) {
                    setError(key, { type: 'custom', message: `${errors[key].message}` })
                }
                Toast({
                    type: 'error',
                    title: 'Ups... Coś poszło nie tak',
                });
            }
        }
    )

    const onSubmit = async (values) => {

        if (values.attachments.length > 0) {
            const file64 = { base64: await fileToBase64(values.attachments[0]), type: values.attachments[0].type }
            sendMessage({ ...values, attachments: [file64] })
        }
        else {
            sendMessage(values)
        }
    }

    return (
        <>
            <Container>
                <Wrapper>
                    <BigTitle>Kontakt</BigTitle>
                    <Title>Kto pyta, nie błądzi</Title>
                    <Desc>Masz pytania, sugestie, wątpliwości? </Desc>
                    <Desc>Potrzebujesz pomocy lub chcesz się z nami podzielić feedbackiem? </Desc>
                    <Desc>Napisz do nas! </Desc>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <TextField
                            color='primary'
                            borderColor='primary'
                            label='Imię i nazwisko'
                            placeholder='wypełnij'
                            {...register('names')}
                            error={errors?.names?.message}
                        />
                        <TextField
                            color='primary'
                            borderColor='primary'
                            label="Temat"
                            placeholder='wypełnij'
                            {...register('subject')}
                            error={errors?.subject?.message}
                        />
                        <TextArea
                            color='primary'
                            borderColor='primary'
                            label="Treść wiadomości"
                            placeholder='wpisz wiadomość'
                            {...register('text')}
                            error={errors?.text?.message}
                        />
                        <Box>
                            <InputFile
                                watch={watch}
                                {...register('attachments')}
                            />
                            <Button
                                disabled={isLoadingSendMessage}
                                icon={<Spiner />}
                                isIcon={isLoadingSendMessage}
                            >
                                {
                                    isLoadingSendMessage ? 'Wysyłanie...' : 'Wyślij'
                                }
                            </Button>
                        </Box>
                    </Form>
                </Wrapper>
            </Container>
            <StyledStages noBackground />
            <Slider
                data={slide1Data}
                component={<Card />}
                breakpoints={{
                    1400: {
                        slidesPerView: 3
                    },
                    900: {
                        slidesPerView: 2
                    },
                }}
                isCenter={true}
                bgColor={'lightGray'}
            />
        </>
    )
}

export default Contact

const Desc = styled.p`
    font-size: 20px;
    line-height: 32px;
`

const Wrapper = styled.div`
    max-width: 700px;
    margin: auto;
`



const Form = styled.form`
    margin 40px 0px;

`

const StyledStages = styled(Stages)`
    padding-bottom: 40px;
`

const Box = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 20px;
    @media only screen and (max-width: 400px) {
        flex-direction: column;
        align-items: center;
    }
`