import React, { useState } from 'react'
import styled from 'styled-components'
import Container from './Container'
import Audio from './Icons/Audio'
import Link from '../components/Icons/Link'
import { rgba } from 'polished'
import ArrowRight2 from '../components/Icons/ArrowRight2'
import { FaVideo, FaBook, FaImage } from 'react-icons/fa'
import { RiArticleLine } from 'react-icons/ri'




const LibraryToggler = ({ setAudioUrl, setIsOpenModal, number, data, action = true }) => {
    const { content, name, createdAtText, type } = data
    const [isOpen, setIsOpen] = useState(false)

    const handleTogglerClick = () => {
        setIsOpen(prev => !prev)
    }

    const handleType = (type) => {
        switch (type) {
            case 'text':
                return <RiArticleLine className='icon no-zoom' />
            case 'link':
                return <Link className='icon' />
            case 'video':
                return <FaVideo className='icon' />
            case 'audio':
                return <Audio className='icon' />
            case 'image':
                return <FaImage className='icon' />
            case 'book':
                return <FaBook className='icon no-zoom' />
            default:
                return <></>
        }
    }

    const handleIconClick = (e, type, content) => {
        e.stopPropagation()
        switch (type) {
            case 'link':
                window.open(content, '_blank', 'noopener,noreferrer');
                break
            case 'video':
                window.open(content, '_blank', 'noopener,noreferrer');
                break
            case 'audio':
                setAudioUrl(content)
                setIsOpenModal(true)
                break
            default:
                return <></>
        }
    }

    return (
        <>
            <StyledLibraryToggler onClick={action === true ? handleTogglerClick : null} isBg={number % 2}>
                <Container disablePaddingTop>
                    <Wrapper>
                        <Date>{createdAtText}</Date>
                        <Name>{name}</Name>
                        <Icon onClick={(e) => handleIconClick(e, type, content)}>{handleType(type)}</Icon>
                        {
                            action &&
                            <StyledArrowRight2 isopen={isOpen ? 1 : 0} onClick={(e) => handleIconClick(e, type, content)} />
                        }
                    </Wrapper>
                </Container>
            </StyledLibraryToggler>
        </>
    )
}

export default LibraryToggler

const StyledLibraryToggler = styled.div`
    transition: all 0.3s ease-in-out;
    ${props => props.isBg && `
        background-color: ${rgba(props.theme.colors.primary, 0.2)};
    `};
    &:hover {
        background-color: ${props => rgba(props.theme.colors.primary, 0.4)};
    };
    
`

const Wrapper = styled.div`
    max-width: 1170px;
    height: 75px;
    margin: auto;
    display: flex;
    align-items: center;
    gap: 35px;
    `

const Date = styled.div`
    color: ${props => props.theme.colors.primary};
    font-size: 24px;
    font-weight: bold;
    font-family: 'Merriweather', serif;
`

const Name = styled.div`
    flex: 6;
`

const Icon = styled.div`
    flex: 1;
  .icon {
        fill: ${props => props.theme.colors.primary};
        font-size: 30px;
        transition: .3s;
    &:not(.no-zoom):hover {
          transform: scale(125%);
          cursor: pointer;
    } 
    }
`

const StyledArrowRight2 = styled(ArrowRight2)`
    fill: ${props => props.theme.colors.primary};
    transform: translateY(${props => props.isopen ? '20px' : '0px'}) rotate(${props => props.isopen ? '90deg' : '0deg'}) ;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
`

