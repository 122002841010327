import React from 'react'
import styled from 'styled-components'
import Arrow from '../assets/images/arrow-maker.svg'

const FeatureListWrapper = styled.div`
  h3 {
    font-size: 24px;
    color: ${props => props.theme.colors.primary};
    line-height: 33px;
    margin-bottom: 40px;
    @media (max-width: ${props => props.theme.mobile}) {
      text-align: center;
    }
  }

  ul {
    list-style: none;
  }

  li {
    &::before {
      content: ' ';
      background: url(${Arrow}) no-repeat;
      width: 17px;
      height: 23px;
      display: inline-block;
      position: absolute;
      top: 0px;
      left: 0px;
      @media (max-width: 400px) {
        left: 50%;
        transform: rotate(90deg);
      }
    }
    position: relative;
    font-size: 20px;
    margin: 10px 0 15px;
    display: block;
    padding-left: 35px;
    @media (max-width: 400px) {
      padding-left: 0px;
      text-align: center;
      padding-top: 25px;
      
    }
  }
`;

const FeatureList = ({ title, data, ...props }) => (
  <FeatureListWrapper {...props}>
    {
      title && (
        <h3 className={'title'}>
          {title}
        </h3>
      )
    }
    <ul>
      {
        data.map((e, index) => (
          <li key={index} >{e.name}</li>
        ))
      }
    </ul>
  </FeatureListWrapper>
);

export default FeatureList