import React from 'react'

const Magnifier = (props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={33.005} height={33} viewBox="0 0 33.005 33" {...props}>
            <path
                data-name="Path 2960"
                d="M13.284 26.553a13.251 13.251 0 0 0 8.143-2.8l8.78 8.78a1.651 1.651 0 0 0 2.335-2.335l-8.78-8.78a13.276 13.276 0 1 0-10.478 5.132ZM6.229 6.224a9.977 9.977 0 1 1 0 14.11 9.941 9.941 0 0 1-.052-14.059l.051-.051Z"
            />
        </svg>
    )
}

export default Magnifier