import React from 'react'
import BigTitle from '../components/BigTitle'
import Container from '../components/Container'
import Title from '../components/Title'
import styled from 'styled-components'
import schedule from '../assets/images/schedule.jpg'
import ScheduleToggler from '../components/ScheduleToggler'
import { useQuery } from '@tanstack/react-query'
import TaskRow from '../components/TaskRow'
import Collapse from '../components/Collapse'
import Stages from '../components/Stages'
import Slider from '../components/Slider'
import Card from '../components/Card'
import { slide1Data } from '../FakeData/SliderData'
import taskService from '../services/taskService'
import Spiner from '../components/Spiner'

const Schedule = () => {


    const {
        data: dataUserTasks,
        isSuccess: isSuccessUserTasks,
        isLoading: isLoadingUsersTasks
    } = useQuery(
        ['userTasks'],
        taskService.getUserTasks()
    )

    return (
        <>
            {
                isLoadingUsersTasks && <Spiner />
            }
            {
                isSuccessUserTasks && (
                    <>
                        <Container>
                            <BigTitle>Terminarz</BigTitle>
                            <Title>Zarezerwuj i zaplanuj czas na naukę</Title>
                            <Wrapper>
                                <Boxes>
                                    <Desc>
                                        Poniżej znajdziesz dokładny harmonogram swoich zadań w poszczególnych semestrach. Zarezerwuj sobie czas i rozplanuj naukę. Pamiętaj, że warto uczyć się systematycznie, by być na bieżąco i „wyrabiać się" z zadaniami.
                                    </Desc>
                                    <Image src={schedule} alt='schedule' />
                                </Boxes>
                            </Wrapper>
                        </Container>
                        {
                            dataUserTasks.some(task => task.half === 1) && (
                                <Container>
                                    <Title>semestr zimowy</Title>
                                </Container>
                            )
                        }
                        {
                            dataUserTasks.filter(task => (task.half === 1) && (task.showInCalendar)).sort((a,b) => (a.dateFromText > b.dateFromText) ? 1 : (b.dateFromText > a.dateFromText) ? -1 : 0 ).map((task, index) => (
                                <Collapse
                                    key={index}
                                    isActive={task.published}
                                    toggler={
                                        <ScheduleToggler
                                            isActive={task.published}
                                            number={index + 1}
                                            text={`${task.dateFromText} - ${task.dateToText}`}
                                            subtext={task.name}/>
                                    }
                                >
                                    <TaskRow data={task} noBackground extraLine />
                                </Collapse>
                            ))
                        }
                        {
                            dataUserTasks.some(task => task.half === 2) && (
                                <Container>
                                    <Title>semestr letni</Title>
                                </Container>
                            )
                        }
                        {
                            dataUserTasks.filter(task => (task.half === 2) && (task.showInCalendar)).map((task, index) => (
                                <Collapse
                                    key={index}
                                    isActive={task.published}
                                    toggler={
                                        <ScheduleToggler
                                            isActive={task.published}
                                            number={index + 1}
                                            text={`${task.dateFromText} - ${task.dateToText}`}
                                            subtext={'test'}/>
                                    }
                                >
                                    <TaskRow data={task} noBackground extraLine />
                                </Collapse>
                            ))
                        }
                        <StyledStages noBackground />
                        <Slider
                            data={slide1Data}
                            component={<Card />}
                            breakpoints={{
                                1400: {
                                    slidesPerView: 3
                                },
                                900: {
                                    slidesPerView: 2
                                },
                            }}
                            isCenter={true}
                            bgColor={'lightGray'}
                        />
                    </>
                )
            }
        </>
    )
}

export default Schedule

const Wrapper = styled.div`
    max-width: 1170px;
    margin: auto;
`

const Boxes = styled.div`
    display: flex;
    gap: 20px;
    justify-content: space-between;
    @media screen and (max-width: ${props => props.theme.mobile}) {
        flex-direction: column;
        align-items: center;
      }
`

const Desc = styled.div`
      font-size: 20px;
`

const Image = styled.img`
    display: block;
    max-width: 400px;
    width: 100%;
    height: auto;
`

const StyledStages = styled(Stages)`
      margin: 40px 0px;
`





