import React from 'react'
import Container from '../components/Container'
import BigTitle from '../components/BigTitle'
import styled from 'styled-components'
import Title from '../components/Title'
import Slider from "../components/Slider";
import { slide1Data } from "../FakeData/SliderData";
import Card from "../components/Card";
import Stages from "../components/Stages";
import { useParams } from 'react-router-dom'
import { useQuery } from "@tanstack/react-query";
import inquisitiveService from "../services/inquisitiveService";
import Spiner from '../components/Spiner'
import TaskDescription2 from "../components/TaskDescription2";


const Curiosity = () => {
    const { id } = useParams()

    const {
        data,
        isSuccess,
        isLoading,
    } = useQuery(
        ['inquisitive', id],
        inquisitiveService.getSingleInquisitive(id)
    );

    return (
        <>
            {
                isLoading && <Spiner />
            }
            {isSuccess &&
                <Container>
                    <BigTitle>{data.title}</BigTitle>
                    <Title>{data.subtitle}</Title>
                    <Wrapper>
                        <div>
                            <Date>{data.publicationDateText}</Date>
                            <Teaser>{data.abstract}</Teaser>
                            <TaskDescription2 data={data.content}/>
                        </div>
                        <Image src={data.image} alt='' />
                    </Wrapper>
                    <StylesStages noBackground />
                </Container>
            }
            <Slider
                data={slide1Data}
                component={<Card />}
                breakpoints={{
                    1400: {
                        slidesPerView: 3
                    },
                    900: {
                        slidesPerView: 2
                    },
                }}
                isCenter={true}
                bgColor={'lightGray'}
            />
        </>
    )
}

export default Curiosity

const Wrapper = styled.div`
    max-width: 1175px;
    display: flex;
    align-items: flex-start;
    gap: 40px;
    margin: 38px auto 40px;
    @media only screen and (max-width: ${props => props.theme.mobile}) {
        flex-direction: column;
        align-items: center;
    }
`

const Date = styled.div`
    margin-bottom: 20px;
`

const Teaser = styled.div`
    font-size: 24px;
    line-height: 38px;
    font-weight: bold;
    font-family: 'Merriweather', serif;
    margin-bottom: 40px;
    color: ${props => props.theme.colors.primary};
`

const StylesStages = styled(Stages)`
    padding-bottom: 40px;
`

const Image = styled.img`
    display: block;
    width: 100%;
    max-width: 400px;
`