import { useStoreState } from 'easy-peasy';
import React from 'react'
import styled from 'styled-components'
import ArrowRight from './Icons/ArrowRight'
import Link from "./Link";
import cx from 'classnames'


const StageCard = ({ data,  ...props }) => {

    const { link, icon, title, desc, disabled, color } = data

    const chairLevelSlug = useStoreState(state => state.user.chairLevelSlug)

    return (
        <StyledStageCard className={cx(props.className, disabled && 'disabled')}  color={color}>
            <Link to={link === '/DYNAMIC_CHAIR' ? `/about/chairs/${chairLevelSlug}` : link} disabled={disabled}>
                <img className='icon' src={icon} alt='icon' />
                <div>
                    <Title color={color}>{title}</Title>
                    <Description>{desc}</Description>
                </div>
                <div>
                    <ArrowRight className='arrow' />
                </div>
            </Link>
        </StyledStageCard>
    )
}

export default StageCard

const StyledStageCard = styled.div`
    min-height: 135px;
    position: relative;
    border: 1px solid ${props => props.color || props.theme.colors.primary};
    background-color: ${props => props.theme.colors.white};
    width: 100%;
    padding: 17px 17px 27px 17px;
    display: flex;
    align-items: flex-start;
    cursor: pointer;
    max-width: 339px;
    -webkit-box-shadow: inset 0px 10px 24px -21px rgba(81, 83, 97, 0.54);
    -moz-box-shadow: inset 0px 10px 24px -21px rgba(81, 83, 97, 0.54);
    box-shadow: inset 0px 10px 24px -21px rgba(81, 83, 97, 0.54);
    &:not(.disabled)::before {
        transition: all 0.3s ease-in-out;
        content: '';
        position: absolute;
        height: 10px;
        width: 70%;
        bottom: 0;
        right: 0;
        background-color: ${props => props.color || props.theme.colors.primary};
    }
    &.disabled {
        opacity: 0.3;
        cursor: initial;
    }
    a {
        display: flex;
        align-items: flex-start;
    }
    img {
        margin-right: 40px;
    }
    .icon,
    .arrow {
        transition: all 0.3s ease-in-out;
    }
    .arrow {
        fill: ${props => props.theme.colors.yellowGreen};
    }
    &:not(.disabled):hover {
        &::before {
            width: 40%;
        }
        .icon {
            transform: scale(1.1);
        }
        .arrow {
            fill: ${props => props.theme.colors.primary};
        }
        
    }
    @media only screen and (max-width: 700px) {
        min-height: 170px;
    }
    &.special {
        background-color: ${props => props.theme.colors.primary};
        color: ${props => props.theme.colors.white};
        .arrow {
            transition: all 0.3s ease-in-out;
            fill: ${props => props.theme.colors.yellow2};
        }
        &:not(.disabled):hover {
            .arrow {
                fill: ${props => props.theme.colors.yellow2};
            }
        }
    }
    
`

const Title = styled.div`
    font-size: 24px;
    line-height: 33px;
    font-weight: bold;
    color: ${props => props.color || props.theme.colors.primary};
    font-family: 'Merriweather', serif;
`

const Description = styled.div`
    font-size: 20px;
    line-height: 28px;
`



