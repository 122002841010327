import axios from './axios'

const quizService = {
    getQuizDetails: (id) => () => {
        return axios.get(`/api/v1/quiz/task/${id}`).then(res => res.data)
    },
    finishTheQuiz: (id) => (data) => {
        return axios.post(`/api/v1/quiz/finish/${id}`, data).then(res => res.data)
    }

}

export default quizService