import cathedrals from '../assets/images/katedry.jpg'
import news from '../assets/images/carousel_news.jpg'
import myStudies from '../assets/images/carousel_my_studies.jpg'
import forInterested from '../assets/images/dla-dociekliwych.jpg'

export const slide1Data = [
    {
        title: 'Aktualności',
        desc1: 'Sprawdzaj najnowsze informacje',
        desc2: 'i bądź zawsze na bieżąco z tematami,',
        desc3: 'które dotyczą Twoich studiów na UBCM.',
        image: news,
        imageAlt: 'mobile phone',
        link1: '/news',
        link1Text: 'Co nowego?',
    },
    {
        title: 'Katedry tematyczne',
        desc1: 'Uniwersytet Berlin-Chemie Menarini pozwoli Ci',
        desc2: 'poszerzyć horyzonty i rozwinąć się w swojej',
        desc3: 'dziedzinie, a także odkryć zupełnie nowe talenty.',
        image: cathedrals,
        imageAlt: 'graduade cap',
        link1: '/about/chairs',
        link1Text: 'Przejdź do katedr',
    },
    {
        title: 'Dla dociekliwych',
        desc1: 'To miejsce dla osób wyjątkowo głodnych wiedzy.',
        desc2: 'Znajdziesz tu dodatkowe informacje, najnowsze',
        desc3: 'trendy i nowinki z różnych dziedzin.',
        image: forInterested,
        imageAlt: 'light bulb',
        link1: '/for-inquisitive',
        link1Text: 'Sięgnij po wiedzę',
    },
    {
        title: 'Moje studia',
        desc1: 'Jeżeli odkryłeś/łaś w sobie umiejętności',
        desc2: 'uczenia innych i motywowania do działania –',
        desc3: 'tematyka tej katedry jest dla Ciebie.',
        image: myStudies,
        imageAlt: 'light bulb',
        link1: '/my-studies',
        link1Text: 'Wróć do nauki',
    },

]