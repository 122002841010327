import React from 'react'
import { Link as RouterLink } from "react-router-dom";
import styled from 'styled-components';

const Link = ({ to = '#', disabled, children, ...props }) => {
  return (
    <StyledLink to={to} disabled={disabled} {...props} >{children}</StyledLink>
  )
}

export default Link

const StyledLink = styled(RouterLink)`
    text-decoration: none;
    color: inherit;
    ${props => props.disabled && `
      pointer-events: none;
    `}
`