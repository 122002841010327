import React, { useEffect, useState } from 'react'
import Background from '../components/Background'
import Container from '../components/Container'
import FlexBox from '../components/FlexBox'
import NewTitle from '../components/NewTitle'
import styled from 'styled-components'
import { useMutation, useQuery } from '@tanstack/react-query'
import examService from '../services/examService'
import { useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import TestQuestion from '../components/TestQuestion'
import Button from '../components/Button'
import Spiner from '../components/Spiner'
import Modal from '../components/Modal'
import { toast } from 'react-toastify'
import { incrementDataText } from '../helpers/helpers'
import useMediaQuery from '../Hooks/MediaQueryHook'
import Image from '../components/Image'
// import BigTitle from '../components/BigTitle'
import Title from '../components/Title'
import { useNavigate } from 'react-router-dom'




const TestLecturer = () => {
    const navigate = useNavigate()
    const isMobile = useMediaQuery(`(max-width: 1200px`)
    const { examId, studentId } = useParams()
    const { register, handleSubmit, reset, getValues } = useForm()

    const [isDraftModal, setIsDraftModal] = useState(false)
    const [isFinishedModal, setIsFinishedModal] = useState(false)
    const [isUnFinishedModal, setIsUnFinishedModal] = useState(false)
    const [isExamAssessedModal, setIsExamAssessedModal] = useState(false)

    const {
        data: dataExam,
        isSuccess: isSuccessExam,
        isLoading: isLoadingExam,
        // refetch: refetchDataExam
    } = useQuery(
        ['specyficExam', examId, studentId],
        examService.getSpecificExam(examId, studentId)
    )

    // save draft version by lecturer
    // const { mutate: assessExamByLecturerDraft } = useMutation(
    //     ['assessExamByLecturerDraft'],
    //     examService.assessExamByLecturerDraft(),
    //     {
    //         onSuccess: () => {
    //             refetchDataExam()
    //             setIsDraftModal(true)
    //         }
    //     }
    // )


    //save finished verion by lecturer
    const { mutate: finishExamByLecturer } = useMutation(
        ['finishExamByLecturer'],
        examService.finishExamByLecturer(examId, studentId),
        {
            onSuccess: () => {
                toast.success("Pomyślnie zakończono spradzenie egzaminu")
                setIsFinishedModal(false)
                navigate(`/my-studies/lecturer-exams/${dataExam.id}`)
            },
            onError: () => {
                setIsFinishedModal(false)
            }

        }
    )

    // format data from api to form
    const formatDefaultData = (arr) => {
        const obj = {}
        arr.forEach(examQuestion => {
            if (examQuestion.type === 'CLOSED') {
                obj[examQuestion.myAnswer?.id] = {
                    answer: examQuestion.myAnswer.closedAnswers.map(item => String(item))[0],
                    id: examQuestion.myAnswer?.id,
                    points: examQuestion.myAnswer.points,
                }
            }
        })
        return obj
    }


    // set default values from api to form
    useEffect(() => {
        dataExam && reset(formatDefaultData(dataExam.examQuestions))
    }, [dataExam, reset])

    const formatOutPutData = (values) => {
        const valuesArr = Object.values(values)
        const newValues = valuesArr.map(item => true && { ...item, points: Number(item.points) })
        return Object.values(newValues)
    }

    const onSubmit = () => {

        // if (dataExam.wholeExamAssessedForUser) {
        //     setIsExamAssessedModal(true)
        // }
        // else {
        //     const values = getValues()
        //     const isAnswerWithoutPoints = Object.values(values).some(answer => answer.points === null)
        //     if (isAnswerWithoutPoints) {
        //         setIsUnFinishedModal(true)
        //     }
        //     else {
        //         setIsFinishedModal(true)
        //     }
        // }
    }

    const handleFinishExam = () => {
        const values = getValues()
        finishExamByLecturer(formatOutPutData(values))
    }

    // const handleDraftVersion = () => {
    //     const values = getValues()
    //     assessExamByLecturerDraft(formatOutPutData(values))
    // }


    const handleShowPoints = () => {
        setIsExamAssessedModal(false)
        navigate(`/my-studies/lecturer-exams/${dataExam.id}`)
    }

    const handleBackToExams = () => {
        setIsExamAssessedModal(false)
        navigate(`/my-studies/lecturer-exams`)
    }



    return (
        <>
            {
                isLoadingExam && <Spiner />
            }
            {
                isSuccessExam && (
                    <>

                        {/* DRAFT MODAL */}
                        <Modal isOpen={isDraftModal} setIsOpen={setIsDraftModal}>
                            Wersja robocza została zapisana pomyślnie.
                            W każdej chwili możesz wrócić do sprawdzania
                            tego egzaminu. Pamiętaj, żeby zakończyć
                            ocenę przed upływem terminu.
                            Wersja robocza nie jest oficjalną oceną.
                            <FlexBox justifyContnet={'space-between'} margin='10px 0px' flexWrap={'wrap'} gap={'20px'}>
                                <Button onClick={() => setIsDraftModal(false)}>Wróć do oceny</Button>
                                <Button>Wyloguj się</Button>
                            </FlexBox>
                        </Modal>

                        {/* FINISHED MODAL */}
                        <Modal isOpen={isFinishedModal} setIsOpen={setIsFinishedModal}>
                            Po kliknięciu „Zakończ i wyślij” nie możesz wrócić do sprawdzania, a Postawiona przez Ciebie ocena będzie widoczna dla studentów.
                            <FlexBox justifyContnet={'space-between'} margin='10px 0px' flexWrap={'wrap'} gap={'20px'}>
                                <Button onClick={() => setIsFinishedModal(false)}>Wróć do oceny</Button>
                                <Button onClick={handleFinishExam}>Zakończ i wyślij</Button>
                            </FlexBox>
                        </Modal>

                        {/* UNFINISHED MODAL */}
                        <Modal isOpen={isUnFinishedModal} setIsOpen={setIsUnFinishedModal}>
                            Ups...<br />
                            Musisz ocenić wszystkie pytania otwarte,<br />
                            by zakończyć ocenę tego egzaminu.<br />
                            Wstaw brakującą punktację lub zapisz wersję roboczą<br />
                            oceny i wróć do sprawdzania później.<br />
                            <FlexBox justifyContnet={'center'} margin='10px 0px'>
                                <Button onClick={() => setIsUnFinishedModal(false)}>Wróć do oceny</Button>
                            </FlexBox>
                        </Modal>

                        {/* EXAM ASSESSED MODAL */}
                        <Modal isOpen={isExamAssessedModal} setIsOpen={setIsExamAssessedModal}>
                            <BreakBox>
                                Ten egzamin został już przez Ciebie oceniony.
                                Masz wgląd do sprawdzonego egzaminu i oceny,
                                ale nie możesz jej już zmodyfikować.
                            </BreakBox>

                            <FlexBox justifyContnet={'space-between'} margin='10px 0px' flexWrap={'wrap'} gap={'20px'}>
                                <Button onClick={handleShowPoints}>Zobacz ocenę</Button>
                                <Button onClick={handleBackToExams}>Wróć do egzaminów</Button>
                            </FlexBox>
                        </Modal>

                        <Background bgColor={'primary'}>
                            <Container>
                                {/* <BigTitle color='yellow2'>{dataExam.issue.name}</BigTitle> */}
                                <Title color='white'>Egzamin</Title>
                            </Container>
                        </Background>
                        <Background bgColor={'aquamarine'}>
                            <Container maxWidth='1160px'>
                                <StyledFlexBox2 gap={'50px'} alignItems='flex-start' justifyContnet='center' margin='0 0 38px 0'>
                                    <Desc>
                                        {
                                            isMobile ? (
                                                <>
                                                    <p>
                                                        Egzamin składa się z pytań testowych i zadań otwartych. Punktacja z części testowej zlicza się automatycznie. Zadania otwarte czekają na Twoją ocenę przez 2 tygodnie. Masz możliwość przekazać feedback ocenianej osobie. Studenci mogą kontaktować się z Tobą w sprawie omówienia wyników.
                                                    </p>
                                                </>
                                            ) : (
                                                <>
                                                    <p>Egzamin składa się z pytań testowych i zadań otwartych. Punktacja z części</p>
                                                    <p>testowej zlicza się automatycznie. Zadania otwarte czekają na Twoją ocenę</p>
                                                    <p>przez 2 tygodnie. Masz możliwość przekazać feedback ocenianej osobie.</p>
                                                    <p>Studenci mogą kontaktować się z Tobą w sprawie omówienia wyników. </p>
                                                </>
                                            )
                                        }
                                        <Desc>
                                            <FlexBox margin='30px 0 0 0'>Termin sprawdzenia egzaminów upływa:</FlexBox>
                                            <Deadline>
                                                {incrementDataText(dataExam.endDateText, 14)}
                                            </Deadline>
                                        </Desc>
                                    </Desc>
                                    <Image src={dataExam.imageUrl} alt='placeholder' />
                                </StyledFlexBox2>
                            </Container>
                        </Background>
                        <Background>
                            <Container maxWidth='1160px'>
                                <NewTitle variant='h3'>{dataExam.examFinishedData.user.namesConcated}</NewTitle>
                            </Container>
                        </Background>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            {
                                dataExam.examQuestions.map((question, index) => (
                                    <TestQuestion
                                        register={register}
                                        key={index}
                                        data={question}
                                        number={index + 1}
                                        quizQuestions={null}
                                        isLecturerView={true}
                                        isFinishedByStudent={true}
                                        // showSelected={true}
                                    />
                                ))
                            }
                            {/* <Container maxWidth='1200px'>
                                <FlexBox justifyContnet={'space-between'}>
                                    <Button disabled={dataExam.wholeExamAssessedForUser} type='button' onClick={handleDraftVersion}>Zapisz wersję roboczą</Button>
                                    <Button type='submit'>Zapisz ocenę</Button>
                                </FlexBox>
                            </Container> */}
                        </form>
                    </>
                )
            }
        </>
    )
}

export default TestLecturer

const Desc = styled.div`
  font-size: 20px;
  line-height: 32px;
  color: ${props => props.theme.colors.balck};
`

const Deadline = styled.div`
    font-size: 32px;
    line-height: 40px;
    font-weight: bold;
    font-family: 'Merriweather', serif;
    color: ${props => props.theme.colors.primary};
`

const StyledFlexBox2 = styled(FlexBox)`
    @media only screen and (max-width: ${props => props.theme.mobile2}) {
        flex-direction: column;
        align-items: center;
    }
`

const BreakBox = styled.div`
    max-width: 520px;
    margin: auto;
`