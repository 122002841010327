import { rgba } from 'polished'
import React from 'react'
import ReactModal from 'react-modal'
import styled from 'styled-components'
import useMediaQuery from '../Hooks/MediaQueryHook'
import { VscClose } from 'react-icons/vsc'


const Modal = ({ onRequestClose = true, isOpen, setIsOpen, children }) => {
    const isMobile = useMediaQuery(`(max-width: 900px`)

    const handleCloseModal = (e) => {
        e.stopPropagation()
        setIsOpen(false)
    }

    return (
        <ReactModal
            isOpen={isOpen}
            onRequestClose={onRequestClose ? handleCloseModal : null}
            style={
                {
                    overlay: {
                        backgroundColor: rgba('#007A81', 0.8),
                        zIndex: 9999,
                    },
                    content: {
                        top: 0,
                        border: 'none',
                        padding: 0,
                        inset: '0px 0px',
                        margin: ' auto',
                        maxWidth: isMobile ? '420px' : '740px',
                        height: 'fit-content',
                        overflow: 'initial'
                    }
                }
            }
        >
            <ModalContent>
                {children}
                <StyledVscClose onClick={handleCloseModal} />
            </ModalContent>
        </ReactModal>
    )
}

export default Modal

const ModalContent = styled.div`
    border: 1px solid ${props => props.theme.colors.primary};
    display: flex;
    flex-direction: column;
    gap: 26px;
    padding: 35px;
    position: relative;
    font-size: 24px;
    line-height: 38px;
    font-weight: bold;
    font-family: 'Merriweather', serif;
    color: ${props => props.theme.colors.primary};
    text-align: center;
    &::before {
        content: '';
        position: absolute;
        width: 30%;
        height: 10px;
        bottom: 0;
        right: 0;
        background-color: ${props => props.theme.colors.primary};
    }
    @media only screen and (max-width: ${props => props.theme.mobile}) {
        gap: 10px;
        font-size: 16px;
        line-height: 25px;
        padding: 15px;
    }
    @media only screen and (max-width: 400px) {
        font-size: 12px;
        line-height: 18px;
    }
`

const StyledVscClose = styled(VscClose)`
    font-size: 40px;
    position: absolute;
    top:0;
    right: 0;
    transform: translateY(-100%);
    cursor: pointer;
    color: ${props => props.theme.colors.white};
`