import React from 'react'
// import Card from '../components/Card'
// import coaching from '../assets/images/coaching.jpg'
// import managment from '../assets/images/managment.jpg'
// import marketing from '../assets/images/marketing.jpg'
// import sales from '../assets/images/sales.jpg'
// import specialization from '../assets/images/specialization.jpg'
import styled from 'styled-components'
import BigTitle from '../components/BigTitle'
import Container from '../components/Container'
import chairService from '../services/chairService'
import { useQuery } from '@tanstack/react-query'
import ChairCard from '../components/ChairCard'
import Stages from "../components/Stages";
import Spiner from '../components/Spiner'


// const cathedralsData = [
//     {
//         title: 'Coaching i Rozwój',
//         desc1: 'Jeżeli odkryłeś/łaś w sobie umiejętności',
//         desc2: 'uczenia innych i motywowania do działania –',
//         desc3: 'tematyka tej katedry jest dla Ciebie.',
//         image: coaching,
//         imageAlt: 'coaching',
//         link1: 'coaching-and-development/specialist',
//         link1Text: 'Specjalista',
//         link2: 'coaching-and-development/manager',
//         link2Text: 'Manager',
//         descWidth: '400px'
//     },
//     {
//         title: 'Marketing i Promocja',
//         desc1: 'Masz talent do kreowania nowych rozwiązań?',
//         desc2: 'Chętnie działasz niestandardowo? Masz głowę pełną',
//         desc3: 'pomysłów? To miejsce dla Ciebie.',
//         image: marketing,
//         imageAlt: 'marketing',
//         link1: 'marketing-and-promotion/specialist',
//         link1Text: 'Specjalista',
//         link2: 'marketing-and-promotion/manager',
//         link2Text: 'Manager',
//         descWidth: '460px'
//     },
//     {
//         title: 'Sprzedaż',
//         desc1: 'Masz dobrze rozwinięte umiejętności sprzedażowe',
//         desc2: 'i duże doświadczenie, ale poszukujesz nowej energii?',
//         desc3: 'Katedra Sprzedaży czeka na Ciebie.',
//         image: sales,
//         imageAlt: 'sales',
//         link1: 'sales/specialist',
//         link1Text: 'Specjalista',
//         link2: 'sales/manager',
//         link2Text: 'Manager',
//         descWidth: '400px'
//     },
//     {
//         title: 'Zarządzanie i Rozwój',
//         desc1: 'Jeżeli ciekawią Cię mechanizmy',
//         desc2: 'zarządzania zespołami i role, jakie w nich',
//         desc3: 'pełnimy – to miejsce dla Ciebie.',
//         image: managment,
//         imageAlt: 'managment',
//         link1: 'managment-and-development/specialist',
//         link1Text: 'Specjalista',
//         link2: 'managment-and-development/manager',
//         link2Text: 'Manager',
//         descWidth: '400px'
//     },
//     {
//         title: 'Specjalizacja Zawodowa',
//         desc1: 'Jeżeli ciekawią Cię zagadnienia komunikacji, ról zespołowych,',
//         desc2: 'pracy pod presją czasu i radzenia sobie z emocjami w stresie,',
//         desc3: 'znajdziesz tu sporo zagadnień dla siebie.',
//         image: specialization,
//         imageAlt: 'specialization',
//         link1: 'professional-specialization/specialist',
//         link1Text: 'Specjalista',
//         link2: 'professional-specialization/manager',
//         link2Text: 'Manager',
//         descWidth: '400px'
//     }
// ]

const Cathedrals = () => {
    const {
        data: dataChairs,
        isSuccess: isSuccessChairs,
        isLoading: isLoadingChairs
    } = useQuery(
        ['chairs'],
        chairService.getAllChairs()
    )

    return (
        <>
            {
                isLoadingChairs && <Spiner />
            }
            {
                isSuccessChairs && (
                    <>
                        <Container>
                            <BigTitle>
                                Katedry
                            </BigTitle>
                            <Row>
                                {
                                    dataChairs.map((chair, index) => (
                                        <ChairCard
                                            data={chair}
                                            key={index}
                                        />
                                    ))
                                }
                            </Row>
                        </Container>
                        <StyledStages noBackground />
                    </>
                )
            }
        </>
    )
}

export default Cathedrals

const Row = styled.div`
    display: flex;
    justify-content: center;
    gap: 60px;
    flex-wrap: wrap;
    @media only screen and (max-width: ${props => props.theme.mobile}) {
        gap: 45px;
    }
`
const StyledStages = styled(Stages)`
    padding-top: 40px;
    padding-bottom: 40px;
`