const theme = {
    colors: {
        primary: '#007A81',
        primaryLight: 'rgb(212,231,234)',
        secondary: 'red',
        gray: '#919191',
        gray2: '#707070',
        yellowGreen: '#BBA907',
        darkGray: '#1e1e1e',
        white: '#FFFFFF',
        lightYellow: '#f7f4ef',
        lightGray: '#E8E8E8',
        yellow: '#D2CA81',
        yellow2: '#F9E52D',
        transparent: 'transparent',
        black: '#000000',
        red: '#FF0000',
        blue: '#0774bb',
        aquamarine: "#CCE4E6"
    },
    mobile: '1100px',
    mobile1: '1024px',
    mobile2: '992px',
    screenXL: '2600px',
    maxWidth: '1200px',
    paddings: {
        mobile: '20px'
    }
}

export default theme
