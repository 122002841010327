import React from 'react'

const SortAsc = ({ isActive, ...props }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={23.734}
            height={20.498}
            {...props}
        >
            <g>
                <path data-name="Path 3413" d="m11.867.998 11 19h-22Z" fill={isActive ? "#007a81" : 'transparent'} />
                <path
                    data-name="Path 3413 - Outline"
                    d="m11.867-.002 11.867 20.5H0ZM22 19.498l-10.133-17.5-10.133 17.5Z"
                    fill="#007a81"
                />
            </g>
        </svg>
    )
}

export default SortAsc
