import React from 'react'
import styled from 'styled-components'

const Title = ({ color = 'yellowGreen', children, ...props }) => {
    return (
        <StyledTitle color={color} {...props}>
            {children}
        </StyledTitle>
    )
}

export default Title

const StyledTitle = styled.h1`
    font-size: 48px;
    line-height: 60px;
    margin-bottom: 30px;
    color: ${props => props.theme.colors[props.color]};
    font-family: 'Lato', sans-serif;
    text-align: center;
    @media only screen and (max-width: ${props => props.theme.mobile}) {
        font-size: 28px;
        line-height: 38px;
        margin-bottom: 20px;
    }
`