import React from 'react'
import styled from 'styled-components'

const BigDescription = ({ children, ...props }) => {
    return (
        <StyledBigDescription {...props}>
            {children}
        </StyledBigDescription>
    )
}

export default BigDescription

const StyledBigDescription = styled.p`
    font-family: 'Merriweather', serif;
    font-size: 22px;
    line-height: 36px;
    margin-bottom: 30px;
    @media only screen and (max-width: ${props => props.theme.mobile}) {
        font-size: 20px;
        line-height: 30px;
        margin-bottom: 26px;
        text-align: center;
    }
`