import React, { forwardRef } from 'react'
import InputGroup from './InputGroup'
import Label from './Label'
import styled from 'styled-components'

const TextArea = forwardRef(({ isDisabled, placeholder, color = 'yellowGreen', borderColor = 'gray', error, type, label, name, ...props }, ref) => {
    return (
        <InputGroupStyled>
            {
                label && <Label label={label} />
            }
            <InputStyled
                color={color}
                borderColor={borderColor}
                rows={4}
                name={name}
                ref={ref}
                placeholder={placeholder}
                isDisabled={isDisabled}
                {...props}
            />
            {
                error && <Error>{error}</Error>
            }
        </InputGroupStyled>
    )
})

export default TextArea

const InputStyled = styled.textarea`
    resize: none;
    width: 100%;
    padding: 15px;
    border: 1px solid ${props => props.theme.colors[`${props.borderColor}`]};
    border-radius: 0;
    background-color: white;
    outline: none;
    color: ${props => props.theme.colors[`${props.color}`]};
    &::placeholder {
        color: ${props => props.theme.colors[`${props.color}`]};
    }
    pointer-events: ${props => props.isDisabled ? 'none' : 'auto'};
`

const InputGroupStyled = styled(InputGroup)`
    position: relative;
`

const Error = styled.small`
    color: red;
    margin-left: 15px;
`
