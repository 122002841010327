export const parseText = (text) => {
    return text.replace('.', '').replaceAll(' ', '-').toLowerCase()
}

export const fileToBase64 = async (file) => {
    const fr = new FileReader();
    return new Promise((resolve, reject) => {
        try {
            fr.onload = function () {
                const base64 = fr.result;
                const parsedBase64 = base64.substr(base64.indexOf(",") + 1);
                resolve(parsedBase64);
            };
            fr.readAsDataURL(file);
        } catch (e) {
            reject(e);
        }
    });
};

export const debounce = (func, timeout = 300) => {
    let timer
    return (...args) => {
        clearTimeout(timer)
        timer = setTimeout(() => {
            func.apply(this, args)
        }, timeout)
    }
}

export const parseParams = (objParams) => {
    let queryParams = '?'
    for (const key in objParams) {
        queryParams = queryParams + `${key}=${objParams[key]}&`
    }
    queryParams = queryParams.slice(0, -1)
    return queryParams
}

export const minutesToMiliseconds = (minutes) => {
    return minutes * 60 * 1000
}

export const incrementDataText = (date, days) => {
    const newDate = new Date(date)
    newDate.setDate(newDate.getDate() + 14);

    let day = newDate.getDate()
    let month = newDate.getMonth() + 1
    const year = newDate.getFullYear()

    if (month < 10) {
        month = `0${month}`
    }
    if (day < 10) {
        day = `0${day}`
    }

    const formatedDate = `${year}-${month}-${day}`
    return formatedDate
}



