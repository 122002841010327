import React from 'react'

import Footer from './Footer'
import styled from 'styled-components'
import { useLocation } from 'react-router-dom'
import Header from './Nav/Header'
import ScrollButton from './ScrollButton'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import TokenExpModal from './TokenExpModal'

const paths = ['/login', '/login/expired']

const Layout = ({ children, ...props }) => {

    const { pathname } = useLocation()
    return (
        <StyledLayout {...props}>
            <TokenExpModal />
            <ToastContainer />
            {
                !paths.includes(pathname) && <Header />
            }
            <Content
                marginTop={!paths.includes(pathname)}
                sidebar={pathname === '/my-studies'}
            >
                <ScrollButton />
                {children}
            </Content>
            {
                !paths.includes(pathname) && <Footer />
            }
        </StyledLayout>
    )
}

export default Layout

const StyledLayout = styled.div`
    min-height: 100vh;
    display: flex;
    flex-direction: column;
`

const Content = styled.main`
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-top: ${props => props.marginTop ? '136px' : '0px'};
    overflow: ${props => props.sidebar ? 'hidden' : 'initial'};


  @media (max-width: ${props => props.theme.mobile2}) {
    margin-top: ${props => props.marginTop ? '96px' : '0px'};
    }
`