import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Background from '../components/Background'
import Container from '../components/Container'
import FlexBox from '../components/FlexBox'
import NewTitle from '../components/NewTitle'
import { useForm } from 'react-hook-form'
import TestQuestion from '../components/TestQuestion'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useParams, useNavigate } from 'react-router-dom'
import examService from '../services/examService'
import Spiner from '../components/Spiner'
import Button from '../components/Button'
import { toast } from 'react-toastify'
import Modal from '../components/Modal'
import { useStoreActions } from 'easy-peasy'
import Slider from '../components/Slider'
import { slide1Data } from '../FakeData/SliderData'
import Card from '../components/Card'
import useMediaQuery from '../Hooks/MediaQueryHook'
import Image from '../components/Image'

const Test = () => {
    const isMobile = useMediaQuery(`(max-width: 1200px`)
    const [isDraftModal, setIsDraftModal] = useState(false)
    const [isFinishedModal, setIsFinishedModal] = useState(false)
    const [isErrorModal, setIsErrorModal] = useState(false)
    const logout = useStoreActions(actions => actions.auth.logoutUser)
    const { id } = useParams()
    const navigate = useNavigate()
    const { register, handleSubmit, getValues, reset } = useForm()

    const {
        data: dataExam,
        isSuccess: isSuccessExam,
        isLoading: isLoadingExam
    } = useQuery(
        ['exam', id],
        examService.getSingleExam(id),
    )

    // format data from api to form
    const formatDefaultData = (arr) => {
        const obj = {}
        arr.forEach(examQuestion => {
            if (examQuestion.type === 'CLOSED') {
                obj[examQuestion.myAnswer?.id] = {
                    answer: Array.isArray(examQuestion.myAnswer?.closedAnswers) ? examQuestion.myAnswer.closedAnswers.map(item => String(item))[0] : null,
                    id: examQuestion.myAnswer?.id,
                    points: examQuestion.myAnswer?.points || 0,
                }
            }
        })
        return obj
    }



    // set default values form api to form
    useEffect(() => {
        dataExam && reset(formatDefaultData(dataExam.examQuestions))
    }, [dataExam, reset])

    // update student answers
    const { mutate: updateStudentAnswers } = useMutation(
        ['updateStudentAnswers'],
        examService.updateStudentAnswers(),
        {
            onSuccess: () => {
                setIsDraftModal(true)
            }
        }
    )

    // finish exam by student
    const { mutate: finishExamByStudent } = useMutation(
        ['updateStudentAnswers'],
        examService.finishExamByStudent(id),
        {
            onSuccess: () => {
                toast.success('Pomyślnie przesłano odpowiedzi do egzaminu')
                setIsFinishedModal(false)
                navigate('/my-studies/exams')
            },
            onError: () => {
                setIsErrorModal(true)
                setIsFinishedModal(false)
            }
        }
    )

    // format output data
    const formatOutputData = (values) => {
        const valuesArr = Object.values(values)

        const changeFiled = (item) => {
            const obj = { id: item.id, closedAnswers: item?.answer ? [Number(item.answer)] : [] }
            return obj
        }
        const newValues = valuesArr.map(item => changeFiled(item))
        return newValues
    }


    // handlers
    const handleDraftVersion = () => {
        const values = getValues()
        updateStudentAnswers(formatOutputData(values))
    }

    const handleCloseDraftModal = () => {
        setIsDraftModal(false)
        logout()
        navigate('/login')
    }

    const onSubmit = () => {
        setIsFinishedModal(true)
    }

    const handleFinishExam = () => {
        const values = getValues()
        finishExamByStudent(formatOutputData(values))
    }

    const handleCloseErrorModal = () => {
        setIsErrorModal(false)
        setIsFinishedModal(false)
    }


    return (
        <>
            {
                isLoadingExam && <Spiner />
            }
            {
                isSuccessExam && (
                    <>
                        {/* DRAFT VERSION MODAL */}
                        <Modal isOpen={isDraftModal} setIsOpen={setIsDraftModal}>
                            Wersja robocza została zapisana pomyślnie.
                            W każdej chwili możesz wrócić do rozwiązywania
                            swojego egzaminu. Pamiętaj, żeby zakończyć
                            egzamin przed upływem jego terminu.
                            Wersja robocza nie podlega ocenie.
                            <FlexBox justifyContnet={'space-between'} margin='10px 0px' flexWrap={'wrap'} gap={'20px'}>
                                <Button onClick={() => setIsDraftModal(false)}>Wróć do egzaminu</Button>
                                <Button onClick={handleCloseDraftModal}>Wyloguj się</Button>
                            </FlexBox>
                        </Modal>

                        {/* FINISHED VERSION MODAL */}
                        <Modal isOpen={isFinishedModal} setIsOpen={setIsFinishedModal}>
                            Po kliknięciu „Zakończ i wyślij” nie możesz wrócić do egzaminu, a udzielone przez Ciebie odpowiedzi zostaną przesłane do Wykładowcy.
                            <FlexBox justifyContnet={'space-between'} margin='10px 0px' flexWrap={'wrap'} gap={'20px'}>
                                <Button onClick={() => setIsFinishedModal(false)}>Wróć do egzaminu</Button>
                                <Button onClick={handleFinishExam}>Zakończ i wyślij</Button>
                            </FlexBox>
                        </Modal>

                        {/* ERROR VERSION MODAL */}
                        <Modal isOpen={isErrorModal} setIsOpen={setIsErrorModal}>
                            {`Ups... Musisz udzielić odpowiedzi na wszystkie pytania. Wróć do egzaminu lub zapisz wersję roboczą i dokończ egzamin później.`}


                            <FlexBox justifyContnet={'center'} margin='10px 0px' flexWrap={'wrap'} gap={'20px'}>
                                <Button onClick={handleCloseErrorModal}>Wróć do egzaminu</Button>
                            </FlexBox>
                        </Modal>

                        <Background bgColor={'primary'}>
                            <Container maxWidth='1160px'>
                                <NewTitle variant='h1' color='yellow2'>
                                    Egzamin
                                </NewTitle>
                            </Container>
                        </Background>
                        <Background bgColor={'aquamarine'}>
                            <Container maxWidth='1160px'>
                                <StyledFlexBox2 gap={'50px'} alignItems='flex-start' justifyContnet='center' margin='0 0 38px 0'>
                                    <Desc>
                                        {
                                            isMobile ? (
                                                <>
                                                    <p>
                                                        Poniższy egzamin składa się z części testowej oraz pytań otwartych.
                                                        Pamiętaj – w niektórych zadaniach testowych znajduje się kilka poprawnych odpowiedzi. Masz także możliwość zapisania wersji roboczej swojego egzaminu i powrotu do niego. Nie zapomnij zakończyć egzaminu przed końcem terminu.
                                                    </p>
                                                </>
                                            ) : (
                                                <>
                                                    <p>Poniższy egzamin składa się z części testowej oraz pytań otwartych. </p>
                                                    <p>Pamiętaj – w niektórych zadaniach testowych znajduje się kilka poprawnych </p>
                                                    <p>odpowiedzi. Masz także możliwość zapisania wersji roboczej swojego egzaminu</p>
                                                    <p>i powrotu do niego. Nie zapomnij zakończyć egzaminu przed końcem terminu.</p>
                                                </>
                                            )
                                        }
                                        <Desc>
                                            <FlexBox margin='30px 0 0 0'>Termin przystąpienia do egzaminu upływa:</FlexBox>
                                            <Deadline>
                                                {dataExam.endDateText}
                                            </Deadline>
                                        </Desc>
                                    </Desc>
                                    <Image src={dataExam.imageUrlIssueTask ? dataExam.imageUrlIssueTask : dataExam.imageUrl} alt='egzamin' />
                                </StyledFlexBox2>
                            </Container>
                        </Background>
                        <Background>
                            <Container maxWidth='1160px'>
                                <NewTitle variant='h3'>Odpowiedz na poniższe pytania</NewTitle>
                            </Container>
                        </Background>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            {
                                dataExam.examQuestions.map((question, index) => (
                                    <TestQuestion
                                        register={register}
                                        key={index}
                                        data={question}
                                        number={index + 1}
                                        quizQuestions={null}
                                        isChecked={dataExam.finished}
                                        isFinishedByStudent={dataExam.finished}
                                    />
                                ))
                            }
                            {
                                (dataExam.finished || dataExam.afterDeadline) ? (
                                    <Background bgColor={'primary'}>
                                        <Container maxWidth='800px'>
                                            {/* <StyledLink href={`mailto:${dataExam?.lecturers[0]?.users[0]?.email}`} >
                                                <NewTitle variant='h3' color='white'>
                                                    Jeśli chcesz omówić swoje wyniki
                                                    skontaktuj się z wykładowcą
                                                    <ArrowRight fill='#FFFFFF' style={{ marginLeft: '10px' }} />
                                                </NewTitle>
                                            </StyledLink> */}
                                        </Container>
                                    </Background>
                                ) : (
                                    <Container maxWidth='1200px'>
                                        <FlexBox justifyContnet={'space-between'}>
                                            <Button type='button' onClick={handleDraftVersion}>Zapisz wersję roboczą</Button>
                                            <Button type='submit'>Zakończ egzamin</Button>
                                        </FlexBox>
                                    </Container>
                                )
                            }

                        </form>
                        <Container>
                            <Slider
                                data={slide1Data}
                                component={<Card />}
                                breakpoints={{
                                    1400: {
                                        slidesPerView: 3
                                    },
                                    900: {
                                        slidesPerView: 2
                                    },
                                }}
                                isCenter={true}
                            />
                        </Container>
                    </>
                )
            }
        </>
    )
}

export default Test

const Desc = styled.div`
  font-size: 20px;
  line-height: 32px;
  color: ${props => props.theme.colors.balck};
`

const Deadline = styled.div`
    font-size: 32px;
    line-height: 40px;
    font-weight: bold;
    font-family: 'Merriweather', serif;
    color: ${props => props.theme.colors.primary};
`

const StyledFlexBox2 = styled(FlexBox)`
    @media only screen and (max-width: ${props => props.theme.mobile2}) {
        flex-direction: column;
        align-items: center;
    }
`

// const StyledLink = styled.a`
//     text-decoration: none;
// `