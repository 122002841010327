import * as React from "react"

const Home = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={32} height={36} {...props}>
        <path
            d="M0 36V12L16.1 0 32 12v24H20.3V21.75h-8.65V36Zm3-3h5.65V18.75H23.3V33H29V13.5L16.1 3.75 3 13.5Zm13-14.65Z"
            fill="#e8e8e8"
        />
    </svg>
)

export default Home
