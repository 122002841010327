import axios from "./axios"

const chairService = {
    getAllChairs: () => () => {
        return axios.get('/api/v1/chairs').then(res => res.data)
    },
    getSingleChairLevel: (id) => () => {
        return axios.get(`/api/v1/chair-level/${id}`).then(res => res.data)
    },
    getSingleChairLevelBySlug: (slug) => () => {
        return axios.get(`/api/v1/chair-level/slug/${slug}`).then(res => res.data)
    },

}

export default chairService