import * as React from "react"

const Logo = ({ color = '#000000', width, ...props }) => (
    <svg
        data-name="Group 2"
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        viewBox="0 0 166.065 178.333"
        {...props}
    >
        <defs>
            <clipPath id="a">
                <path data-name="Rectangle 2" fill="none" d="M0 0h166.065v178.333H0z" />
            </clipPath>
        </defs>
        <g data-name="Group 1" clipPath="url(#a)">
            <path
                data-name="Path 1"
                d="M27.053 20.249a.328.328 0 0 1 .527.149l2.457 3.745a4.382 4.382 0 0 0 2.4 1.953 3.576 3.576 0 0 0 3-.662q3.249-2.131.9-5.706l-2.388-3.64a.363.363 0 0 1-.083-.226c.007-.055.091-.15.251-.286s.386-.313.679-.53l-.671-1.027-3.615 2.371.677 1.03q.473-.272.769-.42a.771.771 0 0 1 .376-.125.364.364 0 0 1 .174.166l2.412 3.678a3.263 3.263 0 0 1 .647 1.768 1.549 1.549 0 0 1-.808 1.286q-1.7 1.112-2.921-.753l-2.483-3.784a.328.328 0 0 1 .073-.543q.174-.161.754-.615l-.678-1.032-3.99 2.618.676 1.031q.647-.35.865-.446"
                fill={color}
            />
            <path
                data-name="Path 2"
                d="m37.252 13.944 3.209 6.175a.367.367 0 0 1 .059.233c-.013.054-.106.14-.278.259s-.416.272-.729.458l.568 1.094 3.822-1.986-.568-1.094q-.513.232-.822.348a.838.838 0 0 1-.393.09.335.335 0 0 1-.163-.179l-2.547-4.9 7.277 4.291 1.4-.727-3.886-7.478a.364.364 0 0 1-.059-.232q.019-.081.278-.26t.715-.451l-.568-1.094-3.795 1.973.568 1.095q.513-.232.815-.344a.815.815 0 0 1 .386-.086.333.333 0 0 1 .163.179l2.123 4.085-6.3-3.762-3.088 1.6.568 1.093a8.194 8.194 0 0 1 1.243-.382"
                fill={color}
            />
            <path
                data-name="Path 3"
                d="M47.375 8.83a.518.518 0 0 1 .326-.008.5.5 0 0 1 .171.251l2.507 6.072a.5.5 0 0 1 .055.3q-.028.1-.237.225t-.877.472l.47 1.139 4.457-1.84-.471-1.14q-.716.229-.955.284a.508.508 0 0 1-.326.008.5.5 0 0 1-.171-.25L49.817 8.27a.5.5 0 0 1-.056-.3c.018-.064.1-.139.237-.224s.431-.243.877-.472l-.47-1.14-4.457 1.84.47 1.14q.716-.229.955-.285"
                fill={color}
            />
            <path
                data-name="Path 4"
                d="M52.823 6.422a.486.486 0 0 1 .319.1 1.722 1.722 0 0 1 .285.359l4.787 7.1 1.625-.447.465-6.425 3.672 5.285 1.655-.455.343-8.511a1.3 1.3 0 0 1 .052-.427.535.535 0 0 1 .223-.248 5.455 5.455 0 0 1 .632-.32l-.322-1.192-4.153 1.143.327 1.189q.46-.094.769-.147a1.563 1.563 0 0 1 .42-.035.161.161 0 0 1 .139.124.937.937 0 0 1 .011.159l.16 5.638-4.279-6.252-.993.274-.429 7.612-2.941-4.92-.08-.172a.15.15 0 0 1 .053-.161 1.3 1.3 0 0 1 .34-.174q.263-.1.661-.246l-.327-1.189-4.424 1.218.327 1.188a5.646 5.646 0 0 1 .686-.067"
                fill={color}
            />
            <path
                data-name="Path 5"
                d="M69.614 2.323a.515.515 0 0 1 .315.084.5.5 0 0 1 .094.288l.712 6.532a.5.5 0 0 1-.03.3.513.513 0 0 1-.289.15q-.237.065-.974.208l.134 1.226 8.827-.963-.5-3.744-1.427.156q0 1.177-.02 1.619a1.692 1.692 0 0 1-.081.551.255.255 0 0 1-.214.125l-3.3.361-.317-2.9 2.617-.191-.193-1.768-2.6.377-.274-2.513 3.181-.347a.273.273 0 0 1 .251.074 1.535 1.535 0 0 1 .188.5q.106.4.352 1.508l1.443-.157-.337-3.642-8.688.948.134 1.226q.752-.019 1-.007"
                fill={color}
            />
            <path
                data-name="Path 6"
                d="M84.314 8.937q-.748-.079-.989-.123a.506.506 0 0 1-.3-.124.5.5 0 0 1-.056-.3l.061-2.762.905.02a1.4 1.4 0 0 1 .845.245 1.251 1.251 0 0 1 .424.595 9.525 9.525 0 0 1 .267 1q.028.157.156.721a6.4 6.4 0 0 0 .486 1.337 1.747 1.747 0 0 0 .617.693 1.84 1.84 0 0 0 .924.23 1.566 1.566 0 0 0 1.355-.555 2.615 2.615 0 0 0 .515-1.448l-.139-.065a.8.8 0 0 1-.63.266.712.712 0 0 1-.365-.094 1.065 1.065 0 0 1-.305-.327 3.627 3.627 0 0 1-.335-.71 3.068 3.068 0 0 0-2.016-2.058 4.151 4.151 0 0 0 2.45-.649 2.3 2.3 0 0 0 .9-1.931q.06-2.7-3.748-2.783L79.683 0l-.027 1.233q.747.079.989.123a.515.515 0 0 1 .3.124.5.5 0 0 1 .055.3l-.144 6.568a.5.5 0 0 1-.069.295.511.511 0 0 1-.307.111c-.163.023-.493.049-.993.08l-.027 1.232 4.821.106Zm-1.2-7.363 2.106.046a1.964 1.964 0 0 1 1.241.347 1.19 1.19 0 0 1 .369.984 1.2 1.2 0 0 1-.483 1.027 2.181 2.181 0 0 1-1.311.307l-1.981-.044Z"
                fill={color}
            />
            <path
                data-name="Path 7"
                d="M92.156 9.488a2.356 2.356 0 0 0 .857 1.178 3.718 3.718 0 0 0 1.623.561 3.32 3.32 0 0 0 1.669-.157 2.81 2.81 0 0 0 1.25-.917 3.156 3.156 0 0 0 .619-1.527 2.574 2.574 0 0 0-.423-1.96 4.546 4.546 0 0 0-1.987-1.378l-1.5-.624a2.779 2.779 0 0 1-1.036-.636.866.866 0 0 1-.223-.71.913.913 0 0 1 .246-.509 1.145 1.145 0 0 1 .556-.307 2.075 2.075 0 0 1 .815-.026 2.547 2.547 0 0 1 2.162 2.089l1.3.185.477-3.353-1.406-.2-.554 1.183a2.289 2.289 0 0 0-.7-1.061 2.714 2.714 0 0 0-1.375-.527 3.393 3.393 0 0 0-1.669.157 2.913 2.913 0 0 0-1.268.884 2.735 2.735 0 0 0-.608 1.387q-.32 2.256 2.185 3.258l1.49.606a3.49 3.49 0 0 1 1.185.7.94.94 0 0 1 .286.813.973.973 0 0 1-.523.746 1.869 1.869 0 0 1-1.18.147 2.867 2.867 0 0 1-1.612-.741 3.466 3.466 0 0 1-.923-1.716l-1.236-.175-.523 3.677 1.375.2Z"
                fill={color}
            />
            <path
                data-name="Path 8"
                d="M101.057 3.463a1.705 1.705 0 0 1 .185.346l1.745 4.635-.608 2.373a.454.454 0 0 1-.141.27.656.656 0 0 1-.377.024q-.289-.034-1.182-.2l-.306 1.194 5.2 1.332.306-1.194q-.862-.286-1.132-.395a.646.646 0 0 1-.318-.2.458.458 0 0 1 .005-.3l.635-2.48 3.848-3.074a1.863 1.863 0 0 1 .352-.208 1.08 1.08 0 0 1 .362-.06q.227-.006.734.027l.306-1.194-4.051-1.042-.306 1.194q.525.215.681.3c.1.054.149.111.134.171a.268.268 0 0 1-.077.109 2.718 2.718 0 0 0-.088.09l-2.256 2.027-.907-2.874a.637.637 0 0 1-.052-.239c.01-.04.049-.065.116-.075a1.559 1.559 0 0 1 .357.019q.257.034.628.1l.306-1.194-4.717-1.207-.306 1.194a6.79 6.79 0 0 1 .645.31.979.979 0 0 1 .281.225"
                fill={color}
            />
            <path
                data-name="Path 9"
                d="M113.049 7.482a1.6 1.6 0 0 1 .3-.453.283.283 0 0 1 .28-.012l1.5.566-2.4 6.383a.452.452 0 0 1-.17.252.642.642 0 0 1-.377-.016q-.284-.066-1.153-.326l-.434 1.154 5.009 1.884.435-1.154q-.811-.371-1.067-.51t-.295-.236a.459.459 0 0 1 .039-.3l2.4-6.383 1.519.572a.266.266 0 0 1 .188.187 2.5 2.5 0 0 1-.106.7q-.133.574-.388 1.562l1.344.506 1.432-3.63-8.91-3.352-1.3 3.678 1.344.506q.6-1.207.8-1.582"
                fill={color}
            />
            <path
                data-name="Path 10"
                d="M123.499 10.898a.505.505 0 0 1-.084.292l-3.079 5.8a.5.5 0 0 1-.194.232.509.509 0 0 1-.324-.039q-.233-.08-.923-.375l-.578 1.089 7.844 4.163 1.688-3.381-1.268-.673q-.66.976-.926 1.329a1.677 1.677 0 0 1-.376.409.257.257 0 0 1-.247-.017l-2.936-1.558 1.367-2.577 2.272 1.311.834-1.572-2.359-1.146 1.185-2.233 2.826 1.5a.274.274 0 0 1 .166.2 1.529 1.529 0 0 1-.124.518q-.139.395-.555 1.445l1.282.681 1.767-3.2-7.72-4.1-.578 1.089q.632.408.828.554c.13.1.2.181.214.246"
                fill={color}
            />
            <path
                data-name="Path 11"
                d="M131.747 18.249q.877-1.025 1.161-1.342a1.58 1.58 0 0 1 .4-.366.284.284 0 0 1 .273.056l1.324.912-3.868 5.617a.459.459 0 0 1-.226.2.656.656 0 0 1-.362-.107q-.26-.131-1.041-.594l-.7 1.015 4.408 3.036.7-1.015q-.7-.556-.913-.752c-.144-.13-.22-.23-.229-.3a.451.451 0 0 1 .11-.284l3.868-5.617 1.337.92a.266.266 0 0 1 .137.227 2.462 2.462 0 0 1-.272.656q-.268.525-.754 1.424l1.183.814 2.264-3.178-7.841-5.4-2.148 3.257Z"
                fill={color}
            />
            <path
                data-name="Path 12"
                d="M84.32 135.896c2.3-.913 4.551-1.944 6.774-3.029 11.739-5.73 22.654-12.638 31.38-22.542 7.848-8.908 13.031-19.017 13.105-31.182.081-13.357-.013-26.715.031-40.073a3.872 3.872 0 0 0-1.507-3.35c-18.297-15.024-38.996-21.924-62.678-18.224a77.279 77.279 0 0 0-39.394 18.2 3.957 3.957 0 0 0-1.523 3.348c.072 6.971.032 13.944.032 20.916h-.017c0 6.386-.205 12.781.076 19.154a49.719 49.719 0 0 0 1.757 11.531c3.161 10.933 10.019 19.46 18.495 26.774 9.268 8 19.873 13.8 31.09 18.538a3.456 3.456 0 0 0 2.378-.061m-28.557-26.96a48.957 48.957 0 0 1-12.112-15.55h-8.92v-7.2l1.243-.1c1.515-.128 2.2-.219 2.5-.27 0-.081.007-.179.007-.3V69.755c0-.119 0-.217-.007-.3-.331-.056-1.029-.144-2.5-.269l-1.243-.105v-7.2h5.463q0-9.245-.029-18.489a3.378 3.378 0 0 1 1.536-3.1c21.755-15.575 44.948-18.611 69.576-7.737 4.606 2.034 8.834 4.956 13.119 7.655a3.984 3.984 0 0 1 1.457 2.891c.131 6.254.066 12.512.066 18.768v.011h5.9v7.2l-1.243.105c-1.47.124-2.169.212-2.5.269 0 .081-.007.179-.007.3V85.51c0 .118 0 .216.007.3.3.051.986.142 2.5.27l1.243.1v7.2h-9.381a47 47 0 0 1-7.412 10.756c-8.555 9.53-19.349 15.934-30.853 21.231a3.218 3.218 0 0 1-2.359-.068 99.142 99.142 0 0 1-26.049-16.369"
                fill={color}
            />
            <path
                data-name="Path 13"
                d="M60.395 64.024a7.5 7.5 0 0 1 3.278 6.488 7 7 0 0 1-1.709 4.834 7.375 7.375 0 0 1-1.339 1.165 8.223 8.223 0 0 1 2.2 1.77 7.538 7.538 0 0 1 1.25 2.02q-.064-.959-.065-1.979a20.109 20.109 0 0 1 1.734-8.564 13.9 13.9 0 0 1 4.829-5.821 12.213 12.213 0 0 1 3.194-1.523v-7.027a8.039 8.039 0 0 1 .382-3.022 2.53 2.53 0 0 1 1.855-1.165 58.145 58.145 0 0 1 6.053-.811V39.773h-35.99V50.39a58.161 58.161 0 0 1 6.053.811 2.527 2.527 0 0 1 1.854 1.165 8.041 8.041 0 0 1 .382 3.022v6.63a12.662 12.662 0 0 1 6.039 2.006"
                fill={color}
            />
            <path
                data-name="Path 14"
                d="M102.874 85.828c.347.051 1.022.131 2.336.25l.224.02-2.56-6.046Z"
                fill={color}
            />
            <path
                data-name="Path 15"
                d="M109.419 93.384h-.9l-.351-.828-1.723-4.069v4.9H93.704a13.947 13.947 0 0 1-1.34 3.855 7.941 7.941 0 0 1-7.362 3.954c-4.4 0-7.511-2.6-9.325-6.7a14 14 0 0 1-7.548-3.771 13.625 13.625 0 0 1-3.365-6.193 8.1 8.1 0 0 1-3.361 6.485 12.916 12.916 0 0 1-6.337 2.2 38.082 38.082 0 0 0 2.18 7.763 24.2 24.2 0 0 0 9.324 11.39q6.434 4.241 16.8 4.244a29.7 29.7 0 0 0 15.214-3.719 24.323 24.323 0 0 0 9.815-11.044 34.634 34.634 0 0 0 2.636-8.466h-1.614Z"
                fill={color}
            />
            <path
                data-name="Path 16"
                d="M86.965 39.773V50.39q4.255.462 5.671.754c.945.195 1.527.484 1.745.873a5.041 5.041 0 0 1 .327 2.208v7.655h11.123l.34.856 4.387 11.038 1.273-3.081V54.226a3.943 3.943 0 0 1 .436-2.149 3.073 3.073 0 0 1 2.017-.933q1.58-.288 5.835-.754V39.773Z"
                fill={color}
            />
            <path
                data-name="Path 17"
                d="M57.003 76.628a7.752 7.752 0 0 0 3.95-2.187 5.661 5.661 0 0 0 1.361-3.928 6.185 6.185 0 0 0-2.7-5.378 11.356 11.356 0 0 0-5.26-1.744 23.945 23.945 0 0 0-2.752-.153H35.847v4.6q2.1.178 2.767.311c.447.089.721.239.826.447a2.92 2.92 0 0 1 .156 1.16v15.756a2.918 2.918 0 0 1-.156 1.16c-.1.208-.379.357-.826.446s-1.369.194-2.767.312v4.6H51.96a25.782 25.782 0 0 0 2.9-.157 11.767 11.767 0 0 0 5.734-1.942 6.858 6.858 0 0 0 2.834-5.847 7.217 7.217 0 0 0-1.629-4.909 8.442 8.442 0 0 0-4.8-2.545m-9.507-7.81h3.08a4.1 4.1 0 0 1 2.455.625 2.181 2.181 0 0 1 .848 1.874 2.127 2.127 0 0 1-.892 1.9 5.029 5.029 0 0 1-2.767.6h-2.723Zm6.868 16.2a2.589 2.589 0 0 1-.419.449 5.27 5.27 0 0 1-3.37.893h-3.08v-6.878h2.818q2.935 0 4.046 1.2a2.713 2.713 0 0 1 .684 1.921 3.887 3.887 0 0 1-.675 2.406"
                fill={color}
            />
            <path
                data-name="Path 18"
                d="M90.181 67.835V63.37h-5.933l-1.16 3.393h-.446a4.953 4.953 0 0 0-1.853-2.589 5.756 5.756 0 0 0-3.414-.937 10.769 10.769 0 0 0-3.606.61 10.911 10.911 0 0 0-2.442 1.22 12.5 12.5 0 0 0-4.352 5.267 18.686 18.686 0 0 0-1.606 7.989q0 7.678 3.727 11.449a12.249 12.249 0 0 0 6 3.233 21.185 21.185 0 0 0 4.96.538 13.772 13.772 0 0 0 5.021-.892 8.1 8.1 0 0 0 3.66-2.723 7.34 7.34 0 0 0 1.362-4.508 11.017 11.017 0 0 0-.313-2.588h-.053a12.192 12.192 0 0 1-8.213 3.348 8.055 8.055 0 0 1-5.668-1.853q-2.008-1.851-2.008-6a13.687 13.687 0 0 1 .714-4.709 6.423 6.423 0 0 1 1.964-2.923 4.437 4.437 0 0 1 2.812-.982 3.436 3.436 0 0 1 2.678 1.2 9.787 9.787 0 0 1 1.785 4.018h6.383v-5.687Z"
                fill={color}
            />
            <path
                data-name="Path 19"
                d="M95.226 68.46v17.541a1.479 1.479 0 0 1-.156.826.944.944 0 0 1-.491.255c-.075.021-.155.04-.246.057q-.58.112-2.455.291v4.6h13.212v-4.6q-1.965-.178-2.611-.291a1.365 1.365 0 0 1-.8-.311 1.47 1.47 0 0 1-.156-.826V73.369l7.9 18.658h.893l1.308-2.947 6.95-15.665v12.1a2.924 2.924 0 0 1-.156 1.16c-.1.209-.379.357-.826.446s-1.369.194-2.767.312v4.6h15.4v-4.6q-2.1-.178-2.767-.312t-.825-.446a2.9 2.9 0 0 1-.156-1.16V69.754a2.9 2.9 0 0 1 .156-1.16q.156-.313.825-.447t2.767-.311v-4.6h-13.843l-4.549 11.009-1.3 3.141-5.624-14.149h-13.03v4.6l2.828.528Z"
                fill={color}
            />
            <path
                data-name="Path 20"
                d="M20.962 113.914c-3.841-3.87-3.061-4.761-5.615-8.511-2.9-4.254-8.992-5.035-12.888-5.217a44.252 44.252 0 0 0 7.275 10.621c3.45 3.8 8.586 4.2 11.228 3.107"
                fill={color}
            />
            <path
                data-name="Path 21"
                d="M19.658 56.994c1.663-4.522.655-8.809-.452-12.288-1.472 5.05-5.16 6.378-6.958 9.84-2.215 4.265-.894 8.9.923 10.867 1.472-5.05 4.9-4.107 6.487-8.418"
                fill={color}
            />
            <path
                data-name="Path 22"
                d="M11.111 79.641c-1.336-5.287-.21-5.657-.5-10.184-.329-5.136-5.176-8.911-8.436-11.051a44.256 44.256 0 0 0 .854 12.845c1.034 5.028 5.253 7.985 8.082 8.39"
                fill={color}
            />
            <path
                data-name="Path 23"
                d="M14.37 69.727c-3.069 3.728-3.392 7.729-2.4 9.907 3.85-2.691 5.441-2.479 7.99-6.354 2.674-4.065 3.021-8.339 3.058-11.854a47.532 47.532 0 0 0-8.645 8.3"
                fill={color}
            />
            <path
                data-name="Path 24"
                d="M13.6 97.324c-2.522-4.834-1.512-5.454-2.841-9.792-1.508-4.921-7.1-7.473-10.762-8.8a44.238 44.238 0 0 0 3.8 12.3c2.169 4.653 6.956 6.555 9.8 6.295"
                fill={color}
            />
            <path
                data-name="Path 25"
                d="M20.975 76.85a47.536 47.536 0 0 0-6.492 10.075c-2.124 4.336-1.513 8.3-.048 10.194 3.123-3.508 4.72-3.67 6.3-8.029 1.662-4.573 1.011-8.811.235-12.24"
                fill={color}
            />
            <path
                data-name="Path 26"
                d="M21.696 113.472c1.944-4.276 3.421-4.9 3.642-9.537.232-4.86-1.645-8.715-3.4-11.76a47.54 47.54 0 0 0-3.215 11.546c-.744 4.771 1.016 8.379 2.976 9.751"
                fill={color}
            />
            <path
                data-name="Path 27"
                d="M10.602 119.313a44.259 44.259 0 0 0 9.109 9.1c4.092 3.1 9.213 2.543 11.608.982-4.489-3.1-3.886-4.115-7.089-7.329-3.633-3.646-9.766-3.29-13.629-2.751"
                fill={color}
            />
            <path
                data-name="Path 28"
                d="M31.96 128.823c1.122-4.561 2.458-5.451 1.821-10.045-.668-4.819-3.224-8.262-5.513-10.93a47.528 47.528 0 0 0-1.03 11.941c.149 4.827 2.544 8.048 4.723 9.035"
                fill={color}
            />
            <path
                data-name="Path 29"
                d="M44.993 139.483c-5.154-1.78-4.847-2.924-8.792-5.163-4.477-2.54-10.29-.555-13.867 1a44.25 44.25 0 0 0 11.212 6.325c4.772 1.89 9.558-.017 11.447-2.162"
                fill={color}
            />
            <path
                data-name="Path 30"
                d="M44.522 128.597c-1.934-4.465-5.32-7.1-8.239-9.055a47.535 47.535 0 0 0 2.206 11.781c1.436 4.61 4.606 7.073 6.969 7.44-.141-4.7.908-5.909-.936-10.166"
                fill={color}
            />
            <path
                data-name="Path 31"
                d="M61.356 146.148c-5.449-.187-5.492-1.371-9.921-2.352-5.025-1.113-10 2.493-12.961 5.03a44.255 44.255 0 0 0 12.576 2.751c5.117.4 9.131-2.825 10.307-5.43"
                fill={color}
            />
            <path
                data-name="Path 32"
                d="M57.706 135.881c-3.161-3.7-7.17-5.221-10.536-6.234a47.545 47.545 0 0 0 5.569 10.613c2.728 3.985 6.481 5.408 8.848 5.063-1.514-4.446-.868-5.915-3.882-9.442"
                fill={color}
            />
            <path
                data-name="Path 33"
                d="M79.184 148.637c-5.34 1.1-5.66-.04-10.2.05-5.146.1-9.131 4.777-11.413 7.94a44.25 44.25 0 0 0 12.87-.286c5.069-.811 8.21-4.895 8.739-7.7"
                fill={color}
            />
            <path
                data-name="Path 34"
                d="M69.425 144.942c3.589 3.231 7.572 3.73 9.792 2.838-2.518-3.965-2.237-5.545-6-8.263-3.942-2.851-8.2-3.386-11.708-3.579a47.546 47.546 0 0 0 7.911 9"
                fill={color}
            />
            <path
                data-name="Path 35"
                d="M152.895 65.415c1.818-1.964 3.138-6.6.923-10.867-1.8-3.461-5.486-4.789-6.958-9.84-1.107 3.479-2.116 7.766-.452 12.288 1.585 4.311 5.016 3.368 6.487 8.418"
                fill={color}
            />
            <path
                data-name="Path 36"
                d="M154.955 79.641c2.83-.4 7.048-3.362 8.082-8.39a44.26 44.26 0 0 0 .854-12.845c-3.26 2.14-8.107 5.915-8.436 11.051-.29 4.527.836 4.9-.5 10.184"
                fill={color}
            />
            <path
                data-name="Path 37"
                d="M154.098 79.635c.989-2.178.666-6.179-2.4-9.907a47.531 47.531 0 0 0-8.645-8.3c.037 3.515.384 7.789 3.058 11.854 2.55 3.875 4.14 3.663 7.99 6.354"
                fill={color}
            />
            <path
                data-name="Path 38"
                d="M162.265 91.03a44.251 44.251 0 0 0 3.8-12.3c-3.667 1.329-9.255 3.88-10.762 8.8-1.329 4.338-.319 4.958-2.841 9.793 2.846.26 7.634-1.642 9.8-6.295"
                fill={color}
            />
            <path
                data-name="Path 39"
                d="M145.091 76.85c-.776 3.429-1.427 7.667.235 12.24 1.585 4.36 3.181 4.521 6.3 8.029 1.465-1.891 2.076-5.858-.048-10.194a47.542 47.542 0 0 0-6.492-10.075"
                fill={color}
            />
            <path
                data-name="Path 40"
                d="M150.718 105.403c-2.554 3.749-1.774 4.641-5.615 8.511 2.641 1.092 7.778.694 11.228-3.107a44.246 44.246 0 0 0 7.275-10.621c-3.9.182-9.989.963-12.887 5.217"
                fill={color}
            />
            <path
                data-name="Path 41"
                d="M144.369 113.472c1.96-1.371 3.719-4.98 2.975-9.751a47.542 47.542 0 0 0-3.214-11.546c-1.757 3.045-3.635 6.9-3.4 11.76.221 4.633 1.7 5.261 3.642 9.537"
                fill={color}
            />
            <path
                data-name="Path 42"
                d="M141.835 122.064c-3.2 3.214-2.6 4.234-7.089 7.329 2.395 1.561 7.516 2.117 11.608-.982a44.246 44.246 0 0 0 9.109-9.1c-3.863-.54-10-.9-13.629 2.751"
                fill={color}
            />
            <path
                data-name="Path 43"
                d="M132.285 118.778c-.637 4.595.7 5.484 1.821 10.045 2.179-.987 4.574-4.209 4.723-9.035a47.541 47.541 0 0 0-1.03-11.941c-2.289 2.668-4.845 6.111-5.513 10.931"
                fill={color}
            />
            <path
                data-name="Path 44"
                d="M132.518 141.645a44.243 44.243 0 0 0 11.212-6.325c-3.577-1.554-9.391-3.539-13.867-1-3.946 2.239-3.638 3.383-8.793 5.163 1.889 2.145 6.675 4.052 11.447 2.162"
                fill={color}
            />
            <path
                data-name="Path 45"
                d="M127.577 131.323a47.535 47.535 0 0 0 2.205-11.781c-2.92 1.958-6.3 4.59-8.239 9.055-1.844 4.256-.8 5.471-.936 10.166 2.364-.367 5.534-2.83 6.97-7.44"
                fill={color}
            />
            <path
                data-name="Path 46"
                d="M115.016 151.578a44.255 44.255 0 0 0 12.576-2.751c-2.962-2.537-7.936-6.143-12.961-5.03-4.429.981-4.472 2.165-9.921 2.352 1.176 2.605 5.19 5.835 10.307 5.43"
                fill={color}
            />
            <path
                data-name="Path 47"
                d="M113.329 140.26a47.539 47.539 0 0 0 5.57-10.613c-3.366 1.013-7.375 2.535-10.536 6.234-3.013 3.527-2.368 5-3.882 9.442 2.367.344 6.121-1.079 8.848-5.064"
                fill={color}
            />
            <path
                data-name="Path 48"
                d="M95.62 156.341a44.264 44.264 0 0 0 12.87.286c-2.281-3.163-6.267-7.838-11.413-7.94-4.536-.09-4.856 1.051-10.2-.05.529 2.809 3.67 6.892 8.739 7.7"
                fill={color}
            />
            <path
                data-name="Path 49"
                d="M86.85 147.781c2.22.891 6.2.392 9.792-2.838a47.543 47.543 0 0 0 7.911-9c-3.51.193-7.765.727-11.708 3.579-3.759 2.718-3.477 4.3-6 8.263"
                fill={color}
            />
            <path
                data-name="Path 50"
                d="M12.898 142.511a3.46 3.46 0 0 0-.357-1.994 2.218 2.218 0 0 0 3.024.3 2.158 2.158 0 0 0 .959-1.91 5.111 5.111 0 0 0-1.167-2.69l-3.05-4.137-1.018.751q.4.657.52.88a.528.528 0 0 1 .089.322.516.516 0 0 1-.207.232l-5.425 4a.514.514 0 0 1-.283.129.524.524 0 0 1-.282-.18q-.18-.176-.687-.757l-1.018.75 3.287 4.459a5.546 5.546 0 0 0 1.553 1.5 2.793 2.793 0 0 0 1.582.445 2.69 2.69 0 0 0 1.487-.559 2.331 2.331 0 0 0 .994-1.539m-2.156-.469a1.431 1.431 0 0 1-2.255-.387l-1.121-1.521 2.526-1.862 1.016 1.379a2.127 2.127 0 0 1 .5 1.312 1.415 1.415 0 0 1-.667 1.078m1.256-3.531-.855-1.16 2.036-1.5.9 1.224a1.817 1.817 0 0 1 .454 1.157 1.165 1.165 0 0 1-.529.847 1.137 1.137 0 0 1-1.028.251 2.072 2.072 0 0 1-.981-.818"
                fill={color}
            />
            <path
                data-name="Path 51"
                d="M17.232 150.29a2.591 2.591 0 0 1-1.05-.271 3.788 3.788 0 0 1-1.1-.8 2.647 2.647 0 0 1-.824-1.423 1.746 1.746 0 0 1 .466-1.365l3.307 3.372a5.743 5.743 0 0 0 .525-.47 4.9 4.9 0 0 0 1.137-1.642 3.141 3.141 0 0 0 .215-1.735 2.907 2.907 0 0 0-.84-1.565 3.171 3.171 0 0 0-1.79-.969 3.746 3.746 0 0 0-1.989.235 5.349 5.349 0 0 0-1.795 1.177 4.1 4.1 0 0 0-1.1 1.733 3.148 3.148 0 0 0-.012 1.84 4.077 4.077 0 0 0 1.042 1.7 3.426 3.426 0 0 0 1.362.908 2.358 2.358 0 0 0 1.318.064 2.964 2.964 0 0 0 1.155-.583Zm-.244-5.165a1.257 1.257 0 0 1 .891.382 1.038 1.038 0 0 1 .309.818 1.852 1.852 0 0 1-.52 1.048l-1.852-1.885a2.192 2.192 0 0 1 1.17-.363"
                fill={color}
            />
            <path
                data-name="Path 52"
                d="M20.279 154.258q-.153-.179-.139-.283a.708.708 0 0 1 .178-.3l2.246-2.692a4.555 4.555 0 0 1 1.6.9 3.72 3.72 0 0 1 .436.427l1.354-1.622a2.87 2.87 0 0 0-.391-.431 1.483 1.483 0 0 0-1.09-.368 4.179 4.179 0 0 0-1.417.423v-.205l.991-1.113q-.336-.2-.925-.615t-1.433-1.081l-.892 1.07q.3.314.461.51a.618.618 0 0 1 .161.27.313.313 0 0 1-.091.184l-2.554 3.061a.815.815 0 0 1-.257.233.319.319 0 0 1-.254-.045 6.7 6.7 0 0 1-.61-.394l-.79.946 3.306 2.76.79-.946q-.535-.509-.687-.689"
                fill={color}
            />
            <path
                data-name="Path 53"
                d="M25.137 157.982a.319.319 0 0 1-.105-.236.811.811 0 0 1 .164-.306l5.164-7.073a7.731 7.731 0 0 1-.9-.5q-.552-.344-1.667-1.1l-.821 1.125q.32.293.491.467t.176.247a.344.344 0 0 1-.089.2l-3.974 5.444a.972.972 0 0 1-.235.254.29.29 0 0 1-.25-.024 6.687 6.687 0 0 1-.634-.353l-.727 1 3.207 2.341.727-1a6.759 6.759 0 0 1-.53-.5"
                fill={color}
            />
            <path
                data-name="Path 54"
                d="M28.941 160.366a.948.948 0 0 1 .154-.311l3.233-4.983a5.275 5.275 0 0 1-.965-.425q-.594-.319-1.7-1.02l-.723 1.115q.336.276.519.451c.121.117.184.2.187.255a.424.424 0 0 1-.091.228l-2.135 3.291a.81.81 0 0 1-.228.262.319.319 0 0 1-.258-.015 6.625 6.625 0 0 1-.652-.318l-.671 1.034 3.331 2.161.67-1.034a6.731 6.731 0 0 1-.556-.466c-.084-.086-.121-.161-.111-.225"
                fill={color}
            />
            <path
                data-name="Path 55"
                d="M32.826 151.922a1.321 1.321 0 0 0-1-.216 1.237 1.237 0 0 0-.825.581 1.225 1.225 0 0 0-.2.979 1.485 1.485 0 0 0 1.611 1.046 1.223 1.223 0 0 0 .814-.578 1.237 1.237 0 0 0 .194-.99 1.322 1.322 0 0 0-.6-.821"
                fill={color}
            />
            <path
                data-name="Path 56"
                d="M38.244 158.394a2.341 2.341 0 0 0-1.485-.3 3.283 3.283 0 0 0-1.448.649l-.036-.238.746-1.289q-.369-.125-1.03-.417t-1.621-.77l-.662 1.23q.358.247.554.406c.131.107.2.184.212.232a.311.311 0 0 1-.052.2l-1.886 3.512a.81.81 0 0 1-.205.28.32.32 0 0 1-.258.007 6.71 6.71 0 0 1-.676-.264l-.583 1.086 3.5 1.878.583-1.086a6.659 6.659 0 0 1-.593-.418q-.136-.118-.13-.215a.954.954 0 0 1 .128-.322l1.734-3.23a2.486 2.486 0 0 1 .754.068 2.8 2.8 0 0 1 .677.245 1.5 1.5 0 0 1 .766.774 1.3 1.3 0 0 1-.167 1.055l-2.143 3.992 2.624 1.409.583-1.086a6.735 6.735 0 0 1-.593-.418c-.091-.079-.134-.151-.129-.215a.955.955 0 0 1 .128-.322l1.673-3.117a2.479 2.479 0 0 0 .33-1.341 2.3 2.3 0 0 0-.393-1.165 2.511 2.511 0 0 0-.9-.811"
                fill={color}
            />
            <path
                data-name="Rectangle 1"
                fill={color}
                d="m40.035 164.281.738-1.686 3.447 1.508-.739 1.687z"
            />
            <path
                data-name="Path 57"
                d="M51.244 169.86a3.766 3.766 0 0 1-1.874-.185 2.738 2.738 0 0 1-1.792-1.537 3.862 3.862 0 0 1 .127-2.69 4.6 4.6 0 0 1 1.488-2.317 2.141 2.141 0 0 1 2.057-.276 1.892 1.892 0 0 1 .871.569 2.287 2.287 0 0 1 .443.981 8.754 8.754 0 0 1 .159 1.561l1.3.446 1.365-3.983-1.408-.483-.87 1.056a1.991 1.991 0 0 0-.486-1.2 2.707 2.707 0 0 0-1.218-.79 3.979 3.979 0 0 0-2.316-.108 4.472 4.472 0 0 0-2.1 1.253 6.593 6.593 0 0 0-1.487 2.537 6.661 6.661 0 0 0-.395 2.953 4.113 4.113 0 0 0 .989 2.277 5.055 5.055 0 0 0 2.221 1.421 4.752 4.752 0 0 0 1.98.273 2.944 2.944 0 0 0 2.638-2.142l-.115-.208a4.253 4.253 0 0 1-1.582.591"
                fill={color}
            />
            <path
                data-name="Path 58"
                d="M62.474 169.446a2.482 2.482 0 0 0-.032-1.38 2.315 2.315 0 0 0-.675-1.02 2.572 2.572 0 0 0-2.574-.452 3.333 3.333 0 0 0-1.247.975l1-4.3a7.691 7.691 0 0 1-1.019-.106q-.642-.1-1.963-.359l-.316 1.356q.41.145.635.239a.569.569 0 0 1 .259.159.347.347 0 0 1 0 .221l-1.53 6.565a.965.965 0 0 1-.118.326.289.289 0 0 1-.24.076 6.62 6.62 0 0 1-.722-.078l-.279 1.2 3.866.9.28-1.2a6.726 6.726 0 0 1-.682-.25.321.321 0 0 1-.189-.176.82.82 0 0 1 .032-.346l.824-3.539a2.145 2.145 0 0 1 .759-.152 2.885 2.885 0 0 1 .742.058 1.5 1.5 0 0 1 .941.548 1.3 1.3 0 0 1 .114 1.062l-1.028 4.413 2.916.68.28-1.2a6.711 6.711 0 0 1-.682-.25q-.162-.079-.189-.176a.816.816 0 0 1 .031-.346Z"
                fill={color}
            />
            <path
                data-name="Path 59"
                d="M69.757 168.584a2.912 2.912 0 0 0-1.617-.735 3.168 3.168 0 0 0-2.011.316 3.747 3.747 0 0 0-1.439 1.394 5.35 5.35 0 0 0-.713 2.024 4.107 4.107 0 0 0 .173 2.047 3.148 3.148 0 0 0 1.106 1.47 4.07 4.07 0 0 0 1.861.722 3.415 3.415 0 0 0 1.633-.1 2.355 2.355 0 0 0 1.087-.749 2.97 2.97 0 0 0 .564-1.164l-.14-.15a2.6 2.6 0 0 1-1 .422 3.781 3.781 0 0 1-1.363.03 2.649 2.649 0 0 1-1.518-.632 1.748 1.748 0 0 1-.458-1.368l4.675.675a5.77 5.77 0 0 0 .132-.692 4.909 4.909 0 0 0-.092-1.995 3.135 3.135 0 0 0-.882-1.51m-.917 2.488-2.615-.378a2.194 2.194 0 0 1 .71-1 1.257 1.257 0 0 1 .94-.236 1.037 1.037 0 0 1 .742.463 1.853 1.853 0 0 1 .223 1.148"
                fill={color}
            />
            <path
                data-name="Path 60"
                d="M83.916 175.349a.81.81 0 0 1-.034-.346l.136-3.488a2.7 2.7 0 0 0-.274-1.381 2.327 2.327 0 0 0-3.414-.783 2.864 2.864 0 0 0-.909 1.343 2.606 2.606 0 0 0-.44-1.051 2.161 2.161 0 0 0-.759-.663 2.187 2.187 0 0 0-.92-.244 2.39 2.39 0 0 0-2.4 1.588l-.121-.181.106-1.486q-.387.05-1.109.077t-1.8.018l-.054 1.392q.43.065.676.122a.627.627 0 0 1 .292.115.316.316 0 0 1 .04.2l-.156 3.984a.818.818 0 0 1-.061.342.32.32 0 0 1-.229.119 6.761 6.761 0 0 1-.723.06l-.048 1.232 3.967.155.049-1.232a6.73 6.73 0 0 1-.717-.116q-.175-.047-.211-.136a.967.967 0 0 1-.027-.346l.144-3.7a1.785 1.785 0 0 1 .627-.248 2.876 2.876 0 0 1 .715-.068 1.22 1.22 0 0 1 .9.348 1.342 1.342 0 0 1 .266.98l-.179 4.575 2.992.117.048-1.232a6.648 6.648 0 0 1-.716-.116c-.117-.031-.187-.076-.211-.136a.959.959 0 0 1-.027-.346l.13-3.344.009-.224a1.811 1.811 0 0 1 .606-.337 1.988 1.988 0 0 1 .725-.107 1.244 1.244 0 0 1 .916.348 1.343 1.343 0 0 1 .266.98l-.179 4.575 2.992.117.048-1.232a6.6 6.6 0 0 1-.716-.116.32.32 0 0 1-.219-.137"
                fill={color}
            />
            <path
                data-name="Path 61"
                d="M87.066 168.495a1.333 1.333 0 0 0 .944-.4 1.354 1.354 0 0 0-.094-1.863 1.44 1.44 0 0 0-1.9.087 1.238 1.238 0 0 0-.333.953 1.225 1.225 0 0 0 .41.911 1.331 1.331 0 0 0 .976.308"
                fill={color}
            />
            <path
                data-name="Path 62"
                d="M88.926 175.166a.288.288 0 0 1-.222-.118.961.961 0 0 1-.056-.342l-.272-5.934a5.27 5.27 0 0 1-1.032.216q-.67.087-1.98.162l.061 1.327q.434.028.684.065a.549.549 0 0 1 .3.1.425.425 0 0 1 .059.238l.179 3.919a.812.812 0 0 1-.032.346.319.319 0 0 1-.218.138 6.7 6.7 0 0 1-.716.121l.056 1.232 3.966-.181-.056-1.231a6.711 6.711 0 0 1-.724-.055"
                fill={color}
            />
            <path
                data-name="Path 63"
                d="M96.172 174.041a3.779 3.779 0 0 1-1.314.364 2.652 2.652 0 0 1-1.627-.239 1.747 1.747 0 0 1-.78-1.214l4.7-.5a5.767 5.767 0 0 0-.042-.7 4.9 4.9 0 0 0-.58-1.911 3.138 3.138 0 0 0-1.226-1.247 2.91 2.91 0 0 0-1.748-.314 3.169 3.169 0 0 0-1.871.8 3.737 3.737 0 0 0-1.051 1.7 5.34 5.34 0 0 0-.193 2.137 4.1 4.1 0 0 0 .672 1.941 3.149 3.149 0 0 0 1.434 1.153 4.073 4.073 0 0 0 1.981.241 3.423 3.423 0 0 0 1.557-.5 2.363 2.363 0 0 0 .869-.993 2.963 2.963 0 0 0 .261-1.267l-.173-.11a2.59 2.59 0 0 1-.865.655m-3.334-3.673a1.257 1.257 0 0 1 .853-.46 1.037 1.037 0 0 1 .833.266 1.855 1.855 0 0 1 .5 1.058l-2.627.278a2.192 2.192 0 0 1 .443-1.142"
                fill={color}
            />
            <path
                data-name="Path 64"
                d="m99.144 178.333 1.889-.349 2.625-15.675-1.874.346Z"
                fill={color}
            />
            <path
                data-name="Path 65"
                d="M119.008 169.077a.337.337 0 0 1-.476-.3l-1.968-6.447a.336.336 0 0 1 .228-.513q.218-.108.934-.394l-.369-1.209-3.981 1.216-.725 6.834-4.312-5.3-3.874 1.183.369 1.21 1.326-.154 2.086 6.829a.375.375 0 0 1 .016.246q-.035.078-.329.21t-.821.326l.37 1.21 4.211-1.286-.369-1.21q-.561.137-.894.2a.853.853 0 0 1-.413.017.34.34 0 0 1-.131-.211l-1.651-5.405 5.143 6.1.429-.131.972-8.165 1.674 5.481a.337.337 0 0 1-.228.513q-.218.108-.934.394l.369 1.21 4.716-1.441-.37-1.21q-.754.164-1 .2"
                fill={color}
            />
            <path
                data-name="Path 66"
                d="M126.874 164.552a2.59 2.59 0 0 1-.624.888 3.773 3.773 0 0 1-1.139.747 2.649 2.649 0 0 1-1.622.27 1.749 1.749 0 0 1-1.114-.917l4.321-1.907a5.8 5.8 0 0 0-.255-.658 4.916 4.916 0 0 0-1.136-1.643 3.138 3.138 0 0 0-1.548-.813 2.909 2.909 0 0 0-1.761.234 3.17 3.17 0 0 0-1.537 1.334 3.746 3.746 0 0 0-.481 1.945 5.35 5.35 0 0 0 .469 2.094 4.1 4.1 0 0 0 1.233 1.644 3.147 3.147 0 0 0 1.717.659 4.073 4.073 0 0 0 1.961-.375 3.424 3.424 0 0 0 1.329-.954 2.357 2.357 0 0 0 .524-1.211 2.973 2.973 0 0 0-.139-1.286Zm-4.92-1.592a1.257 1.257 0 0 1 .672-.7 1.037 1.037 0 0 1 .874 0 1.853 1.853 0 0 1 .8.856l-2.417 1.067a2.19 2.19 0 0 1 .073-1.222"
                fill={color}
            />
            <path
                data-name="Path 67"
                d="M135.81 160.85a.291.291 0 0 1-.251 0 .967.967 0 0 1-.2-.28l-1.74-3.081a2.48 2.48 0 0 0-.957-.995 2.3 2.3 0 0 0-1.194-.291 2.508 2.508 0 0 0-1.166.328 2.338 2.338 0 0 0-1.045 1.1 3.283 3.283 0 0 0-.225 1.571l-.22-.1-.69-1.32q-.3.245-.9.648t-1.518.958l.685 1.213q.4-.171.64-.251a.623.623 0 0 1 .309-.055.312.312 0 0 1 .14.151l1.961 3.471a.814.814 0 0 1 .127.323.321.321 0 0 1-.132.221 6.731 6.731 0 0 1-.584.431l.606 1.073 3.458-1.952-.606-1.073a6.782 6.782 0 0 1-.67.277.29.29 0 0 1-.252-.005.962.962 0 0 1-.2-.28l-1.8-3.192a2.492 2.492 0 0 1 .461-.6 2.792 2.792 0 0 1 .569-.441 1.5 1.5 0 0 1 1.063-.233 1.3 1.3 0 0 1 .8.705l2.228 3.945 2.593-1.464-.606-1.073a6.6 6.6 0 0 1-.671.277"
                fill={color}
            />
            <path
                data-name="Path 68"
                d="M143.107 155.142a1.261 1.261 0 0 1-.423.538.421.421 0 0 1-.321.1.472.472 0 0 1-.269-.211l-1.907-2.671a2.173 2.173 0 0 0-1.6-1.024 3.335 3.335 0 0 0-2.116.783 4.261 4.261 0 0 0-1.168 1.188 2.317 2.317 0 0 0-.38 1.314 2.383 2.383 0 0 0 .485 1.326l1.7-.876a1.652 1.652 0 0 1-.333-.893.942.942 0 0 1 .48-.747 1.075 1.075 0 0 1 .767-.262 1.046 1.046 0 0 1 .631.484l.288.4-1.173 1.388a6.262 6.262 0 0 0-.857 1.251 2.216 2.216 0 0 0-.248 1.072 1.9 1.9 0 0 0 .386 1.023 2.487 2.487 0 0 0 .867.788 1.811 1.811 0 0 0 1.022.2 2.181 2.181 0 0 0 1.07-.43 2.285 2.285 0 0 0 .909-1.151 2.717 2.717 0 0 0-.027-1.525l.22.06.112.156a1.331 1.331 0 0 0 .823.6 1.173 1.173 0 0 0 .907-.245 1.747 1.747 0 0 0 .724-1.618 2.148 2.148 0 0 0-.38-.987Zm-2.857 2.4a2.328 2.328 0 0 1-.592.59.919.919 0 0 1-.551.206.534.534 0 0 1-.428-.255.713.713 0 0 1-.129-.566 1.711 1.711 0 0 1 .338-.684l.843-1.133.8 1.12a1.815 1.815 0 0 1-.281.722"
                fill={color}
            />
            <path
                data-name="Path 69"
                d="M146.704 152.699a.425.425 0 0 1-.3.093.709.709 0 0 1-.268-.223l-2.3-2.647a4.558 4.558 0 0 1 1.145-1.44 3.671 3.671 0 0 1 .491-.362l-1.386-1.6a2.9 2.9 0 0 0-.488.317 1.488 1.488 0 0 0-.537 1.018 4.184 4.184 0 0 0 .192 1.466l-.2-.037-.94-1.155q-.248.3-.756.815t-1.3 1.242l.913 1.052q.358-.248.577-.374a.62.62 0 0 1 .292-.116.316.316 0 0 1 .168.12l2.614 3.01a.821.821 0 0 1 .189.291.321.321 0 0 1-.085.244 6.8 6.8 0 0 1-.486.539l.808.931 3.251-2.824-.808-.931q-.588.447-.789.568"
                fill={color}
            />
            <path
                data-name="Path 70"
                d="M144.72 144.88a1.486 1.486 0 0 0 1.351-1.366 1.354 1.354 0 0 0-1.332-1.306 1.439 1.439 0 0 0-1.339 1.355 1.238 1.238 0 0 0 .4.926 1.225 1.225 0 0 0 .919.391"
                fill={color}
            />
            <path
                data-name="Path 71"
                d="M151.532 148.086a.29.29 0 0 1-.243.064.974.974 0 0 1-.273-.214l-4.223-4.177a5.216 5.216 0 0 1-.612.858q-.433.518-1.345 1.463l.945.934q.338-.273.547-.416a.55.55 0 0 1 .288-.132.427.427 0 0 1 .2.135l2.795 2.762a.821.821 0 0 1 .211.276.321.321 0 0 1-.067.249 6.657 6.657 0 0 1-.444.574l.877.867 2.792-2.823-.877-.867a6.718 6.718 0 0 1-.569.451"
                fill={color}
            />
            <path
                data-name="Path 72"
                d="M157.89 140.563a.29.29 0 0 1-.235.088.963.963 0 0 1-.293-.184l-2.76-2.214a2.478 2.478 0 0 0-1.258-.568 2.294 2.294 0 0 0-1.217.174 2.5 2.5 0 0 0-.961.737 2.34 2.34 0 0 0-.563 1.406 3.284 3.284 0 0 0 .374 1.542l-.24-.008-1.131-.969q-.19.34-.6.937t-1.053 1.454l1.086.871q.308-.307.5-.471a.621.621 0 0 1 .267-.166.316.316 0 0 1 .186.088l3.11 2.495a.825.825 0 0 1 .239.253.321.321 0 0 1-.04.255 6.818 6.818 0 0 1-.382.617l.961.771 2.484-3.1-.962-.772a6.526 6.526 0 0 1-.52.507.287.287 0 0 1-.235.089.964.964 0 0 1-.294-.184l-2.86-2.294a2.506 2.506 0 0 1 .2-.729 2.8 2.8 0 0 1 .365-.621 1.5 1.5 0 0 1 .9-.611 1.3 1.3 0 0 1 1.007.356l3.534 2.835 1.863-2.323-.961-.771a6.715 6.715 0 0 1-.52.507"
                fill={color}
            />
            <path
                data-name="Path 73"
                d="M160.569 136.354a.287.287 0 0 1-.224.114.962.962 0 0 1-.312-.151l-5.015-3.184a5.31 5.31 0 0 1-.416.969q-.313.6-1 1.714l1.122.712q.272-.339.446-.523c.116-.122.2-.186.253-.19a.432.432 0 0 1 .229.088l3.311 2.1a.823.823 0 0 1 .265.225.322.322 0 0 1-.012.258 6.737 6.737 0 0 1-.312.655l1.041.66 2.128-3.352-1.041-.661a6.611 6.611 0 0 1-.461.561"
                fill={color}
            />
            <path
                data-name="Path 74"
                d="M153.67 132.239a1.238 1.238 0 0 0-.992-.185 1.44 1.44 0 0 0-1.021 1.609 1.239 1.239 0 0 0 .589.82 1.226 1.226 0 0 0 .982.187 1.487 1.487 0 0 0 1.03-1.622 1.226 1.226 0 0 0-.587-.808"
                fill={color}
            />
        </g>
    </svg>
)

export default Logo
