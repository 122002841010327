import axios from "./axios"

const userService = {
    userMe: () => () => {
        return axios.get('/api/v1/user/me').then(res => res.data)
    },
    updateMe: () => (data) => {
        return axios.put('/api/v1/user/me', data).then(res => res.data)
    },
    renewToken: () => {
        return axios.get('/api/v1/user/token').then(res => res.data)
    },

}

export default userService