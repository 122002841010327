import React, { useState } from 'react'
import Background from '../../components/Background'
import Container from '../../components/Container'
import FlexBox from '../../components/FlexBox'
import Title from '../../components/Title'
import exam from '../../assets/images/exam.jpg'
import Image from '../../components/Image'
import styled from 'styled-components'
import NewTitle from '../../components/NewTitle'
import SortAsc from "../../components/Icons/SortAsc";
import SortDesc from "../../components/Icons/SortDesc";
import Slider from "../../components/Slider";
import { slide1Data } from "../../FakeData/SliderData";
import Card from "../../components/Card";
import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router'
import examService from '../../services/examService'
import Pagination from '../../components/Pagination'
import Spiner from '../../components/Spiner'
import Link from '../../components/Link'
import useMediaQuery from '../../Hooks/MediaQueryHook'

const StudentsList = () => {
    const isMobile = useMediaQuery(`(max-width: 1200px`)
    const { examId } = useParams()
    const [currentPage, setCurrentPage] = useState(1)
    const [isSortAsc, setIsSortAsc] = useState(false)

    const [param, setParam] = useState({
        page: currentPage,
        perPage: 10,
        sort_by: 'userNamesConcated_asc'
    })


    const {
        data: dataStudents,
        isSuccess: isSuccessStudents,
        isLoading: isLoadingStudents
    } = useQuery(
        ['exam', examId, 'studentsList', param],
        examService.getAllStudentsByExamId(examId, param)
    )



    const handleSortAsc = () => {
        setIsSortAsc(true)
        setParam({
            page: currentPage,
            perPage: 10,
            sort_by: 'userNamesConcated_asc'
        })

    }
    const handleSortDesc = () => {
        setIsSortAsc(false)
        setParam({
            page: currentPage,
            perPage: 10,
            sort_by: 'userNamesConcated_desc'
        })
    }


    return (
        <>
            {
                isLoadingStudents && <Spiner />
            }
            {
                isSuccessStudents && (
                    <>
                        <Background bgColor={'primary'}>
                            <Container>
                                {/* <BigTitle color='yellow2'>{dataStudents?.exam?.issue?.name}</BigTitle> */}
                                <Title color='white'>Egzamin</Title>
                            </Container>
                        </Background>
                        <Background bgColor='aquamarine'>
                            <Container maxWidth='1460px'>
                                <StyledFlexBox2 gap={'50px'} alignItems='flex-start' justifyContnet='center' margin='0 0 38px 0'>
                                    <Desc>
                                        {
                                            isMobile ? (
                                                <>
                                                    <p>
                                                        Poniżej znajdziesz wszystkie wypełnione przez studentów egzaminy z tego zagadnienia. Na sprawdzenie tego egzaminu przewidzieliśmy 2 tygodnie. Masz możliwość zapisania wersji roboczej oceny i powrotu do niej w późniejszym, dogodnym dla Ciebie czasie.
                                                    </p>

                                                </>
                                            ) : (
                                                <>
                                                    <p>Poniżej znajdziesz wszystkie wypełnione przez studentów egzaminy z tego </p>
                                                    <p>zagadnienia. Na sprawdzenie tego egzaminu przewidzieliśmy 2 tygodnie.</p>
                                                    <p>Masz możliwość zapisania wersji roboczej oceny i powrotu do niej</p>
                                                    <p>w późniejszym, dogodnym dla Ciebie czasie. </p>
                                                </>
                                            )
                                        }
                                        <FlexBox margin='30px 0 0 0'>Termin sprawdzenia egzaminów upływa:</FlexBox>
                                        <NewTitle
                                            variant='h3'
                                            color='primary'
                                            style={{ textAlign: 'left', marginTop: '5px' }}
                                        >
                                            {dataStudents.exam.lecturerDeadlineText}
                                        </NewTitle>
                                    </Desc>
                                    <Image src={exam} alt='exam' />
                                </StyledFlexBox2>
                            </Container>
                        </Background>
                        <Container maxWidth='1460px'>
                            <div>
                                <NewTitle variant='h3' color='primary'>Status ocen z egzaminu</NewTitle>
                            </div>
                        </Container>
                        <Container disablePaddingTop maxWidth='1150px'>
                            <Legend>
                                <Sorter>
                                    <SorterIcons >
                                        <SortAsc isActive={isSortAsc} className='icon' onClick={handleSortAsc} />
                                        <SortDesc isActive={!isSortAsc} className='icon' onClick={handleSortDesc} />
                                    </SorterIcons>
                                    Imię i nazwisko
                                </Sorter>
                            </Legend>

                        </Container>
                        {
                            dataStudents.studentExams.map((student, index) => (
                                <Background key={index} bgColor={index % 2 === 0 ? 'aquamarine' : 'white'}>
                                    <Container disablePaddingTop maxWidth='1150px'>
                                        <StyledFlexBox alignItems={'center'} flexWrap={'wrap'} gap={'20px'} padding={'25px 40px'}>
                                            <FlexBox flex={1} whiteSpace='nowrap' >
                                                {`${student.user.namesConcated}`}
                                            </FlexBox>
                                            <FlexBox flex={2} whiteSpace='nowrap' justifyContnet={'center'}>
                                                <Link
                                                    // to={`/my-studies/exams/${examId}/students/${student.user.id}`}
                                                    to={`/my-studies/lecturer-exams/${examId}/students/${student.user.id}`}
                                                >
                                                    Zobacz
                                            
                                                </Link>
                                            </FlexBox>
                                            <FlexBox flex={2} justifyContnet={'flex-end'} alignItems={'center'} gap={'10px'} whiteSpace='nowrap'>
                                                <div>średnia</div>
                                                <Average>
                                                    {`${student.percentageResult} %`}
                                                </Average>
                                            </FlexBox>
                                        </StyledFlexBox>
                                    </Container>
                                </Background>
                            ))
                        }
                        <FlexBox justifyContnet={'center'} margin={'50px 0px'}>
                            <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} pages={dataStudents.total} perPage={10} />
                        </FlexBox>
                    </>
                )
            }

            <Slider
                data={slide1Data}
                component={<Card />}
                breakpoints={{
                    1400: {
                        slidesPerView: 3
                    },
                    900: {
                        slidesPerView: 2
                    },
                }}
                isCenter={true}
                bgColor={'lightGray'}
            />
        </>
    )
}

export default StudentsList

const Desc = styled.div`
  font-size: 20px;
  line-height: 32px;
  color: ${props => props.theme.colors.balck};
`

const Sorter = styled.div`
    display: flex;
    align-items: center;
    gap: 15px;
`

const SorterIcons = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
    .icon {
        cursor: pointer;
    }
`

const Legend = styled.div`
    display: flex;
    align-items: center;
    gap: 55px;
    height: 75px;
`

const Average = styled.div`
    font-size: 24px;
    color: ${props => props.theme.colors.yellowGreen};
    font-family: 'Merriweather', serif;
    font-weight: bold;
`

const StyledFlexBox = styled(FlexBox)`
    @media only screen and (max-width: 500px) {
        flex-direction: column;
    }
`

const StyledFlexBox2 = styled(FlexBox)`
    @media only screen and (max-width: ${props => props.theme.mobile2}) {
        flex-direction: column;
        align-items: center;
    }
`



