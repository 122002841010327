import axios from "./axios"
import { parseParams } from "../helpers/helpers"

const libraryService = {
    getMyTaskAttachments: (params) => () => {

        return axios.get(`/api/v1/task-attachments/mine${params ? parseParams(params) : ''}`).then(res => res.data)
    },
}

export default libraryService