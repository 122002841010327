import React from 'react'
import styled from 'styled-components'
import Container from './Container'
import TextArea from '../components/TextArea'
import Button from '../components/Button'
import GoogleDrive from './Icons/GoogleDrive'
import Checkbox from './Checkbox'
import FlexBox from './FlexBox'
import Background from './Background'
import Select from '../components/Select'
import NewTitle from './NewTitle'
import RadioBox from './RadioBox'


const TestQuestion = ({ isFinishedByStudent, isChecked = false, isLecturerView, number, data, register }) => {
    const { myQuestionUploadDriveLink, type, question, examClosedAnswers, myAnswer } = data


    const wasChecked = (id) => {
        return true
        // return myAnswer.closedAnswers.includes(id.toString())
    }

    const check = (answer) => {
        if (wasChecked(answer.id) && answer.points) {
            return 'good'
        }
        else if (wasChecked(answer.id) && !answer.points) {
            return 'wrong'
        }
        else if (!wasChecked(answer.id) && answer.points) {
            return 'good'
        }
        else if (!wasChecked(answer.id) && !answer.points) {
            return 'disabled'
        }
    }

    const check2 = (answer) => {
        if (wasChecked(answer.id) && answer.pointsAfterAssessed
        ) {
            return 'good'
        }
        else if (wasChecked(answer.id) && !answer.pointsAfterAssessed
        ) {
            return 'wrong'
        }
        else if (!wasChecked(answer.id) && answer.pointsAfterAssessed
        ) {
            return 'good'
        }
        else if (!wasChecked(answer.id) && !answer.pointsAfterAssessed
        ) {
            return 'disabled'
        }
    }


    return (
        <div>
            <Background bgColor={'aquamarine'}>
                <Container maxWidth='1170px'>
                    <FlexBox alignItems={'center'} justifyContnet={'space-between'}>
                        Pytanie {number}
                        {
                            isLecturerView && (
                                <SinglResult>
                                    {
                                        type === 'CLOSED' ? (
                                            <div>
                                                {data.myAnswer.points}/max {data.points} pkt.
                                            </div>
                                        ) : (
                                            <FlexBox gap='10px' alignItems={'center'}>
                                                <Text>Wpisz ocenę</Text>
                                                <Select {...register(`${myAnswer?.id}.points`)} max={data.points} />
                                                <div>
                                                    /max {data.points} pkt.
                                                </div>
                                            </FlexBox>
                                        )
                                    }
                                </SinglResult>
                            )
                        }
                        {
                            isChecked && (
                                <SinglResult>
                                    {data.myAnswer.points}/max {data.points} pkt.
                                </SinglResult>
                            )
                        }
                    </FlexBox>
                </Container>
            </Background>
            <Container maxWidth='1170px' disablePaddingTop>
                <Question>
                    {question}
                </Question>
                {
                    type === 'OPEN_TEXT' &&
                    <>
                        <TextArea
                            {...register(`${myAnswer?.id}.answer`)}
                            color='primary'
                            borderColor='primary'
                            placeholder='Wpisz odpowiedź'
                            disabled={isLecturerView || isChecked}
                            isDisabled={isFinishedByStudent}
                        />
                        {
                            (isLecturerView || isChecked) && (
                                <>
                                    <FlexBox margin='0 0 17px 0'>
                                        <NewTitle textAlign='left' variant='h4' >Feedback od Wykładowcy</NewTitle>
                                    </FlexBox>
                                    <TextArea
                                        {...register(`${myAnswer?.id}.feedback`)}
                                        color='primary'
                                        borderColor='primary'
                                        placeholder='Wpisz odpowiedź'
                                        disabled={isChecked}
                                    />
                                </>
                            )
                        }
                    </>
                }
                {
                    type === 'DRIVE' &&
                    <>
                        <Important>WAŻNE! ZAPOZNAJ SIĘ Z INSTRUKCJĄ PLIKU!</Important>
                        <Instruction>
                            {
                                isLecturerView ? 'Miejsce na instrukcję odczytania z Dysk Google' : 'Miejsce na instrukcję dodawania na  Dysk Google'
                            }

                        </Instruction>
                        <FlexBox justifyContnet={'space-between'} margin='0 0 30px 0' flexWrap='wrap' gap={'20px'}>
                            <GoogleDriveLink href={myQuestionUploadDriveLink} target="_blank" isDisabled={!isLecturerView && isFinishedByStudent}>
                                <Button type='button' isReverse isIcon icon={<GoogleDrive />} >
                                    {isLecturerView ? 'Pobierz plik' : "Załaduj plik"}
                                </Button>
                            </GoogleDriveLink>
                            {
                                !isLecturerView && (
                                    <Checkbox
                                        text="Potwierdzam przesłanie pliku na Dysk Google"
                                        borderColor='primary'
                                        {...register(`${myAnswer?.id}.answer`)}
                                        // disabled={!!isLecturerView || isChecked}
                                        isDisabled={isFinishedByStudent}
                                    />
                                )
                            }

                        </FlexBox>
                        {
                            (isLecturerView || isChecked) && (
                                <>
                                    <FlexBox margin='0 0 17px 0'>
                                        <NewTitle textAlign='left' variant='h4' >Feedback od Wykładowcy</NewTitle>
                                    </FlexBox>
                                    <TextArea
                                        {...register(`${myAnswer?.id}.feedback`)}
                                        color='primary'
                                        borderColor='primary'
                                        placeholder='Wpisz odpowiedź'
                                        disabled={isChecked}
                                    />
                                </>
                            )
                        }
                    </>
                }
                {
                    type === 'CLOSED' &&
                    <Answers>
                        {
                            examClosedAnswers.map((answer, index) => (
                                <RadioBox
                                    {...register(`${myAnswer?.id}.answer`)}
                                    value={answer.id}
                                    key={index}
                                    text={answer.answer}
                                    status={isLecturerView ? check(answer) : isChecked ? check2(answer) : false}
                                    variant={2}
                                    isDisabled={isFinishedByStudent}
                                />
                            ))
                        }
                    </Answers>
                }
            </Container>
        </div >
    )
}

export default TestQuestion

const Question = styled.div`
    font-size: 24px;
    font-weight: bold;
    font-family: 'Merriweather', serif;
    color: ${props => props.theme.colors.primary};
    margin-bottom: 30px;
    margin-top: 15px;
`

const Answers = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    margin-bottom: 40px;
`

const GoogleDriveLink = styled.a`
    text-decoration: none;
    width: fit-content;
    display: block;
    pointer-events: ${props => props.isDisabled ? 'none' : 'auto'};
`

const SinglResult = styled.div`
    font-size: 24px;
    font-weight: bold;
    font-family: 'Merriweather', serif;
    color: ${props => props.theme.colors.primary};
`

const Important = styled.p`
    color: #FF0000;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 17px;
`

const Instruction = styled.p`
    font-size: 20px;
    margin-bottom: 37px;
`

const Text = styled.p`
    font-size: 14px;
    margin-right: 8px;
    font-weight: normal;
`


