import axios from "./axios"

const taskService = {
    startTask: () => (task_id) => {
        return axios.post(`/api/v1/start-task/${task_id}`).then(res => res.data)
    },
    finishTask: () => (task_id) => {
        return axios.put(`/api/v1/finish-task/${task_id}`, {}).then(res => res.data)
    },
    getLecturerTasks: (id) => () => {
        return axios.get(`/api/v1/tasks/lecturer/${id}`).then(res => res.data)
    },
    getUserTasks: () => () => {
        return axios.get(`/api/v1/tasks`).then(res => res.data)
    },
    getTaskDetails: (id) => () => {
        return axios.get(`/api/v1/task/${id}`).then(res => res.data)
    },


}

export default taskService
